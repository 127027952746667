import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmailActivationComponent } from './account/component/email-activation/email-activation.component';
import { AuthGuard } from './authorization/guard/auth.guard';
import { RefreshGuard } from './authorization/guard/refresh.guard';
import { CartComponent } from './cart/component/cart/cart.component';
import { ErrorPageComponent } from './common/component/error-page/error-page.component';
import { CoreConfiguration } from './core/core-configuration';
import { MinorConsentComponent } from './minor-consent/component/minor-consent/minor-consent.component';
import { FindMyOrderComponent } from './order-history/component/find-my-order/find-my-order.component';
import { PciCallbackComponent } from './payment/component/pci-callback/pci-callback.component';

// IMPORTANT Use lowercase Routes
const routes: Routes = [
  {
    path: 'cart',
    pathMatch: 'full',
    component: CartComponent,
    canDeactivate: [RefreshGuard],
    runGuardsAndResolvers: 'always',
    data: { skipRefreshOnIdle: true },
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    canDeactivate: [RefreshGuard],
    runGuardsAndResolvers: 'always',
    data: { skipRefreshOnIdle: true },
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canDeactivate: [RefreshGuard],
    runGuardsAndResolvers: 'always',
    data: { title: 'Login', skipRefresh: true },
  },
  {
    path: 'account/emailactivation',
    component: EmailActivationComponent,
    data: { title: 'Email Activation' },
  },
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
    canDeactivate: [RefreshGuard],
    runGuardsAndResolvers: 'always',
    data: { title: 'My Account' },
  },
  {
    path: 'reseller',
    loadChildren: () => import('./reseller/reseller.module').then((m) => m.ResellerModule),
    canActivate: [AuthGuard]
    // canDeactivate: [RefreshGuard],
    // runGuardsAndResolvers: 'always',
    // data: { title: 'Place Order', refreshTo: '/' },
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then((m) => m.CheckoutModule),
    canDeactivate: [RefreshGuard],
    runGuardsAndResolvers: 'always',
    data: { title: 'Checkout – Life Extension', refreshTo: new CoreConfiguration().webRoutes.root },
  },
  {
    path: 'orderbynumber',
    loadChildren: () => import('./order-by-item/order-by-item.module').then((m) => m.OrderByItemModule),
    canDeactivate: [RefreshGuard],
    runGuardsAndResolvers: 'always',
    data: { title: 'Order By Catalog Item Number', refreshTo: new CoreConfiguration().webRoutes.root },
  },
  {
    path: 'findmyorder',
    component: FindMyOrderComponent,
    canDeactivate: [RefreshGuard],
    runGuardsAndResolvers: 'always',
    data: { title: 'Find My Order' },
  },
  {
    path: 'returns',
    loadChildren: () => import('./returns/returns.module').then((m) => m.ReturnsModule),
    canActivateChild: [AuthGuard],
    canDeactivate: [RefreshGuard],
    runGuardsAndResolvers: 'always',
    data: { title: 'Return Order' },
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule),
  },
  {
    path: 'pci/:operation/:id',
    component: PciCallbackComponent,
    data: { title: 'PCI Callback Processor' },
  },
  {
    path: 'minorconsent/verify/:orderNumber',
    component: MinorConsentComponent,
    data: { title: 'Minor Consent' },
  },
  {
    path: '**',
    redirectTo: 'cart',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', scrollOffset: [0, 0], anchorScrolling: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }

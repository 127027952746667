import { Injectable } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { IPaymentOption } from 'src/app/payment/model';
import { CustomerNCOAComponent } from './../component/customer-ncoa/customer-ncoa.component';
import { Prop65ListComponent } from './../component/prop65-list/prop65-list.component';
import { RenewPremierComponent } from './../component/renew-premier/renew-premier.component';
import { IAccountInformation } from './../model/account-information.model';
import { IAccount } from './../model/account.model';

@Injectable({
  providedIn: 'root',
})
export class AccountDialogService {
  constructor(private readonly dialogService: DialogService) {}

  openProp65ProductsDialog(onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Prop 65 Product List',
      width: 1200,
      maxHeight: '90%',
      maxWidth: '90%',
      content: Prop65ListComponent,
    });

    const componentRef: Prop65ListComponent = dialogRef.content.instance;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  showCustomerNcoaVerificationDialog(accountInformation: IAccountInformation, onClose: (confirm: boolean) => void) {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'NCOA Address Settings',
      content: CustomerNCOAComponent,
      height: 425,
      width: 1000,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: CustomerNCOAComponent = dialogRef.content.instance;
    componentRef.accountInformation = accountInformation;

    componentRef.closeDialog.subscribe((confirm: boolean) => {
      dialogRef.close();
      onClose(confirm);
    });

    dialogRef.result.subscribe((result: any) => {
      dialogRef.close();
    });
  }

  showRenewPremierDialog(
    account: IAccount,
    paymentOption: IPaymentOption,
    onClose: (confirm: boolean, paymentOption: IPaymentOption) => void
  ) {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Renew Premier Automatically',
      content: RenewPremierComponent,
      height: 425,
      width: 1000,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: RenewPremierComponent = dialogRef.content.instance;
    componentRef.account = account;
    componentRef.closeDialog.subscribe((confirm: boolean) => {
      dialogRef.close();
      paymentOption = dialogRef.content.instance.paymentOption;
      onClose(confirm, paymentOption);
    });

    dialogRef.result.subscribe((result: any) => {
      dialogRef.close();
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ICartMessage } from 'src/app/cart-common/model';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IPaymentOption, PaymentOption } from 'src/app/payment/model';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { AccountDialogService } from '../../service/account-dialog.service';
import { IAccount } from './../../model/account.model';
import { AccountService } from './../../service/account.service';

@Component({
  selector: 'lef-my-account-premier',
  templateUrl: './my-account-premier.component.html',
  styleUrls: ['./my-account-premier.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyAccountPremierComponent implements OnInit {
  @Input() public account: IAccount;
  @Output() updateMessage: EventEmitter<ICartMessage[]> = new EventEmitter();
  premierItem: string;
  paymentOption: IPaymentOption = new PaymentOption();

  constructor(
    public readonly config: CoreConfiguration,
    private readonly accountDialogService: AccountDialogService,
    private readonly accountService: AccountService,
    private readonly loadingService: LoadingService
  ) { }

  ngOnInit(): void { }

  showCreditCardModal() {
    this.showRenewPremierDialog();
  }

  showRenewPremierDialog() {
    this.accountDialogService.showRenewPremierDialog(
      this.account,
      this.paymentOption,
      (confirm: any, selectedPaymentOption: IPaymentOption) => {
        if (confirm) {
          this.account.messages = [];
          this.updateMessage.emit(this.account.messages);
          this.loadingService.show();
          this.accountService.saveAutoRenew(selectedPaymentOption.id, this.account).subscribe(
            (account: IAccount) => {
              if (account && account.customerNumber) {
                this.account = account;
                this.updateMessage.emit(this.account.messages);
              }
              this.loadingService.hide();
            },
            (error) => {
              this.loadingService.hide();
            }
          );
        }
      }
    );
  }
}

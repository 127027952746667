<div class="return-summary-wrapper">
  <div *ngIf="returnOrder.returnStep === returnSteps.SetOptions" class="return-summary-section bg-light-blue">
    <div class="return-summary-block">
      <div class="text-primary font-lg font-weight-bold">Select Refund Method</div>
      <div class="text-primary font-weight-bold">(If Applicable)</div>
      <div class="selection-body pl-3">
        <div class="selection-record p-2">
          <input kendoRadioButton id="ogPayment" class="form-check-input k-radio" type="radio"
                 [disabled]="!hasRefundItems" [value]="false" [(ngModel)]="returnOrder.refundToAccount" />
          <label class="form-check-label" [attr.for]="hasRefundItems ? 'ogPayment': ''"
                 [ngClass]="{'clickable': hasRefundItems}">
            Refund to original payment method
          </label>
        </div>
        <div class="selection-record p-2">
          <input kendoRadioButton id="account" class="form-check-input k-radio" type="radio"
                 [disabled]="!hasRefundItems" [value]="true" [(ngModel)]="returnOrder.refundToAccount" />
          <label class="form-check-label" [attr.for]="hasRefundItems ? 'account': ''"
                 [ngClass]="{'clickable': hasRefundItems}">
            Refund purchase to your account
          </label>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="returnOrder.returnStep > returnSteps.SetOptions">
    <div class="return-summary-section">
      <div class="return-summary">
        <div>
          <span class="font-weight-bold">Return Summary</span>
          <span>{{returnOrder.returnItemCount}} item(s)</span>
        </div>
        <div *ngIf="returnOrder.replacementItemCount > 0">
          <span>Replacement Item(s)</span>
          <span>{{returnOrder.replacementItemCount}}</span>
        </div>
        <div *ngIf="returnOrder.refundItemCount > 0">
          <span>Refund Item(s)</span>
          <span>{{returnOrder.refundItemCount}}</span>
        </div>
      </div>
    </div>
    <div class="return-summary-section bg-light-blue">
      <div class="return-summary">
        <div>
          <span>Refund Subtotal</span>
          <span class="font-weight-bold">{{returnOrder.refundTotal | currency}}</span>
        </div>
        <div *ngIf="returnOrder.replacementItemCount > 0 && returnOrder.shippingSummary.shippingMethod">
          <span>Replacement Shipping Cost</span>
          <span class="font-weight-bold">
            {{premierService.getRateText(returnOrder.shippingSummary.shippingMethod.rate)}}
          </span>
        </div>
        <div *ngIf="returnOrder.shouldSendBackItems">
          <span>Return Shipping Cost</span>
          <span class="font-weight-bold">
            {{premierService.getRateText(returnOrder.returnShippingCost)}}
          </span>
        </div>
        <div *ngIf="returnOrder.refundTotalTax">
          <span>{{refundTaxLabel}}</span>
          <span class="font-weight-bold">{{refundTaxAmount | currency}}</span>
        </div>
        <div class="border-none font-weight-bold font-lg">
          <span>{{refundLabel}}</span>
          <span>{{refundAmount | currency}}</span>
        </div>
        <div *ngIf="returnOrder.amountDue < 0 && returnOrder.paymentOptionSelected">
          <span>To: <span class="ml-1" [innerHTML]="returnOrder.paymentOptionSelected.shortDescription"></span></span>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="return-summary-section">
    <div class="return-summary-block">
      <ng-container *ngIf="returnOrder.returnStep > returnSteps.SetOptions">
        <div *ngIf="returnOrder.amountDue > 0" class="mb-3"> {{refundExplanation}} </div>
        <div *ngIf="returnOrder.replacementItemCount > 0" class="mb-3">{{replacementExplanation}}</div>
      </ng-container>

      <button *ngIf="returnOrder.returnStep < returnSteps.Complete" class="btn btn-action btn-block mb-3"
              [disabled]="!returnsSelected" (click)="nextStep.emit()">
        {{buttonLabel}}
      </button>

      <lef-contact-us [align]="'right'"></lef-contact-us>
    </div>
  </div>
</div>
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IAccount } from '../../model/account.model';

@Component({
  selector: 'lef-my-account-standard',
  templateUrl: './my-account-standard.component.html',
  styleUrls: ['./my-account-standard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MyAccountStandardComponent implements OnInit {
  @Input() public account: IAccount;
  constructor(public readonly config: CoreConfiguration) { }

  ngOnInit(): void { }
}

<div class="lab-results">
  <div class="pt-md-4 pt-mb-3 pt-2">
    <lef-section-title [title]="'Lab Test Results'"></lef-section-title>
  </div>
  <div>
    <button class="btn btn-link text-primary font-weight-bold" routerLink="/account/bloodlab">
      <i class="lef-chevron-circle-left text-orange"></i> Lab Test Subjects
    </button>
  </div>

  <ng-container *ngIf="screenSize < screenSizes.MD">
    <kendo-listview *ngIf="labResults.length > 0; else noResults" [data]="labResults"
                    [containerClass]="'k-d-flex k-flex-col k-flex-nowrap'">
      <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
        <div class="bg-light pb-1 w-100">
          <div class="mx-3 pt-3" [ngClass]="{'border-top': !isFirst}">
            <div><span class="font-weight-bold">Order #: </span>{{dataItem.orderNumber}}</div>
            <div><span class="font-weight-bold">Description: </span>{{dataItem.itemDescription}}
            </div>
            <div><span class="font-weight-bold">Result Date: </span>{{dataItem.resultDate | date: 'MM/dd/yyyy'}}</div>
          </div>
          <div class="p-2">
            <button kendoButton look="clear" class="text-info font-weight-bold"
                    (click)="downloadResult(dataItem)">Download Results</button>
          </div>
        </div>
      </ng-template>
    </kendo-listview>
    <ng-template #noResults>
      <div class="bg-light p-3 w-100 text-center">
        No Lab Test Results were found for this subject.
      </div>
    </ng-template>
  </ng-container>

  <kendo-grid *ngIf="screenSize > screenSizes.SM" [data]="labResults" scrollable="none">
    <kendo-grid-column field="orderNumber" title="Order #"></kendo-grid-column>
    <kendo-grid-column field="itemDescription" title="Description"></kendo-grid-column>
    <kendo-grid-column field="resultDate" title="Result Date">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{dataItem.resultDate | date: 'MM/dd/yyyy'}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Results" [width]="200" [headerClass]="'justify-content-end'"
                               [class]="'text-right'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button kendoButton look="clear" class="text-info font-weight-bold p-0"
                (click)="downloadResult(dataItem)">Download
          Results</button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ItemConfigurationService } from 'src/app/cart-common/service/item-configuration.service';
import { ResponsiveService, ScreenSizes } from 'src/app/common/service/responsive.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { IReturnItem, IReturnReason, ReturnOptions, ReturnSteps } from '../../model';

@Component({
  selector: 'lef-return-item',
  templateUrl: './return-item.component.html',
  styleUrls: ['./return-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReturnItemComponent implements OnInit {
  @Input() returnItem: IReturnItem;
  @Input() returnReasons: IReturnReason[];
  @Input() returnStep: ReturnSteps;
  @Input() mustSendBackItems = true;

  @Output() whyUnavailable: EventEmitter<void> = new EventEmitter();

  @ViewChild('returnItemDiv', { read: ElementRef }) returnItemDiv: ElementRef;

  private quantities: { value: number; label: number }[] = [];
  private readonly currencyPipe = new CurrencyPipe('en-US');

  private get refundPrice(): string {
    return this.currencyPipe.transform(this.returnItem.yourPrice * this.returnItem.quantityToReturn);
  }

  private get chargedPrice(): string {
    return this.currencyPipe.transform(this.returnItem.yourPrice * this.returnItem.quantityToSendBack);
  }

  selectItemForm: UntypedFormGroup = new UntypedFormGroup({});
  selectItemFormFields: FormlyFieldConfig[] = [];
  returnOptionsForm: UntypedFormGroup = new UntypedFormGroup({});
  confirmForm: UntypedFormGroup = new UntypedFormGroup({});
  confirmFormFields: FormlyFieldConfig[] = [];
  returnSteps = ReturnSteps;
  returnOptions = ReturnOptions;
  screenSizes = ScreenSizes;
  defaultImage = 'assets/images/ph-sm.png';

  //#region public getters
  get screenSize(): ScreenSizes {
    return this.responsiveService.currentSize;
  }

  get valid(): boolean | any {
    switch (this.returnStep) {
      case ReturnSteps.SelectItems:
        return !this.returnItem.isSelected || (this.selectItemForm.touched && this.selectItemForm.valid);
      case ReturnSteps.Confirm:
        return !this.showReturnConfirmation || (this.confirmForm.touched && this.confirmForm.valid);
    }
  }

  get itemUrl(): string {
    return this.itemConfig.getItemUrl(this.returnItem);
  }

  get itemImageUrl(): string {
    return this.returnItem && this.returnItem.itemNumber
      ? this.baseConfig.environment.itemUrlTemplates.itemImageUrl.replace('{itemNumber}', this.returnItem.itemNumber).toLocaleLowerCase()
      : '';
  }

  get canReturnOnline(): boolean {
    return !this.returnItem.returnReason || this.returnItem.returnReason.canReturnOnline;
  }

  get offerExchange(): boolean {
    return this.returnItem.returnReason && this.returnItem.returnReason.offerExchange;
  }

  get showReturnConfirmation(): boolean {
    return this.returnItem.eligibleToSendBack && this.mustSendBackItems;
  }

  get selectionClass(): string {
    return this.returnStep > ReturnSteps.SetOptions ? 'col-lg-4' : 'col-lg';
  }

  get chargeLabel(): string {
    return this.returnItem.eligibleToSendBack
      ? `<p>You must send back the original item(s) within 30 days or be charged
          <b class="text-primary">${this.chargedPrice}</b>.
        </p>`
      : '';
  }

  get refundPaymentLabel(): string {
    return `
      <p>Refund <b class="text-primary">${this.refundPrice}</b>
        to original payment method within 3-5 business days.
      </p>
      ${this.chargeLabel}
    `;
  }

  get replaceItemLabel(): string {
    return `
      <p>Replace with the exact same item(s).</p>
      <p>
        A new item(s) will be sent to you within 10 business days.
      </p>
      ${this.chargeLabel}
    `;
  }

  get confirmLabel(): string {
    switch (this.returnItem.returnOption) {
      case ReturnOptions.RefundPayment:
        return this.refundPaymentLabel;
      case ReturnOptions.ReplaceItem:
        return this.replaceItemLabel;
    }
  }

  //#endregion public getters

  constructor(
    private readonly baseConfig: CoreConfiguration,
    private readonly itemConfig: ItemConfigurationService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    switch (this.returnStep) {
      case ReturnSteps.SelectItems:
        if (this.selectItemFormFields.length < 1) {
          this.initSelectItemForm();
        }
        break;
      case ReturnSteps.SetOptions:
        this.initReturnOptionsForm();
        break;
      case ReturnSteps.Confirm:
      case ReturnSteps.Complete:
        if (this.confirmFormFields.length < 1) {
          this.initConfirmFormFields();
        }
        break;
    }
  }

  private initSelectItemForm() {
    this.selectItemForm = new UntypedFormGroup({});
    for (let index = 1; index <= this.returnItem.availableForReturn; index++) {
      this.quantities.push({ value: index, label: index });
    }
    this.selectItemFormFields = [
      {
        key: 'quantityToReturn',
        type: 'kDropdown',
        wrappers: ['form-field-horizontal'],
        templateOptions: {
          label: 'Quantity to Return:',
          placeholder: 'Please Select',
          options: this.quantities,
          required: true,
          labelClass: 'col-form-label col-6 text-right',
          inputWrapperClass: 'col-6',
        },
        expressionProperties: {
          'templateOptions.disabled': (model, formState) => this.returnItem.returnReason && !this.returnItem.returnReason.canReturnOnline,
        },
      },
      {
        key: 'quantityOpened',
        type: 'kDropdown',
        wrappers: ['form-field-horizontal'],
        templateOptions: {
          label: 'How Many Opened Items?:',
          placeholder: 'Please Select',
          required: true,
          labelClass: 'col-form-label col-6 text-right',
          inputWrapperClass: 'col-6',
        },
        expressionProperties: {
          'templateOptions.disabled': (model, formState) => this.returnItem.returnReason && !this.returnItem.returnReason.canReturnOnline,
          'templateOptions.options': (model, formState) => {
            const quantities: { value: number; label: number }[] = [];
            for (let index = 0; index <= model.quantityToReturn; index++) {
              quantities.push({ value: index, label: index });
            }
            return quantities;
          },
        },
      },
      {
        key: 'returnReasonCode',
        type: 'kDropdown',
        wrappers: ['form-field-horizontal'],
        templateOptions: {
          label: 'Reason for Return:',
          placeholder: 'Please Select',
          options: this.returnReasons,
          valueProp: 'id',
          labelProp: 'webDescription',
          required: true,
        },
        hooks: {
          onInit: (field) => {
            return field.formControl.valueChanges.subscribe((reasonCode: string) => {
              this.checkReturnReason(reasonCode);
            });
          },
        },
      },
      {
        key: 'comments',
        type: 'textarea',
        className: 'text-right',
        wrappers: ['form-field-html-error'],
        templateOptions: {
          maxLength: 200,
          rows: 5,
        },
        expressionProperties: {
          'templateOptions.label': (model, formState) =>
            this.returnItem.returnReason && this.returnItem.returnReason.commentsRequired ? 'Comments' : 'Comments (Optional)',
          'templateOptions.required': (model, formState) => this.returnItem.returnReason && this.returnItem.returnReason.commentsRequired,
          'templateOptions.disabled': (model, formState) => this.returnItem.returnReason && !this.returnItem.returnReason.canReturnOnline,
          'templateOptions.description': (model, formState) => `${200 - model.comments.length} characters remaining`,
        },
        hideExpression: (model, formState) => !this.returnItem.returnReason || this.returnItem.returnReason.isCustomerFault,
      },
    ];
  }

  private initReturnOptionsForm() {
    this.returnOptionsForm = this.formBuilder.group({
      returnOption: [this.returnItem.returnOption],
    });

    this.returnOptionsForm.controls.returnOption.valueChanges.subscribe((returnOption: ReturnOptions) => {
      this.returnItem.returnOption = returnOption;
    });
  }

  private initConfirmFormFields() {
    this.confirmForm = new UntypedFormGroup({});
    this.confirmFormFields = [
      {
        type: 'iconCheckbox',
        key: 'sendBackConfirmed',
        templateOptions: {
          label: `I am sending back <b>${this.returnItem.quantityToSendBack} unopened unit(s)</b> of this item.`,
          iconClass: 'lef-warning text-blue font-md pr-1',
        },
        expressionProperties: {
          'templateOptions.disabled': (model, formState) => this.returnStep === ReturnSteps.Complete,
        },
        validators: {
          requiredTrue: {
            expression: (c) => c.value,
            message: (error, field: FormlyFieldConfig) => `<i class="lef-warning font-root ml-4"></i> Confirmation Required.`,
          },
        },
      },
    ];
  }

  private checkReturnReason(reasonCode: string): void {
    const returnReason = this.returnReasons.find((reason: IReturnReason) => {
      return reason.id === reasonCode;
    });

    this.returnItem.returnReason = returnReason;
    this.returnItem.isSelected = this.canReturnOnline;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { PaymentModule } from '../payment/payment.module';
import { SharedModule } from '../shared/shared.module';
import { PackagingSlipComponent } from './component/packaging-slip/packaging-slip.component';
import { ReturnItemComponent } from './component/return-item/return-item.component';
import { ReturnOrderComponent } from './component/return-order/return-order.component';
import { ReturnSummaryComponent } from './component/return-summary/return-summary.component';
import { ShippingLabelComponent } from './component/shipping-label/shipping-label.component';
import { ReturnsRoutingModule } from './returns-routing.module';

const modules = [CommonModule, RouterModule, SharedModule, CommonsModule, CartCommonModule, ReturnsRoutingModule, PaymentModule];

const components = [ReturnOrderComponent, PackagingSlipComponent];

@NgModule({
  declarations: [components, ReturnItemComponent, ReturnSummaryComponent, ShippingLabelComponent],
  imports: [modules],
  exports: [SharedModule, components],
})
export class ReturnsModule {}

<div class="my-account-hdr">Customer Information: Premier</div>
<div class="my-account-content bg-light mb-3 px-3 py-2">
  <div class="pt-2">
    <p>
      <strong>Name:</strong> {{account.mailingName ? account.mailingName : account.firstName}}
    </p>
    <p>
      <strong>Email:</strong> {{account.email}}
    </p>
    <p>
      <strong>Customer #:</strong> {{account.customerNumber}}
    </p>
  </div>
  <hr>
  <div>
    <p class="text-primary font-md">
      <strong>LE Dollars Available: {{account.availableLeRewardDollars | currency :'USD':'symbol':'1.2-2'}}</strong>
    </p>
    <p><a [routerLink]="config.webRoutes.accountOrderHistory" target="_self" title="Order History"
         class="text-info">View LE Dollars
        Activity</a></p>
  </div>
  <hr>
  <div>
    <p *ngIf="account.customerTypeDescription"><strong>Account Type:</strong> {{account.customerTypeDescription}}</p>
    <p *ngIf="!account.isPremierAutoEnroll">
      <strong>Premier Expiration Date:</strong> {{account.expirationDate | date: 'MM/dd/yyyy'}}
    </p>
    <p><strong>Premier Auto Renewal:</strong> {{ account.isPremierAutoEnroll? 'Yes': 'No' }}</p>
    <p *ngIf="account.isPremierAutoEnroll">
      <strong>Premier Automatically Renews:</strong> {{ account.nextShipmentDate | date: 'MM/dd/yyyy'}}
    </p>
    <p><strong>Free Shipping:</strong> Yes</p>
    <p><strong>Shipping Type:</strong>
      {{account.isAnonymous ? 'Standard (3-5 business days)' : (account.isMember && account.isChoiceStandard ?
      'Standard (3-5 business days)': 'Express (2nd day air)')}}
    </p>
    <p><strong>LE Dollars Back:</strong>
      {{(account.rewardsMultiplier ? account.rewardsMultiplier : 0)| percent:'1.1-2' }}</p>
  </div>
  <hr />
</div>

<lef-premier-renew-banner *ngIf="account.displayRenewToday && !account.isPremierAutoEnroll else autoShipBanner">
</lef-premier-renew-banner>

<ng-template #autoShipBanner>
  <div class="premier-cta">
    <div class="premier-cta-img">
      <img src="../../../../assets/images/autoship-icon.svg" alt="AutoShip & Save" />
    </div>
    <div class="premier-cta-content">
      <h2 class="roboto text-info"><strong>Autoship</strong><br /><span class="zilla text-primary">&amp; Save</span>
      </h2>
      <p>
        Find out if you are on the right path for a longer life.
      </p>
      <a href="https://www.lifeextension.com/lpages/vipautoship" target="_blank"
         class="text-primary font-md font-weight-medium">Learn
        more
        <i class="lef-chevron-circle-right text-orange"></i></a>
    </div>
  </div>
</ng-template>
<div *ngIf="accountInformation.showCustomerNcoaNotification">
  <div class="row">
    <div class="col-md-12">
      The United States Postal Service (USPS) notified us that your address has changed.<br>
      Your account has been updated accordingly.<br>
      Please verify your current address below or call 1-888-218-6183 for assistance.
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 cart-box">
      <div class="cart-box-header">
        <div class="row">
          <div class="col-md-6">Current Address</div>
          <div class="col-md-6" style="text-align: right;"><input kendoradiobutton id="RadioNewAddress" class="k-radio"
                   type="radio" name="Address" value="new"
                   [(ngModel)]="accountInformation.customerNCOA.addressSelected" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">Street Address 1:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newAddressLn1}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">Street Address 2:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newAddressLn2}}</div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-6">City:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newCity}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">State:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newState}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">Zip Code:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newZip}}</div>
      </div>
    </div>
    <div class="col-md-6 cart-box">
      <div class="cart-box-header">
        <div class="row">
          <div class="col-md-6">Old Address</div>
          <div class="col-md-6" style="text-align: right;"><input kendoradiobutton id="RadioOldAddress" class="k-radio"
                   type="radio" name="Address" value="old"
                   [(ngModel)]="accountInformation.customerNCOA.addressSelected" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">Street Address 1:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldAddressLn1}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">Street Address 1:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldAddressLn2}}</div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-6">City:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldCity}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">State:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldState}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">Zip Code:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldZip}}</div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="accountInformation.showCustomerNcoaVerification">
  <div class="row">
    <div class="col-md-12">
      The United States Postal Service (USPS) notified us that your address has changed.<br>
      Please verify your current address below or call 1-888-218-6183 for assistance.
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 cart-box">
      <div class="cart-box-header">
        <div class="row">
          <div class="col-md-6">Current Address</div>
          <div class="col-md-6" style="text-align: right;"><input kendoradiobutton id="RadioOldAddress" class="k-radio"
                   type="radio" name="Address" value="old"
                   [(ngModel)]="accountInformation.customerNCOA.addressSelected" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">Street Address 1:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldAddressLn1}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">Street Address 1:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldAddressLn2}}</div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-6">City:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldCity}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">State:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldState}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">Zip Code:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.oldZip}}</div>
      </div>
    </div>
    <div class="col-md-6 cart-box">
      <div class="cart-box-header">
        <div class="row">
          <div class="col-md-6">USPS Provided Address</div>
          <div class="col-md-6" style="text-align: right;"><input kendoradiobutton id="RadioNewAddress" class="k-radio"
                   type="radio" name="Address" value="new"
                   [(ngModel)]="accountInformation.customerNCOA.addressSelected" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">Street Address 1:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newAddressLn1}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">Street Address 2:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newAddressLn2}}</div>
      </div>
      <br>
      <div class="row">
        <div class="col-md-6">City:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newCity}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">State:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newState}}</div>
      </div>
      <div class="row">
        <div class="col-md-6">Zip Code:</div>
        <div class="col-md-6">{{accountInformation.customerNCOA.newZip}}</div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-md-12 text-right">
    <button type="button" class="btn btn btn-warning" (click)="confirm(true)">
      Continue
    </button>
    <button type="button" class="btn btn btn-light" (click)="confirm(false)">
      Cancel
    </button>
  </div>
</div>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountModule } from '../account/account.module';
import { CommonsModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import { ForgotPasswordComponent } from './component/forgot-password/forgot-password.component';
import { LoginPageComponent } from './component/login-page/login-page.component';
import { LoginComponent } from './component/login/login.component';
import { LogoutComponent } from './component/logout/logout.component';
import { ResetPasswordComponent } from './component/reset-password/reset-password.component';
import { UserImpersonationComponent } from './component/user-impersonation/user-impersonation.component';
import { VerifyAccountCodeComponent } from './component/verify-account-code/verify-account-code.component';
import { LoginRoutingModule } from './login-routing.module';

const components = [
  LoginPageComponent,
  LoginComponent,
  LogoutComponent,
  ResetPasswordComponent,
  ForgotPasswordComponent,
  UserImpersonationComponent,
  VerifyAccountCodeComponent
];

@NgModule({
  imports: [CommonModule, SharedModule, LoginRoutingModule, CommonsModule, AccountModule],
  exports: [SharedModule, components],
  declarations: [components]
})
export class LoginModule { }

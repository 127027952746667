import { EventEmitter, Injectable } from '@angular/core';
import { FlyoutModel } from '../model/flyout.model';

@Injectable({
  providedIn: 'root'
})
export class MainFlyoutService {

  openEvent: EventEmitter<FlyoutModel> = new EventEmitter<FlyoutModel>();
  closeEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  open(config: FlyoutModel): void {
    this.openEvent.emit(config);
  }

  close(route: string): void {
    this.closeEvent.emit(route);
  }
}

import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import moment from 'moment';
import { distinctUntilChanged } from 'rxjs/operators';
import { AuthorizationService } from 'src/app/authorization';
import { ResponsiveService, ScreenSizes } from 'src/app/common/service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { CartDialogService } from 'src/app/shared/service/cart-dialog.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IContactPreferences } from '../../model';
import { ContactPreferencesService } from '../../service/contact-preferences.service';
import { UnsubscribeSurveyComponent } from '../unsubscribe-survey/unsubscribe-survey.component';
import { UnsubscribeVerifyComponent } from '../unsubscribe-verify/unsubscribe-verify.component';

@Component({
  selector: 'lef-customer-preferences',
  templateUrl: './customer-preferences.component.html',
  styleUrls: ['./customer-preferences.component.scss'],
})
export class CustomerPreferencesComponent implements OnInit {
  @Input() contactPreferences: IContactPreferences;
  @ViewChild('info', { read: TemplateRef }) info: TemplateRef<any>;

  preferencesForm: UntypedFormGroup;
  screenSizes = ScreenSizes;

  magazineImage: string;
  defaultImage: string;

  content: string;

  isWholeSale: boolean;

  get promotionText(): string {
    return this.isWholeSale
      ? 'Opt-in to receive exclusive savings opportunities for our partners.'
      : 'Be the first to know about sales and exclusive savings opportunities.';
  }

  get informationalTitle(): string {
    return this.isWholeSale ? 'LE Informational' : 'LE Newsletters';
  }

  get informationalText(): string {
    return this.isWholeSale
      ? 'Be the first to know of updates from Life Extension that are pertinent to your business.'
      : 'Stay informed about the latest health news, and get expert advice-so you can live your healthiest life.';
  }

  private verifyDialogRef: DialogRef;
  private surveyDialogRef: DialogRef;
  private wasEmailSubscribed: boolean;
  private verifyShown = false;

  private get successMessage(): string {
    return this.responsiveService.currentSize > ScreenSizes.SM ? 'Success! Changes have been saved.' : 'Changes saved!';
  }

  constructor(
    private readonly contactPreferencesService: ContactPreferencesService,
    private readonly loadingService: LoadingService,
    private readonly dialogService: DialogService,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly responsiveService: ResponsiveService,
    private readonly authorizationService: AuthorizationService,
    private readonly cartDialogService: CartDialogService,
    config: CoreConfiguration
  ) {
    const now = moment();
    this.magazineImage = `${config.environment.assetSite}/-/media/lef/images/magazine/mag${now.year()}/images/${now.format(
      'MMM'
    )}${now.year()}_cover.jpg`;
    this.defaultImage = config.images.placeHolder.small;
  }

  ngOnInit() {
    this.isWholeSale = this.authorizationService.isWholesaler();
    if (this.contactPreferences) {
      this.wasEmailSubscribed =
        this.contactPreferences.email || (this.contactPreferences.isProspect && !this.contactPreferences.blockEmail);
      this.initializeForm();
    } else {
      this.loadContactPreferences();
    }
  }

  private loadContactPreferences() {
    this.loadingService.show('Loading...');
    this.contactPreferencesService.getContactPreferences().subscribe({
      next: (contactPreferences: IContactPreferences) => {
        this.contactPreferences = contactPreferences;
        this.wasEmailSubscribed = this.contactPreferences.email;
        this.initializeForm();
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }


  
  private initializeForm() {
    this.preferencesForm = this.formBuilder.group({
      marketingEmail: [this.contactPreferences.marketingEmail, [Validators.email]],
      email: [this.contactPreferences.email],
      mail: [this.contactPreferences.mail],
      sms: [{ value: this.contactPreferences.sms, disabled: !this.contactPreferences.sms || !this.contactPreferences.smsPhoneNumber }],
      emailMagazine: [{ value: this.contactPreferences.emailMagazine, disabled: !this.contactPreferences.email }],
      mailMagazine: [{ value: this.contactPreferences.mailMagazine, disabled: !this.contactPreferences.mail }],
      allPromotions: [this.contactPreferences.allPromotions],
      otbPromotions: [{ value: this.contactPreferences.otbPromotions, disabled: this.contactPreferences.allPromotions }],
      informational: [this.contactPreferences.informational],
      announcements: [this.contactPreferences.announcements],
      rewardsAndReferral: [this.contactPreferences.rewardsAndReferral],
      blockEmail: [this.contactPreferences.blockEmail],
    });

    if (!this.contactPreferences.email && !this.contactPreferences.mail && !this.contactPreferences.sms) {
      this.togglePreferencesEnable(false);
    }

    // Due to issues opening a dialog when values change.
    this.preferencesForm.get('email').markAsTouched({ onlySelf: true });
    this.preferencesForm.get('allPromotions').markAsTouched({ onlySelf: true });

    this.preferencesForm
      .get('email')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value: boolean) => {
        if (!value) {
          const emailMagazine = this.preferencesForm.get('emailMagazine');
          emailMagazine.disable();

          //  && !this.preferencesForm.value.sms
          if (!this.preferencesForm.value.mail) {
            this.togglePreferencesEnable(false);
          }

          if (this.preferencesForm.get('allPromotions').value) {
            this.showCustomerVerify();
          }
        } else {
          this.togglePreferencesEnable(true);

          if (!this.preferencesForm.value.mail) {
            const mailMagazine = this.preferencesForm.get('mailMagazine');
            mailMagazine.disable();
          }
        }
      });

    this.preferencesForm.get('mail').valueChanges.subscribe((value: boolean) => {
      if (!value) {
        const mailMagazine = this.preferencesForm.get('mailMagazine');
        mailMagazine.disable();

        if (!this.preferencesForm.value.email && !this.preferencesForm.value.sms) {
          this.togglePreferencesEnable(false);
        }
      } else {
        this.togglePreferencesEnable(true);
        if (!this.preferencesForm.value.email) {
          const emailMagazine = this.preferencesForm.get('emailMagazine');
          emailMagazine.disable();
        }
      }
    });

    this.preferencesForm.get('allPromotions').valueChanges.subscribe((value: boolean) => {
      const otbPromotions = this.preferencesForm.get('otbPromotions');
      if (value) {
        otbPromotions.setValue(false);
        otbPromotions.disable();
      } else if (this.preferencesForm.value.email) {
        otbPromotions.enable();
        if (this.preferencesForm.value.email) {
          this.showCustomerVerify();
        }
      }
    });
  }

  private togglePreferencesEnable(enable: boolean) {
    Object.keys(this.preferencesForm.controls).forEach((k: string) => {
      if (k !== 'email' && k !== 'mail' && k !== 'sms') {
        const preference = this.preferencesForm.get(k);
        if (enable) {
          preference.enable({ emitEvent: false });
        } else {
          preference.disable({ emitEvent: false });
        }
      }
    });
    if (this.preferencesForm.value.allPromotions) {
      const otbPromotions = this.preferencesForm.get('otbPromotions');
      otbPromotions.disable();
      otbPromotions.setValue(false);
    }
  }

  savePreferences() {
    this.mapPreferences();
    this.saveContactPreferences();
  }

  private saveContactPreferences() {
    this.loadingService.show('Saving...');
    const saved =
      this.contactPreferences.customerNumber > 0
        ? this.contactPreferencesService.updateContactPreferences(this.contactPreferences)
        : this.contactPreferencesService.createContactPreferences(this.contactPreferences);
    saved.subscribe({
      next: (contactPreferences: IContactPreferences) => {

        this.contactPreferences = contactPreferences;
        if(this.contactPreferences.marketingEmailInvalid) {
          return;
        }
        if(this.contactPreferences.marketingEmailConfirmationNeeded) {
          this.loadingService.hide();
          this.showEmailVerificationDialog();
          return;
        }

        if (this.wasEmailSubscribed && !this.contactPreferences.email) {
          this.showSurveyDialog();
        } else {
          this.showInfoDialog('success');
        }

        this.wasEmailSubscribed = this.contactPreferences.email;
        this.initializeForm();
        // this.mapForm();
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  private mapPreferences() {
    const otbPromotions = this.preferencesForm.get('otbPromotions');
    if (otbPromotions) {
      otbPromotions.enable();
    }
    Object.keys(this.preferencesForm.value).forEach((k: string) => {
      if (this.contactPreferences.hasOwnProperty(k)) {
        this.contactPreferences[k] = this.preferencesForm.value[k];
      }
    });
    if (otbPromotions && !this.contactPreferences.otbPromotions) {
      otbPromotions.disable();
    }
  }

  showInfoDialog(infoType: 'sms' | 'otb' | 'success') {
    let title: string;
    switch (infoType) {
      case 'sms':
        title = 'SMS / Text Message';
        this.content = 'To turn on or off SMS/Text Messaging you have to call <b>1-800-888-8888.<b/>';
        break;
      case 'otb':
        title = 'Only the Best';
        this.content = 'Receive only our best sale and promotional offers (about once a week).';
        break;
      case 'success':
        title = this.successMessage;
        this.content = `You've successfully updated your contact preferences.`;
        break;
    }

    this.dialogService.open({
      title,
      width: 600,
      maxHeight: '90%',
      maxWidth: '90%',
      content: this.info,
    });
  }

  private showCustomerVerify(): void {
    if (this.isWholeSale || this.verifyShown) {
      return;
    }

    this.verifyShown = true;

    this.verifyDialogRef = this.dialogService.open({
      title: `One last thing before you go!`,
      width: 600,
      maxHeight: '90%',
      maxWidth: '90%',
      content: UnsubscribeVerifyComponent,
    });

    const verifyComponent: UnsubscribeVerifyComponent = this.verifyDialogRef.content.instance;

    verifyComponent.cancel.subscribe(() => {
      this.preferencesForm.get('email').setValue(true, { emitEvent: false });
      this.preferencesForm.get('allPromotions').setValue(false, { emitEvent: false });
      const otbPromotions = this.preferencesForm.get('otbPromotions');
      otbPromotions.setValue(true, { emitEvent: false });
      otbPromotions.enable();
      const emailMagazine = this.preferencesForm.get('emailMagazine');
      emailMagazine.setValue(false, { emitEvent: false });

      const informational = this.preferencesForm.get('informational');
      informational.setValue(false, { emitEvent: false });

      const announcements = this.preferencesForm.get('announcements');
      announcements.setValue(false, { emitEvent: false });

      const rewardsAndReferral = this.preferencesForm.get('rewardsAndReferral');
      rewardsAndReferral.setValue(false, { emitEvent: false });
      this.verifyDialogRef.close();
    });

    verifyComponent.verify.subscribe(() => {
      this.verifyDialogRef.close();
    });

    this.verifyDialogRef.result.subscribe((result) => {
      this.verifyDialogRef = null;
    });
  }

  private showSurveyDialog(): void {
    this.surveyDialogRef = this.dialogService.open({
      title: this.successMessage,
      width: 600,
      maxHeight: '90%',
      maxWidth: '90%',
      content: UnsubscribeSurveyComponent,
    });

    const surveyComponent: UnsubscribeSurveyComponent = this.surveyDialogRef.content.instance;
    surveyComponent.contactPreferences = this.contactPreferences;
    surveyComponent.popupMode = true;

    surveyComponent.surveySaved.subscribe(() => {
      this.surveyDialogRef.close();
    });

    this.surveyDialogRef.result.subscribe((result) => {
      this.surveyDialogRef = null;
    });
  }

  private showEmailVerificationDialog() {
    this.cartDialogService.showEmailVerificationDialog(this.contactPreferences.marketingEmail, (confirm) => {
      if (confirm) {
        this.contactPreferences.marketingEmailConfirmed = true;
        this.saveContactPreferences();
      }
    });
  }
}

import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { ICart } from 'src/app/cart-common/model/cart';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from '../../service/tracking.service';

declare global {
  interface Window {
    _GUARANTEE: any;
  }
}

@Component({
  selector: 'lef-norton-shopping-guarantee',
  templateUrl: './norton-shopping-guarantee.component.html',
  styleUrls: ['./norton-shopping-guarantee.component.scss'],
})
export class NortonShoppingGuaranteeComponent implements OnInit {
  constructor(
    private readonly renderer: Renderer2,
    private readonly config: CoreConfiguration,
    private readonly trackingService: TrackingService
  ) { }

  ngOnInit(): void {
    if (this.config.environment.nortonShoppingGuaranteeTracking.enabled
      && this.trackingService.checkOneTrustActiveGroup(this.config.oneTrustGroups.mandatory)
      && this.isHeaderEnabled()
    ) {
      this.addJavaScriptResources();
      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
      this.trackingService.navigationChanged.subscribe({ next: (event) => this.onNavigationChanged(event) });
    }
  }

  addJavaScriptResources() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//guarantee-cdn.com/Web/Seal/gjs.aspx?SN=951765481';
    this.renderer.appendChild(document.body, script);
  }

  onOrderPlaced(cart: ICart) {
    if (!window._GUARANTEE || !window._GUARANTEE.Loaded) {
      return;
    }
    window._GUARANTEE.Guarantee.order = cart.orderNumber;
    window._GUARANTEE.Guarantee.subtotal = cart.totalOrder;
    window._GUARANTEE.Guarantee.currency = 'USD';
    window._GUARANTEE.Guarantee.email = '';
    window._GUARANTEE.WriteGuarantee();
  }

  private onNavigationChanged(event: NavigationEnd) {
    if (!window._GUARANTEE || !window._GUARANTEE.Loaded) {
      return;
    }
    const pages = this.config.environment.nortonShoppingGuaranteeTracking.includedOn;
    if (pages.indexOf(event.urlAfterRedirects) !== -1) {
      if (typeof window._GUARANTEE.ResetKickers === 'function') {
        try {
          setTimeout(() => {
            window._GUARANTEE.ResetKickers();
          }, 100);
        } catch (error) { }
      }
    }
  }

  private isHeaderEnabled(): boolean {
    const rgxs = this.config.environment.nonHeadersOrFooterPaths;
    const path = window.location.pathname;
    return !rgxs.find((r: RegExp) => r.test(path));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IWishList, WishListModel } from '../../model/wish.model';

@Component({
  selector: 'lef-wish-list-item-rename',
  templateUrl: './wish-list-item-rename.component.html',
  styleUrls: ['./wish-list-item-rename.component.scss'],
})
export class WishListItemRenameComponent implements OnInit {
  @Output()
  closeDialog: EventEmitter<boolean> = new EventEmitter();

  @Input()
  wishListItem: IWishList;

  model: IWishList = new WishListModel();
  form: UntypedFormGroup = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];

  constructor() {}

  ngOnInit(): void {
    this.initForm();
  }

  confirm(confirm: boolean) {
    if (!this.form.valid || !this.model) {
      return;
    }
    this.wishListItem.wishListName = this.model.wishListName;
    this.closeDialog.emit(confirm);
  }

  private initForm() {
    this.model = this.wishListItem;
    this.form = new UntypedFormGroup({});
    this.formFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'kTextBox',
            key: 'wishListName',
            templateOptions: {
              label: 'Wish List Name:',
            },
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
    ];
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IContactPreferences } from '../../model';
import { ContactPreferencesService } from '../../service/contact-preferences.service';

@Component({
  selector: 'lef-unsubscribe-prospect',
  templateUrl: './unsubscribe-prospect.component.html',
  styleUrls: ['./unsubscribe-prospect.component.scss'],
})
export class UnsubscribeProspectComponent implements OnInit {
  @Input() contactPreferences: IContactPreferences;
  @Input() enableSurvey = false;

  get hasPreferences(): boolean {
    return this.contactPreferences && this.contactPreferences.isProspect;
  }

  private wasEmailSubscribed = false;

  constructor(private readonly contactPreferencesService: ContactPreferencesService, private readonly loadingService: LoadingService) {}

  ngOnInit(): void {
    this.unSubScribeProspect();
  }

  unSubScribeProspect(): void {
    if (!this.hasPreferences) {
      return;
    }

    this.wasEmailSubscribed = true;
    this.contactPreferences.blockEmail = true;
    this.saveProspectPreferences();
  }

  reSubscribeProspect() {
    this.contactPreferences.blockEmail = false;
    this.saveProspectPreferences();
  }

  private saveProspectPreferences() {
    this.loadingService.show('Saving...');
    this.contactPreferencesService.updateProspectPreferences(this.contactPreferences).subscribe({
      next: (contactPreferences: IContactPreferences) => {
        this.contactPreferences = contactPreferences;

        if (this.wasEmailSubscribed && this.contactPreferences.blockEmail) {
        }

        this.wasEmailSubscribed = !this.contactPreferences.blockEmail;
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }
}

<form class="k-form" [formGroup]="surveyForm" (ngSubmit)="saveSurvey()">
  <div class="bg-light-blue p-3" [ngClass]="{'mb-3': popupMode}">
    <p class="text-primary font-weight-bold font-lg">We're sorry to see you go!</p>
    <p class="text-primary font-weight-bold font-md">Please tell us why you unsubscribed.</p>
    <p>It will only take 10 seconds of your time and it will help us get better.</p>
    <formly-form [model]="unsubscribeSurvey" [fields]="surveyFormFields" [form]="surveyForm">
    </formly-form>
    <div *ngIf="!popupMode && surveyForm.enabled" class="text-right">
      <button type="submit" class="btn btn btn-warning w-100 w-md-u" [disabled]="!canSubmit">
        Send Feedback
      </button>
    </div>
    <lef-cart-messages *ngIf="showSuccess" [messages]="messages"></lef-cart-messages>
  </div>
  <div *ngIf="popupMode" class="text-right">
    <button type="submit" class="btn btn btn-warning w-100 w-md-u" [disabled]="!canSubmit">
      Send Feedback
    </button>
  </div>
</form>

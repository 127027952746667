import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { PremierService } from 'src/app/cart-common/service';
import { ResponsiveService, ScreenSizes } from 'src/app/common/service/responsive.service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { PaymentMethodSelectorComponent } from 'src/app/payment/component';
import { IPaymentOption } from 'src/app/payment/model';
import { PayPalCheckoutService } from 'src/app/payment/service/pay-pal-checkout-service.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ShippingAddressSelectionComponent, ShippingMethodSelectionComponent } from 'src/app/shipping/component';
import { IReturnItem, IReturnOrder, ReturnOptions, ReturnSteps } from '../../model';
import { ReturnsService } from '../../service/returns.service';
import { ReturnItemComponent } from '../return-item/return-item.component';
import { ShippingLabelComponent } from '../shipping-label/shipping-label.component';

@Component({
  selector: 'lef-return-order',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './return-order.component.html',
  styleUrls: ['./return-order.component.scss']
})
export class ReturnOrderComponent implements OnInit, OnDestroy {
  @ViewChildren(ReturnItemComponent) private returnItemComponents: QueryList<ReturnItemComponent>;
  @ViewChild('coverPayment', { read: ElementRef, static: false }) private coverPayment: ElementRef;
  @ViewChild('shippingAddress', { read: ElementRef, static: false }) private shippingAddress: ElementRef;
  @ViewChild(PaymentMethodSelectorComponent, { read: PaymentMethodSelectorComponent, static: false })
  private paymentSelector: PaymentMethodSelectorComponent;

  private done$ = new Subject<void>();

  private infoDialogRef: DialogRef;
  private addressDialogRef: DialogRef;
  private shipmentDialogRef: DialogRef;
  private shippingLabelDialogRef: DialogRef;
  private criteria: string;
  private daysRange: number;

  private get shippingValid(): boolean {
    return (
      this.replacementItems.length === 0 ||
      (this.returnOrder.shippingSummary.selectedAddress && this.returnOrder.shippingSummary.selectedAddress.isContinentalUs)
    );
  }

  orderNumber: number;
  source: string;

  returnOrder: IReturnOrder;
  returnSteps = ReturnSteps;
  screenSizes = ScreenSizes;

  showReturnsCompleteMessage: boolean;
  showNoItemsToReturnMessage: boolean;
  showNotEligibleMessage: boolean;
  showNoItemsMessage: boolean;
  showPaymentMessage: boolean;
  showShippingMessage: boolean;
  orderInvalid: boolean;

  shoppingUrl: string;

  get titleText(): string {
    return this.returnOrder && this.returnOrder.returnStep === ReturnSteps.Complete ? 'Return Confirmation' : 'Return or Replace an Item';
  }

  get previousStepText(): string {
    switch (this.returnOrder.returnStep) {
      case ReturnSteps.SetOptions:
        return 'Choose Items to Return';
      case ReturnSteps.Confirm:
        return 'Return Options';
      default:
        return '';
    }
  }

  get showReturnToOrders(): boolean {
    return !this.returnOrder || this.returnOrder.returnStep === ReturnSteps.SelectItems;
  }

  get showPreviousStep(): boolean {
    return this.returnOrder && this.returnOrder.returnStep > ReturnSteps.SelectItems && this.returnOrder.returnStep < ReturnSteps.Complete;
  }

  get showContinueShopping(): boolean {
    return this.returnOrder && this.returnOrder.returnStep === ReturnSteps.Complete;
  }

  get showPayment(): boolean {
    return (
      this.returnOrder.returnStep >= ReturnSteps.Confirm &&
      !this.returnOrder.isRmaOnly &&
      (this.returnOrder.mustSendBackItems ||
        (this.returnOrder.shippingSummary &&
          this.returnOrder.shippingSummary.shippingMethod &&
          this.returnOrder.shippingSummary.shippingMethod.rate > 0))
    );
  }

  get returnItems(): IReturnItem[] {
    if (!this.returnOrder || !this.returnOrder.returnItems) {
      return [];
    }

    if (this.returnOrder.returnStep === ReturnSteps.SelectItems) {
      return this.returnOrder.returnItems;
    }

    if (this.returnOrder.returnStep >= ReturnSteps.Confirm) {
      return this.returnOrder.returnItems.filter((returnItem: IReturnItem) => {
        return returnItem.isSelected && returnItem.returnOption !== ReturnOptions.ReplaceItem;
      });
    }

    return this.returnOrder.returnItems.filter((returnItem: IReturnItem) => {
      return returnItem.isSelected;
    });
  }

  get confirmedItems(): IReturnItem[] {
    return this.returnOrder.returnItems.filter((returnItem: IReturnItem) => {
      return returnItem.quantityToSendBack > 0;
    });
  }

  get replacementItems(): IReturnItem[] {
    if (!this.returnOrder || !this.returnOrder.returnItems) {
      return [];
    }

    if (this.returnOrder.returnStep >= ReturnSteps.Confirm) {
      return this.returnOrder.returnItems.filter((returnItem: IReturnItem) => {
        return returnItem.isSelected && returnItem.returnOption === ReturnOptions.ReplaceItem;
      });
    }

    return [];
  }

  get returnsSelected(): boolean {
    return (
      this.returnOrder &&
      this.returnOrder.returnItems &&
      this.returnOrder.returnItems.some((returnItem: IReturnItem) => returnItem.isSelected)
    );
  }

  get valid(): boolean {
    return !this.returnItemComponents.some((returnItem: ReturnItemComponent) => !returnItem.valid);
  }

  get replacementRate(): string {
    return this.premierService.getRateText(this.returnOrder.shippingSummary.shippingMethod.rate);
  }

  get showCustomerServiceMessage(): boolean {
    return this.showNotEligibleMessage || this.showNoItemsMessage || this.showNoItemsToReturnMessage;
  }

  get noReturnsMessage(): string {
    return this.showNotEligibleMessage
      ? 'To complete this return, please contact customer service.'
      : this.showNoItemsToReturnMessage
      ? `All eligible items for this order have been returned. <br/> If you need further assistance, please contact customer service.`
      : `No items were found to return for this order. <br/> If you need help, please contact customer service.`;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly returnsService: ReturnsService,
    private readonly dialogService: DialogService,
    private readonly responsiveService: ResponsiveService,
    private readonly loadingService: LoadingService,
    private readonly premierService: PremierService,
    private location: Location,
    baseConfig: CoreConfiguration,
    private readonly payPalCheckOutService: PayPalCheckoutService
  ) {
    this.shoppingUrl = baseConfig.environment.contentSite;
  }

  ngOnInit() {
    this.payPalCheckOutService.refreshPayPalInstanceForCapture(null);
    this.loadOrder();
    const routeState = this.location.getState() as any;
    this.daysRange = routeState.daysRange;
    this.criteria = routeState.criteria;
    this.source = routeState.source;
  }

  ngOnDestroy(): void {
    this.done$.next();
    this.done$.complete();
  }

  private loadOrder() {
    this.orderNumber = parseInt(this.route.snapshot.params.orderNumber, 10);
    if (isNaN(this.orderNumber)) {
      this.source = 'history';
      this.backToOrder();
    }
    this.loadingService.show('Loading Your Order...');
    this.returnsService
      .getReturnOrder(this.orderNumber)
      .pipe(takeUntil(this.done$))
      .subscribe({
        next: (returnOrder: IReturnOrder) => {
          if (returnOrder.returnItems.length < 1) {
            this.showNoItemsMessage = true;
            return;
          }

          if (returnOrder.returnComplete) {
            this.showReturnsCompleteMessage = true;
            return;
          }

          if (!returnOrder.hasItemsToReturn) {
            this.showNoItemsToReturnMessage = true;
            return;
          }

          if (!returnOrder.eligibleForReturns) {
            this.showNotEligibleMessage = true;
            return;
          }

          this.returnOrder = returnOrder;
        },
        error: (error) => {
          this.loadingService.hide();
        },
        complete: () => {
          this.loadingService.hide();
        }
      });
  }

  backToOrder() {
    if (this.source === 'history') {
      this.location.back();
    } else {
      this.router.navigate([`account/orderhistory/${this.orderNumber}`]);
    }
  }

  //#region Returns Flow
  setNextStep(): void {
    switch (this.returnOrder.returnStep) {
      case ReturnSteps.SelectItems:
        this.selectReturnItems();
        break;
      case ReturnSteps.SetOptions:
        this.setReturnOptions();
        break;
      case ReturnSteps.Confirm:
        this.confirm();
        break;
    }
  }

  setPreviousStep(): void {
    this.returnsService
      .setPreviousStep(this.returnOrder)
      .pipe(takeUntil(this.done$))
      .subscribe({
        next: (returnOrder: IReturnOrder) => {
          this.setReturnOrder(returnOrder);
          this.orderInvalid = false;
        }
      });
  }

  private setReturnOrder(returnOrder: IReturnOrder) {
    this.returnOrder = returnOrder;
    window.scrollTo(0, 0);
  }

  private selectReturnItems() {
    for (const returnItem of this.returnItemComponents) {
      if (!returnItem.returnItem.isSelected) {
        continue;
      }
      returnItem.selectItemForm.markAsTouched();
      Object.keys(returnItem.selectItemForm.controls).forEach((field) => {
        const control = returnItem.selectItemForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });
    }

    if (!this.valid) {
      const element = this.getInvalidReturnElement();
      this.scrollToInvalid(element);
      return;
    }

    this.returnsService
      .selectReturnItems(this.returnOrder)
      .pipe(takeUntil(this.done$))
      .subscribe({
        next: (returnOrder: IReturnOrder) => {
          this.setReturnOrder(returnOrder);
        }
      });
  }

  private setReturnOptions() {
    this.returnsService
      .setReturnOptions(this.returnOrder)
      .pipe(takeUntil(this.done$))
      .subscribe({
        next: (returnOrder: IReturnOrder) => {
          this.setReturnOrder(returnOrder);
        }
      });
  }

  private confirm() {
    if (!this.validateOrder()) {
      return;
    }

    if (this.paymentSelector) {
      this.paymentSelector.isSelectionBoxOpen = false;
    }
    this.loadingService.show(['Please Wait...', 'Getting Your Return Ready', 'Processing Your Return'], true);
    this.returnsService
      .completeReturn(this.returnOrder)
      .pipe(takeUntil(this.done$))
      .subscribe({
        next: (returnOrder: IReturnOrder) => {
          this.setReturnOrder(returnOrder);
          if (!returnOrder.isReturnValid) {
            // Light it up.
            this.orderInvalid = !this.validateOrder(true);
          } else {
            // Clear the flag.
            this.orderInvalid = false;
          }
        },
        error: (error) => {
          this.loadingService.hide();
        },
        complete: () => {
          this.loadingService.hide();
        }
      });
  }

  private validateOrder(checkAll: boolean = false) {
    let isOrderValid = true;
    if (this.returnOrder.mustSendBackItems) {
      for (const returnItem of this.returnItemComponents) {
        if (!returnItem.returnItem.isSelected) {
          continue;
        }
        returnItem.confirmForm.markAsTouched();
        Object.keys(returnItem.confirmForm.controls).forEach((field) => {
          const control = returnItem.confirmForm.get(field);
          control.markAsTouched({ onlySelf: true });
        });
      }
    }

    if (!this.valid) {
      isOrderValid = false;
      if (!checkAll) {
        const element = this.getInvalidReturnElement();
        this.scrollToInvalid(element);
        return isOrderValid;
      }
    }

    if (this.showPayment && !this.returnOrder.paymentOptionSelected) {
      this.showPaymentMessage = true;
      isOrderValid = false;
      if (!checkAll) {
        const element = this.coverPayment.nativeElement;
        this.scrollToInvalid(element);
        return isOrderValid;
      }
    }

    if (!this.shippingValid) {
      this.showShippingMessage = true;
      isOrderValid = false;
      if (!checkAll) {
        const element = this.shippingAddress.nativeElement;
        this.scrollToInvalid(element);
        return isOrderValid;
      }
    }

    return isOrderValid;
  }

  private getInvalidReturnElement(): HTMLDivElement {
    const firstInvalid = this.returnItemComponents.find((comp: ReturnItemComponent) => !comp.valid);
    const element = firstInvalid.returnItemDiv.nativeElement as HTMLDivElement;
    return element;
  }

  private scrollToInvalid(element: HTMLDivElement): void {
    const rect = element.getBoundingClientRect();
    const offset = this.responsiveService.currentSize < ScreenSizes.LG ? 46 : 165;
    window.scrollTo(0, window.scrollY + rect.y - offset);
  }
  //#endregion Returns Flow

  onPaymentOptionSelected(paymentOption: IPaymentOption) {
    this.returnOrder.paymentOptionSelected = paymentOption;
    this.showPaymentMessage = false;
  }

  openShippingAddressDialog(): void {
    this.addressDialogRef = this.dialogService.open({
      title: 'Shipping Address',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingAddressSelectionComponent
    });

    const addressSelection: ShippingAddressSelectionComponent = this.addressDialogRef.content.instance;
    addressSelection.searchEnable = this.returnOrder.shippingSummary.shippingAddressSearchEnable;
    addressSelection.continentalOnly = true;
    addressSelection.shippingAddresses = this.returnOrder.shippingSummary.shippingAddresses;
    addressSelection.selectionVerified
      .pipe(
        takeUntil(this.done$),
        switchMap((shipTo: number) => this.returnsService.setShippingDestination(shipTo))
      )
      .subscribe({
        next: (returnOrder: IReturnOrder) => {
          this.returnOrder = returnOrder;
          this.showShippingMessage = !this.shippingValid;
          this.addressDialogRef.close();
        }
      });

    addressSelection.updateCart.pipe(takeUntil(this.done$)).subscribe({
      next: () => {
        // this.updateCart.emit();
        this.addressDialogRef.close();
      }
    });

    this.addressDialogRef.result.pipe(takeUntil(this.done$)).subscribe({
      next: () => {
        this.addressDialogRef = null;
      }
    });
  }

  openUnavailableInfoDialog(unavailableInfo: TemplateRef<any>): void {
    this.infoDialogRef = this.dialogService.open({
      title: 'Return Not Available',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: unavailableInfo
    });

    this.infoDialogRef.result.pipe(takeUntil(this.done$)).subscribe({
      next: () => {
        this.infoDialogRef = null;
      }
    });
  }

  openDeadlineInfoDialog(deadlineInfo: TemplateRef<any>): void {
    this.infoDialogRef = this.dialogService.open({
      title: 'Deadline to Return Items(s)',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: deadlineInfo
    });

    this.infoDialogRef.result.pipe(takeUntil(this.done$)).subscribe({
      next: () => {
        this.infoDialogRef = null;
      }
    });
  }

  openShippingMethodDialog(): void {
    this.shipmentDialogRef = this.dialogService.open({
      title: 'Shipping Method',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingMethodSelectionComponent
    });

    const shippingSelection: ShippingMethodSelectionComponent = this.shipmentDialogRef.content.instance;
    shippingSelection.selectedMethod = this.returnOrder.shippingSummary.shippingMethod.shippingMethod;
    shippingSelection.shippingMethods = this.returnOrder.shippingSummary.shippingMethods;
    shippingSelection.methodSelected
      .pipe(
        takeUntil(this.done$),
        switchMap((shippingMethod: string) => this.returnsService.setShippingMethod(shippingMethod))
      )
      .subscribe({
        next: (returnOrder: IReturnOrder) => {
          this.returnOrder = returnOrder;
          if (this.shipmentDialogRef) {
            this.shipmentDialogRef.close();
          }
        }
      });

    this.shipmentDialogRef.result.pipe(takeUntil(this.done$)).subscribe({
      next: () => {
        this.shipmentDialogRef = null;
      }
    });
  }

  openShippingLabelDialog(actionTemplate: TemplateRef<any>): void {
    this.shippingLabelDialogRef = this.dialogService.open({
      title: 'Prepaid Shipping Label',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: ShippingLabelComponent,
      actions: actionTemplate
    });

    const shippingLabel: ShippingLabelComponent = this.shippingLabelDialogRef.content.instance;
    shippingLabel.trackingNumber = this.returnOrder.trackingNumber;
    shippingLabel.autoPrint = true;

    this.shippingLabelDialogRef.result.pipe(takeUntil(this.done$)).subscribe({
      next: () => {
        this.shippingLabelDialogRef = null;
      }
    });
  }

  printLabel() {
    const shippingLabel: ShippingLabelComponent = this.shippingLabelDialogRef.content.instance;
    shippingLabel.printLabel();
  }

  printConfirmation() {
    window.print();
  }
}

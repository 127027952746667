import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PremierService } from 'src/app/cart-common/service';
import { PaymentType } from 'src/app/payment/model';
import { IReturnItem, IReturnOrder, ReturnOptions, ReturnSteps } from '../../model';

@Component({
  selector: 'lef-return-summary',
  templateUrl: './return-summary.component.html',
  styleUrls: ['./return-summary.component.scss'],
})
export class ReturnSummaryComponent {
  @Input() returnsSelected: boolean;
  @Input() returnOrder: IReturnOrder;

  @Output() nextStep: EventEmitter<void> = new EventEmitter();

  returnSteps = ReturnSteps;
  paymentTypes = PaymentType;

  get buttonLabel(): string {
    return this.returnOrder.returnStep > ReturnSteps.SetOptions ? 'Submit Return' : 'Continue with Return';
  }

  get hasRefundItems(): boolean {
    return this.returnOrder.returnItems.some(
      (returnItem: IReturnItem) => returnItem.isSelected && returnItem.returnOption === ReturnOptions.RefundPayment
    );
  }

  get refundLabel(): string {
    return this.returnOrder.amountDue < 0 ? 'CHARGES' : 'TOTAL REFUND';
  }

  get refundAmount(): number {
    return this.returnOrder.amountDue < 0 ? -this.returnOrder.amountDue : this.returnOrder.amountDue;
  }

  get refundTaxLabel(): string {
    return this.returnOrder.refundTotalTax < 0 ? 'Tax Charges' : 'Refund Tax';
  }

  get refundTaxAmount(): number {
    return this.returnOrder.refundTotalTax < 0 ? -this.returnOrder.refundTotalTax : this.returnOrder.refundTotalTax;
  }

  get refundExplanation(): string {
    return this.returnOrder.isRmaOnly
      ? 'Your refund is being processed and will be issued within 1-3 business days of receiving the returned items to our warehouse.'
      : 'A refund will be issued within 1-3 business days.';
  }

  get replacementExplanation(): string {
    return this.returnOrder.isRmaOnly
      ? 'Your replacement items are being processed will be issued within 1-3 business days of receiving the returned items to our warehouse.'
      : 'Your replacement items will be issued within 1-3 business days.';
  }

  constructor(public readonly premierService: PremierService) { }
}

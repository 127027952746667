import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FloatingCartService } from 'src/app/common/service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'lef-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private readonly loginService: LoginService,
    private readonly router: Router,
    private readonly floatingCartService: FloatingCartService,
    private readonly trackingService: TrackingService,
    public readonly config: CoreConfiguration) { }

  ngOnInit() {
    this.logout();
  }

  logout() {
    this.loginService.logout().subscribe(
      () => {
        this.redirectToCartPage();
        this.floatingCartService.refreshItemsCount();
        this.trackingService.logOut.next(null);
      },
      () => this.redirectToCartPage()
    );
  }

  redirectToCartPage() {
    this.router.navigateByUrl(`${this.config.webRoutes.root}`);
  }
}

import { Injectable } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { WishListItemDeleteConfirmationComponent } from '../component/wish-list-item-delete-confirmation/wish-list-item-delete-confirmation.component';
import { WishListItemRenameComponent } from '../component/wish-list-item-rename/wish-list-item-rename.component';
import { WishListItemCreateComponent } from '../component/wish-list-item/wish-list-item-create.component';
import { IWishList } from './../model/wish.model';

@Injectable({
  providedIn: 'root',
})
export class FavoriteDialogService {
  constructor(private readonly dialogService: DialogService) { }

  openWishListItemDialog(onClose: () => void = null): DialogRef {
    const dialogRef = this.dialogService.open({
      title: 'Create New Wish List',
      width: 1200,
      maxHeight: '90%',
      maxWidth: '90%',
      content: WishListItemCreateComponent,
    });

    const componentRef: WishListItemCreateComponent = dialogRef.content.instance;
    componentRef.closeDialog.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.result.subscribe((result) => {
      if (onClose) {
        onClose();
      }
    });

    return dialogRef;
  }

  openWishListItemCreateDialog(wishlistItem: IWishList, onClose: (confirm: boolean) => void) {
    const dialogRef = this.dialogService.open({
      title: 'Rename Wish List',
      width: 450,
      maxHeight: '90%',
      maxWidth: '90%',
      content: WishListItemCreateComponent,
    });

    const componentRef: WishListItemCreateComponent = dialogRef.content.instance;
    componentRef.wishListItem = wishlistItem;

    componentRef.closeDialog.subscribe((confirm: boolean) => {
      dialogRef.close();
      onClose(confirm);
    });

    dialogRef.result.subscribe((result: any) => {
      dialogRef.close();
    });
  }

  openWishListItemRenameDialog(wishlistItem: IWishList, onClose: (confirm: boolean) => void) {
    const dialogRef = this.dialogService.open({
      title: 'Rename Wish List',
      width: 450,
      maxHeight: '90%',
      maxWidth: '90%',
      content: WishListItemRenameComponent,
    });

    const componentRef: WishListItemRenameComponent = dialogRef.content.instance;
    componentRef.wishListItem = wishlistItem;

    componentRef.closeDialog.subscribe((confirm: boolean) => {
      dialogRef.close();
      onClose(confirm);
    });

    dialogRef.result.subscribe((result: any) => {
      dialogRef.close();
    });
  }

  openWishListDeleteDialog(wishListId: string, onClose: (confirm: boolean) => void) {
    const dialogRef: DialogRef = this.dialogService.open({
      title: 'Delete Wish List',
      content: WishListItemDeleteConfirmationComponent,
      width: 450,
      maxHeight: '90%',
      maxWidth: '90%',
    });

    const componentRef: WishListItemDeleteConfirmationComponent = dialogRef.content.instance;
    componentRef.wishListId = wishListId;

    componentRef.closeDialog.subscribe((confirm: boolean) => {
      dialogRef.close();
      onClose(confirm);
    });

    dialogRef.result.subscribe((result: any) => {
      dialogRef.close();
    });
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FeaturesService } from 'src/app/shared/service/features.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IPaymentGateway, IPaymentOption, PaymentGateway, PaymentOptionCollection, PaymentType } from '../../model';
import { PaymentMethod } from '../../model/payment-method';
import { ApplePayService } from '../../service/apple-pay.service';
import { GooglePayService } from '../../service/google-pay.service';
import { PayPalCheckoutService } from '../../service/pay-pal-checkout-service.service';
import { PaymentOptionService } from '../../service/payment-option.service';
import { PaymentNewMethodComponent } from '../payment-new-method/payment-new-method.component';

@Component({
  selector: 'lef-payment-method-selector',
  templateUrl: './payment-method-selector.component.html',
  styleUrls: ['./payment-method-selector.component.scss'],
})
export class PaymentMethodSelectorComponent implements OnInit, OnChanges {
  @Input() paymentOptionSelectedId: string;
  @Input() paymentMethodLabel: string;
  @Input() labelClass: string;
  @Input() hideSelectedMethodImage: boolean;
  @Input() hidePaymentMethodActions: boolean;
  @Input() preventChange = false;
  @Input() isCheckOut: boolean;
  @Input() isReturn: boolean;
  @Input() instantSave: boolean;
  @Input() initialPayment: IPaymentOption;
  @Input() isPayPalCheckoutFlowEnabled = false;
  @Input() isApplePayCheckoutFlowEnabled = false;
  @Input() isGooglePayCheckoutFlowEnabled = false;
  @Input() amountDue: number;

  @Output() paymentOptionSelected: EventEmitter<IPaymentOption> = new EventEmitter();

  isLoadingInitialData = true;
  initialPaymentOptions: IPaymentOption[] = [];
  paymentOptions: IPaymentOption[] = [];
  paymentSelected: IPaymentOption;
  gateway: IPaymentGateway = new PaymentGateway();
  isSelectionBoxOpen = false;
  isAnonymousGuest = false;
  payPalButtonReady = false;
  payWithCheckoutFlowEnabled = false;
  newCreditCardLabel = 'Add a New Credit or Debit Card';

  get hasPaymentOptions(): boolean {
    return this.paymentOptions.length > 0 || !!this.paymentSelected;
  }

  get hidePaymentActions(): boolean {
    return (
      this.isAnonymousGuest ||
      this.hidePaymentMethodActions ||
      (!!this.paymentSelected && (this.paymentSelected.hidePaymentMethodActions || this.paymentSelected.description === 'On Account'))
    );
  }

  get isAllowSetAsDefault(): boolean {
    return this.paymentSelected.saveOnFile && this.paymentSelected.isAllowSetAsDefault && !this.isFirstPaymentMethod;
  }

  get isFirstPaymentMethod(): boolean {
    return this.paymentOptions.filter((p) => p.isOnFile).length === 0;
  }

  get isPayPalPaymentSelected(): boolean {
    return this.paymentSelected?.type === PaymentType.PayPal;
  }

  get usePayPalCheckoutFlow(): boolean {
    return this.isCheckOut || this.payWithCheckoutFlowEnabled || this.isPayPalCheckoutFlowEnabled;
  }

  get isPayPalEnabled(): boolean {
    return this.payPalCheckoutService.isPayPalServiceActive() && this.isCheckOut && this.amountDue > 0;
  }

  get isApplePayEnabled(): boolean {
    return (
      this.applePayService.isApplePaySessionActive &&
      this.isCheckOut &&
      (this.isApplePayCheckoutFlowEnabled || this.isApplePayFeatureEnabled)
    );
  }

  get isGooglePayEnabled(): boolean {
    return (
      this.googlePayService.isGooglePaySessionActive &&
      this.isCheckOut &&
      (this.isGooglePayCheckoutFlowEnabled || this.isGooglePayFeatureEnabled)
    );
  }

  get showNewCardPaymentBox(): boolean {
    return this.paymentSelected && this.paymentSelected.verifyAtCheckout && this.paymentSelected.type == PaymentType.CreditCard;
  }

  private isGooglePayFeatureEnabled: boolean = false;
  private isApplePayFeatureEnabled: boolean = false;

  constructor(
    private readonly loadingService: LoadingService,
    private readonly payPalCheckoutService: PayPalCheckoutService,
    private readonly applePayService: ApplePayService,
    private readonly googlePayService: GooglePayService,
    private readonly paymentOptionService: PaymentOptionService,
    private readonly dialogService: DialogService,
    private readonly featuresService: FeaturesService
  ) {
    this.isGooglePayFeatureEnabled = this.featuresService.isFeatureEnable(this.featuresService.featuresList.googlePay);
    this.isApplePayFeatureEnabled = this.featuresService.isFeatureEnable(this.featuresService.featuresList.applePay);

    this.payPalCheckoutService.onButtonsReady.subscribe((result) => {
      this.payPalButtonReady = result;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.amountDue && !changes.amountDue.firstChange) {
      this.validatePaymentOptions();
    }
  }

  ngOnInit(): void {
    this.loadPaymentOptions();
  }

  loadPaymentOptions(): void {
    if (this.initialPayment) {
      this.paymentSelected = this.initialPayment;
      this.paymentOptionSelectedId = this.initialPayment.id;
    }

    this.isLoadingInitialData = true;
    this.paymentOptionService.paymentOptions(this.isReturn, this.isCheckOut).subscribe(
      (result) => {
        this.isLoadingInitialData = false;
        this.initialPaymentOptions = result.paymentOptions;
        this.gateway = result.gateway;

        this.validatePaymentOptions();

        if (
          this.isCheckOut &&
          (this.paymentSelected?.type === PaymentType.GooglePay || this.paymentSelected?.type === PaymentType.ApplePay)
        ) {
          this.isLoadingInitialData = true;
          this.selectNewPayment(PaymentType[this.paymentSelected.type]);
        }

        if (
          this.isReturn &&
          (this.paymentSelected?.type === PaymentType.GooglePay || this.paymentSelected?.type === PaymentType.ApplePay)
        ) {
          this.removeSelectedPayment();
        }

        this.isSelectionBoxOpen = !this.paymentSelected;
        this.isAnonymousGuest = result.isAnonymousGuest;
        this.payWithCheckoutFlowEnabled = this.isCheckOut && result.isPayPalCheckoutFlowEnabled;
      },
      (error) => {
        this.isLoadingInitialData = false;
      }
    );
  }

  selectPaymentOption(option: IPaymentOption): void {
    this.paymentOptionSelectedId = option.id;
    this.paymentSelected = this.paymentOptions?.find((p) => p.id === option.id);
    this.isSelectionBoxOpen = !this.paymentSelected;
    this.paymentOptionSelected.emit(option);
  }

  onPaymentOptionsChanged(paymentCollection: PaymentOptionCollection): void {
    this.paymentOptions = paymentCollection.paymentOptions;
    this.paymentOptionSelectedId = paymentCollection.paymentOptionSelectedId;
    this.paymentSelected = this.paymentOptions?.find((p) => p.id === this.paymentOptionSelectedId);
    this.checkPaymentOptionByDevice();
    this.isSelectionBoxOpen = !this.paymentSelected;
    this.isLoadingInitialData = false;
    this.paymentOptionSelected.emit(this.paymentSelected);
  }

  onChangeSaveSelectedPaymentMethod($event): void {
    if (this.paymentSelected.isAllowSaveOnFile) {
      this.paymentSelected.saveOnFile = $event;
      if (!this.paymentSelected.saveOnFile && this.paymentSelected.setAsDefault) {
        this.paymentSelected.setAsDefault = false;
      } else if (this.paymentSelected.saveOnFile && this.isFirstPaymentMethod) {
        this.paymentSelected.setAsDefault = true;
      }

      if (this.paymentSelected.type === PaymentType.ApplePay || this.paymentSelected.type === PaymentType.GooglePay) {
        this.paymentSelected.setAsDefault = this.paymentSelected.saveOnFile;
      }

      this.paymentOptionSelected.emit(this.paymentSelected);
    }
  }

  onChangeSetDefaultSelectedPaymentMethod($event) {
    if (this.paymentSelected.isAllowSetAsDefault) {
      this.paymentSelected.setAsDefault = $event;

      if (this.paymentSelected.type === PaymentType.ApplePay || this.paymentSelected.type === PaymentType.GooglePay) {
        this.paymentSelected.saveOnFile = this.paymentSelected.setAsDefault;
      }

      this.paymentOptionSelected.emit(this.paymentSelected);
    }
  }

  onPayAtCheckoutSelected($event) {
    this.paymentSelected.payAtCheckout = $event;
    this.paymentOptionService.updatePaymentOption(this.paymentSelected, this.isCheckOut).subscribe(
      (result) => { },
      (error) => { }
    );
    this.paymentOptionSelected.emit(this.paymentSelected);
  }

  selectNewPayment(paymentType: string) {
    switch (paymentType) {
      case 'Paypal':
        this.useWalletCheckoutFlowOption(PaymentType.PayPal, PaymentMethod.PayPal);
        break;
      case 'ApplePay':
        this.useWalletCheckoutFlowOption(PaymentType.ApplePay, PaymentMethod.ApplePay);
        break;
      case 'GooglePay':
        this.useWalletCheckoutFlowOption(PaymentType.GooglePay, PaymentMethod.GooglePay);
        break;
      case 'Card':
        this.useNewCardCheckoutFlowOption();
        break;
    }
  }

  private useWalletCheckoutFlowOption(paymentType: PaymentType, paymentMethod: PaymentMethod) {
    this.loadingService.show();
    this.paymentOptionService.selectWalletCheckoutFlowOption(paymentType, paymentMethod).subscribe(
      (result: PaymentOptionCollection) => {
        this.gateway = result.gateway;
        if (result.messages.length === 0) {
          this.onPaymentOptionsChanged(result);
        }
        this.loadingService.hide();
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }

  private useNewCardCheckoutFlowOption() {
    this.loadingService.show();
    this.paymentOptionService.selectNewCreditCardCheckoutFlowOption().subscribe(
      (result: PaymentOptionCollection) => {
        this.gateway = result.gateway;
        if (result.messages.length === 0) {
          this.onPaymentOptionsChanged(result);
        }
        this.loadingService.hide();
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }

  private checkPaymentOptionByDevice(): void {
    switch (this.paymentSelected?.type) {
      case PaymentType.GooglePay:
        if (!this.isGooglePayEnabled) {
          this.removeSelectedPayment();
        }
        this.setWalletSavingData();
        break;

      case PaymentType.ApplePay:
        if (!this.isApplePayEnabled) {
          this.removeSelectedPayment();
        }
        this.setWalletSavingData();
        break;
    }
  }

  private removeSelectedPayment(): void {
    this.paymentOptions = this.paymentOptions.filter((x) => x.id != this.paymentOptionSelectedId);
    this.paymentOptionSelectedId = undefined;
    this.paymentSelected = undefined;
  }

  private setWalletSavingData(): void {
    if (this.isLoadingInitialData && this.paymentOptions.some((x) => x.type === this.paymentSelected?.type && x.setAsDefault)) {
      this.paymentSelected.setAsDefault = true;
      this.paymentSelected.saveOnFile = true;
    }
  }

  private validatePaymentOptions(): void {
    this.paymentOptions = this.initialPaymentOptions;

    if (this.isCheckOut && this.amountDue <= 0 && this.paymentOptions.some(p => p.type === PaymentType.PayPal)) {
      this.paymentOptions = this.paymentOptions.filter((x) => x.type != PaymentType.PayPal);
    }

    this.paymentSelected = this.paymentOptions?.find((p) => p?.id === this?.paymentOptionSelectedId);
    this.isSelectionBoxOpen = !this.paymentSelected;
  }

  openNewPaymentMethodDialog(): void {
    const paymentDialogRef = this.dialogService.open({
      title: 'Add New Payment Method',
      width: 720,
      maxHeight: '90%',
      maxWidth: '90%',
      content: PaymentNewMethodComponent,
    });

    const newCreditCardComponent = paymentDialogRef.content.instance;
    newCreditCardComponent.gateway = this.gateway;
    newCreditCardComponent.isReturn = this.isReturn;
    newCreditCardComponent.instantSave = this.instantSave;
    newCreditCardComponent.isCheckOut = this.isCheckOut;
    newCreditCardComponent.isPayPalCheckoutFlowEnabled = this.usePayPalCheckoutFlow;

    newCreditCardComponent.paymentOptionsChanged.subscribe((paymentCollection: PaymentOptionCollection) => {
      this.onPaymentOptionsChanged(paymentCollection);
      paymentDialogRef.close();
    });
  }

  toggleSelectionBox(): void {
    this.isSelectionBoxOpen = !this.isSelectionBoxOpen;
  }
}

import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IProp65 } from '../../model/prop65.model';
import { AccountService } from '../../service/account.service';

@Component({
  selector: 'lef-prop65-list',
  templateUrl: './prop65-list.component.html',
  styleUrls: ['./prop65-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Prop65ListComponent implements OnInit {
  @Output()
  closeDialog: EventEmitter<void> = new EventEmitter();

  itemProp65List: IProp65[] = [];
  constructor(private readonly accountService: AccountService) { }

  ngOnInit(): void {
    this.getItemProp65List();
  }

  getItemProp65List() {
    this.accountService.getItemProp65List().subscribe((response) => {
      this.itemProp65List = response;
    });
  }
}

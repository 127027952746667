<div class="forgot-password-form col-12" *ngIf="!forgotDialogSubmitted">
  <p class="mb-2">
    Please enter your e-mail address below to begin the password retrieval process.
    Remember that passwords are case-sensitive.
  </p>
  <form class="k-form" [formGroup]="forgotPasswordForm" (ngSubmit)="requestResetPassword()">
    <div class="d-flex flex-wrap justify-content-end">
      <div class="col-12">
        <formly-form [form]="forgotPasswordForm" [fields]="forgotPasswordFormFields" [model]="forgotPasswordModel">
        </formly-form>
      </div>
      <button type="submit" class="btn btn-warning inline-form-btn" [disabled]="!forgotPasswordForm.valid">
        Submit
      </button>
    </div>
  </form>
  <div class="d-flex justify-content-end">
    <p>Need help?<button type="button" class="btn btn-link">Contact Us</button></p>
  </div>
</div>
<div class="forgot-password-confirmation" *ngIf="forgotDialogSubmitted">
  <p>
    If this is a registered email address with Life Extension, an email to reset your password has been sent to
    the address provided.
  </p>
  <div class="float-right">
    <button type="submit" class="btn btn-warning" (click)="close()">
      Close
    </button>
  </div>
</div>
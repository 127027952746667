<lef-collapse-box [expanded]="!order.collapsed" [enabled]="false" [showIcon]="false">
  <div title class="order-hist-hdr">
    <div class="order-hist-hdr-lt">
      <span>{{order.isReturn? 'Return' : 'Order'}} #{{order.orderNumber}}</span>
      <button type="button" (click)="goToDetails()" class="btn btn-link p-0">Order Details</button>
    </div>
    <div class="order-hist-hdr-rt">
      <span class="pr-3">{{order.itemsCount}} Item(s)</span><span>{{order.total}}</span>
    </div>
  </div>
  <div body>
    <div class="p-3 order-history-info" [ngClass]="{'order-Return': order.isReturn, 'order-Order': !order.isReturn}">
      <div class="row">
        <div class="col-md-4 col-lg-6 pt-md-2">
          <strong class="text-primary font-md pr-2">{{order.orderType !== 'Return'? 'Order' : order.orderType}}
            Placed:</strong> {{order.orderDate}}
          <div>
            <div>
              <p class="pt-1">{{order.customMessage}}</p>
            </div>
          </div>
        </div>
        <div class="col-md-8 col-lg-6">
          <div class="d-md-flex order-history-actions">
            <div *ngIf="order.isReturnAllow">
              <button (click)="returnItems()" class="btn btn-link">
                Return or Replace an Item
              </button>
            </div>
            <div *ngIf="order.returnNotAvailable">
              Return Not Available <button class="btn btn-link" (click)="showReturnsAlert()"> Why? </button>
            </div>
            <button class="btn btn-warning" aria-haspopup="dialog" (click)="addAllItemsToCart()">Add All Items to
              Cart</button>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let shipment of  order.shipments">
      <div class=" order-hist-details-wrap">
        <lef-order-shipment [shippedTo]="shipment.shippedTo" [items]="shipment.items"
                            (goToOrderDetails)="openOrderDetails($event)"></lef-order-shipment>
      </div>
    </ng-container>

    <div *ngIf="order.returnItems.length > 0" class="return-items-order-wrap">
      <div class="d-flex justify-content-between pb-2 px-4 pt-2">
        <strong class="text-primary font-md">Return Items</strong>
      </div>
      <ng-container *ngFor="let item of order.returnItems">
        <div class="return-items-order">
          <lef-order-item [item]="item" [embeddedView]="true" (goToOrderDetails)="openOrderDetails($event)">
          </lef-order-item>
        </div>
      </ng-container>
    </div>
  </div>
</lef-collapse-box>
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ICartMessage } from 'src/app/cart-common/model';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ContactPreferencesService } from '../../../preferences/service/contact-preferences.service';
import { IContactPreferences, IUnsubscribeSurvey } from '../../model';

@Component({
  selector: 'lef-unsubscribe-survey',
  templateUrl: './unsubscribe-survey.component.html',
  styleUrls: ['./unsubscribe-survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UnsubscribeSurveyComponent implements OnInit {
  @Output() surveySaved: EventEmitter<void> = new EventEmitter();

  @Input() contactPreferences: IContactPreferences;
  @Input() popupMode = false;

  unsubscribeSurvey: IUnsubscribeSurvey = {};
  surveyForm: UntypedFormGroup = new UntypedFormGroup({});
  surveyFormFields: FormlyFieldConfig[] = [];
  showSuccess = false;
  messages: ICartMessage[] = [{ message: 'Thank you for your feedback', type: 'Success', showIcon: true }];

  get canSubmit(): boolean {
    return (
      this.unsubscribeSurvey.emailTooFrequent ||
      this.unsubscribeSurvey.notRelevant ||
      this.unsubscribeSurvey.notValuable ||
      this.unsubscribeSurvey.notInterested ||
      this.unsubscribeSurvey.doNotKnowWhyReceiving ||
      this.unsubscribeSurvey.preferSocialMedia
    );
  }

  constructor(private readonly contactPreferencesService: ContactPreferencesService, private readonly loadingService: LoadingService) {}

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.unsubscribeSurvey.customerNumber = this.contactPreferences.customerNumber;
    this.unsubscribeSurvey.isProspect = this.contactPreferences.isProspect;
    this.unsubscribeSurvey.email = this.contactPreferences.emailAddress;
    this.surveyForm = new UntypedFormGroup({});
    this.surveyFormFields = [
      {
        fieldGroupClassName: 'survey-fields',
        fieldGroup: [
          {
            className: '',
            type: 'checkbox',
            key: 'emailTooFrequent',
            templateOptions: {
              label: 'Emails were too frequent',
            },
          },
          {
            className: '',
            type: 'checkbox',
            key: 'notRelevant',
            templateOptions: {
              label: 'Content was not relevant',
            },
          },
          {
            className: '',
            type: 'checkbox',
            key: 'notValuable',
            templateOptions: {
              label: 'Content was not valuable',
            },
          },
          {
            className: '',
            type: 'checkbox',
            key: 'notInterested',
            templateOptions: {
              label: 'No longer interested in this content',
            },
          },
          {
            className: '',
            type: 'checkbox',
            key: 'doNotKnowWhyReceiving',
            templateOptions: {
              label: `I don't know why I'm getting these emails`,
            },
          },
          {
            className: '',
            type: 'checkbox',
            key: 'preferSocialMedia',
            templateOptions: {
              label: 'Prefer to get content via social media',
            },
          },
        ],
      },
    ];
  }

  saveSurvey(): void {
    this.loadingService.show('Submitting Survey...');
    this.contactPreferencesService.insertUnsubscribeSurvey(this.unsubscribeSurvey).subscribe({
      next: (returnValue) => {
        this.surveySaved.emit();
        if (!this.popupMode) {
          this.surveyForm.disable();
          this.showSuccess = true;
        }
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AutoShipComponent } from '../auto-ship/component';
import { LabSubjectsComponent } from '../blood-lab/component/lab-subjects/lab-subjects.component';
import { LabTestResultsComponent } from '../blood-lab/component/lab-test-results/lab-test-results.component';
import { OrderDetailsComponent, OrderHistoryMyOrdersComponent } from '../order-history/component';
import { InvoicePreferencesComponent } from '../preferences/component/invoice-preferences/invoice-preferences.component';
import { ShippingAddressListComponent } from '../shipping/component/shipping-address-list/shipping-address-list.component';
import { AccountSettingsComponent } from './component/account-settings/account-settings.component';
import { ContactPreferencesComponent } from './component/contact-preferences/contact-preferences.component';
import { FavoritesComponent } from './component/favorites/favorites.component';
import { MyAccountComponent } from './component/my-account/my-account.component';
import { OfficerInfoComponent } from './component/officer-info/officer-info.component';
import { PaymentManagementComponent } from './component/payment-management/payment-management.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: MyAccountComponent, data: { title: 'Account Options' } },
  { path: 'contactpreferences', component: ContactPreferencesComponent, data: { title: 'My Contact Preferences' } },
  { path: 'autoship', component: AutoShipComponent, data: { title: 'My AutoShip and Save' } },
  { path: 'shippingaddresses', component: ShippingAddressListComponent, data: { title: 'Shipping Addresses' } },
  { path: 'officerinfo', component: OfficerInfoComponent },
  { path: 'bloodlab', component: LabSubjectsComponent, data: { title: 'Lab Testing' } },
  { path: 'bloodlab/viewresults/:subjectNumber', component: LabTestResultsComponent },
  { path: 'orderhistory', component: OrderHistoryMyOrdersComponent, data: { title: 'Order History' } },
  { path: 'orderhistory/:orderNumber', component: OrderDetailsComponent },
  { path: 'accountsettings', component: AccountSettingsComponent, data: { title: 'Profile Settings' } },
  { path: 'paymentmanagement', component: PaymentManagementComponent, data: { title: 'Payment Management' } },
  { path: 'favorites', component: FavoritesComponent, data: {title: 'Wish Lists'} },
  { path: 'invoicepreferences', component: InvoicePreferencesComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}

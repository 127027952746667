import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AutoShipModule } from '../auto-ship/auto-ship.module';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { OrderHistoryModule } from '../order-history/order-history.module';
import { PreferencesModule } from '../preferences/preferences.module';
import { ReturnsModule } from '../returns/returns.module';
import { SharedModule } from '../shared/shared.module';
import { PaymentModule } from './../payment/payment.module';
import { AccountRoutingModule } from './account-routing.module';
import { AccountSettingsComponent } from './component/account-settings/account-settings.component';
import { ContactPreferencesComponent } from './component/contact-preferences/contact-preferences.component';
import { CustomerNCOAComponent } from './component/customer-ncoa/customer-ncoa.component';
import { EmailActivationComponent } from './component/email-activation/email-activation.component';
import { FavoritesComponent } from './component/favorites/favorites.component';
import { MyAccountPremierComponent } from './component/my-account-premier/my-account-premier.component';
import { MyAccountStandardComponent } from './component/my-account-standard/my-account-standard.component';
import { MyAccountComponent } from './component/my-account/my-account.component';
import { OfficerInfoComponent } from './component/officer-info/officer-info.component';
import { PaymentManagementEditComponent } from './component/payment-management-edit/payment-management-edit.component';
import { PaymentManagementComponent } from './component/payment-management/payment-management.component';
import { Prop65ListComponent } from './component/prop65-list/prop65-list.component';
import { RegistrationComponent } from './component/registration/registration.component';
import { RenewPremierComponent } from './component/renew-premier/renew-premier.component';
import { SignInSettingsComponent } from './component/sign-in-settings/sign-in-settings.component';
import { WishListItemDeleteConfirmationComponent } from './component/wish-list-item-delete-confirmation/wish-list-item-delete-confirmation.component';
import { WishListItemRenameComponent } from './component/wish-list-item-rename/wish-list-item-rename.component';
import { WishListItemCreateComponent } from './component/wish-list-item/wish-list-item-create.component';

const components = [
  RegistrationComponent,
  MyAccountComponent,
  MyAccountStandardComponent,
  MyAccountPremierComponent,
  OfficerInfoComponent,
  Prop65ListComponent,
  AccountSettingsComponent,
  SignInSettingsComponent,
  CustomerNCOAComponent,
  ContactPreferencesComponent,
  PaymentManagementEditComponent,
  PaymentManagementComponent,
  FavoritesComponent,
  WishListItemCreateComponent,
  WishListItemDeleteConfirmationComponent,
  WishListItemRenameComponent,
  RenewPremierComponent,
  EmailActivationComponent,
];

const modules = [
  CommonModule,
  SharedModule,
  RouterModule,
  AccountRoutingModule,
  CartCommonModule,
  AutoShipModule,
  ReturnsModule,
  OrderHistoryModule,
  CommonsModule,
  PreferencesModule,
  PaymentModule,
];

@NgModule({
  imports: [modules],
  exports: [components],
  declarations: [components],
})
export class AccountModule { }

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { PagerModule } from '@progress/kendo-angular-pager';
import { PDFViewerModule } from "@progress/kendo-angular-pdfviewer";
import { PopupModule } from '@progress/kendo-angular-popup';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { DynamicContentModule } from '../dynamic-content/dynamic-content.module';
import { AddressVerificationComponent } from './component/address-verification/address-verification.component';
import { DialogAlertMessageComponent } from './component/dialog-alert-message/dialog-alert-message.component';
import { EmailVerificationComponent } from './component/email-verification/email-verification.component';
import { MainFlyoutComponent } from './component/main-flyout/main-flyout.component';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { SetChildAttributesDirective } from './directive/set-child-attributes.directive';
import { SetFocusDirective } from './directive/set-focus.directive';
import { FormDateInputComponent, IconLabelComponent, QuantityInputComponent } from './form/component';
import { FocusInputComponent } from './form/component/focus-input/focus-input.component';
import { FormCheckboxComponent } from './form/component/form-checkbox/form-checkbox.component';
import { FormDropdownComponent } from './form/component/form-dropdown/form-dropdown.component';
import { FormMaskedTextBoxComponent } from './form/component/form-masked-text-box/form-masked-text-box.component';
import { FormTextBoxComponent } from './form/component/form-text-box/form-text-box.component';
import { FormValidationMessageComponent } from './form/component/form-validation-message/form-validation-message.component';
import { FormWrapperFormFieldComponent } from './form/component/form-wrapper-form-field/form-wrapper-form-field.component';
import { HorizontalWrapperComponent } from './form/component/horizontal-wrapper/horizontal-wrapper.component';
import { IconCheckboxComponent } from './form/component/icon-checkbox/icon-checkbox.component';
import { formlyModules } from './form/formly';
import { ObservedPipe } from './pipe/observed.pipe';
import { SafeResourcePipe } from './pipe/safe-resource.pipe';
import { TrustHtmlPipe } from './pipe/trust-html.pipe';

const angularModules = [FormsModule, ReactiveFormsModule, HttpClientModule];

const kendoModules = [
  ButtonsModule,
  DateInputsModule,
  GridModule,
  DialogsModule,
  DropDownsModule,
  InputsModule,
  LabelModule,
  ListViewModule,
  NotificationModule,
  ProgressBarModule,
  PopupModule,
  TooltipModule,
  PagerModule,
  LayoutModule,
  OverlayModule,
  PDFViewerModule,
  DynamicContentModule
];

const modules = [LazyLoadImageModule];

const pipes = [TrustHtmlPipe, ObservedPipe, SafeResourcePipe];

const components = [
  EmailVerificationComponent,
  AddressVerificationComponent,
  FormDateInputComponent,
  QuantityInputComponent,
  IconLabelComponent,
  FocusInputComponent,
  HorizontalWrapperComponent,
  FormValidationMessageComponent,
  FormWrapperFormFieldComponent,
  SpinnerComponent,
  IconCheckboxComponent,
  FormDropdownComponent,
  FormTextBoxComponent,
  FormCheckboxComponent,
  DialogAlertMessageComponent,
  MainFlyoutComponent
];

const directives = [SetFocusDirective, SetChildAttributesDirective];

@NgModule({
  declarations: [pipes, components, directives, SafeResourcePipe, FormMaskedTextBoxComponent],
  imports: [CommonModule, angularModules, kendoModules, formlyModules, modules],
  exports: [angularModules, kendoModules, formlyModules, modules, pipes, components, directives],
})
export class SharedModule { }

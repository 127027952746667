<div class="unsubscribe-verify">
  <ng-container *ngIf="!isProspect">
    <p>
      Instead of opting out, you can receive only our best offers (about once a week).
    </p>
    <div class="text-right">
      <button kendoButton look="clear" class="text-info font-weight-bold" (click)="onVerify()">No Thanks</button>
      <button kendoButton class="k-action font-weight-bold" (click)="onCancel()">Receive Fewer Emails</button>
    </div>
  </ng-container>
  <ng-container *ngIf="isProspect">
    <p>
      Are you sure you want to unsubscribe from Life Extension emails?
    </p>
    <div class="text-right">
      <button kendoButton class="k-action font-weight-bold px-4 mr-3" (click)="onVerify()">Yes</button>
      <button kendoButton class="k-action font-weight-bold px-4" (click)="onCancel()">No</button>
    </div>
  </ng-container>
</div>

import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { CartMessageType, ICartMessage } from 'src/app/cart-common/model';
import { PasswordResetModel } from 'src/app/login/model/password-reset.model';
import { AppValidators } from 'src/app/shared/form/form-validations';
import { CartDialogService } from 'src/app/shared/service/cart-dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { EmailResetModel } from '../../model/email-reset.model';
import { ISignInSettings } from '../../model/sign-in-settings.model';
import { AccountService } from '../../service/account.service';
import { SignInSettingsModel } from './../../model/sign-in-settings.model';

@Component({
  selector: 'lef-sign-in-settings',
  templateUrl: './sign-in-settings.component.html',
  styleUrls: ['./sign-in-settings.component.scss'],
})
export class SignInSettingsComponent implements OnInit, OnChanges {
  @Input() isWholesaler: boolean;
  signInSettings: ISignInSettings;
  model: ISignInSettings = new SignInSettingsModel();

  changeEmailForm: UntypedFormGroup = new UntypedFormGroup({});
  changeEmailFormOptions: FormlyFormOptions = {};
  changeEmailFormFields: FormlyFieldConfig[] = [];

  changePasswordForm: UntypedFormGroup = new UntypedFormGroup({});
  changePasswordFormOptions: FormlyFormOptions = {};
  changePasswordFormFields: FormlyFieldConfig[] = [];

  resetPasswordModel: PasswordResetModel;
  resetEmailModel: EmailResetModel;
  resetPasswordModelMessages: ICartMessage[];
  resetEmailModelMessages: ICartMessage[];
  confirmedEmailModelMessages: ICartMessage[];

  isChangedPassword: boolean;
  isChangedEmail: boolean;
  isConfirmedEmail: boolean;

  messagesTypesToHide: CartMessageType[] = ['Success'];

  constructor(
    private readonly accountService: AccountService,
    private readonly cartDialogService: CartDialogService,
    private readonly loadingService: LoadingService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.init();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if(changes.isWholesaler){
      this.enableChangeEmailForm();
    }    
  }

  init() {
    this.getSignInSettings();
  }

  getSignInSettings() {
    this.accountService.getSignInSettings().subscribe((signInSettings: ISignInSettings) => {
      this.model = signInSettings;
      this.signInSettings = this.model;
      this.initForm();
    });
  }
  initForm() {
    this.initChangeEmailForm();
    this.initChangePasswordForm();
  }

  changeEmail() {
    if (!this.changeEmailForm.valid || !this.model || !this.resetEmailModel?.email) {
      return;
    }
    this.model.email = this.resetEmailModel.email;
    this.isChangedPassword = false;
    this.resetEmailModelMessages = [];
    this.confirmedEmailModelMessages = [];
    this.loadingService.show();
    this.accountService.changeEmail(this.model).subscribe(
      (response) => {
        this.model = response;
        this.isChangedEmail = true;
        if (this.model.invalidEmail && this.model.invalidEmail) {
          this.showEmailVerificationDialog();
        } else {
          this.changeEmailFormOptions.resetModel();
          this.resetEmailModelMessages = response.messages;
        }
        this.loadingService.hide();
      },
      () => {
        this.loadingService.hide();
      }
    );
  }

  private showEmailVerificationDialog() {
    this.cartDialogService.showEmailVerificationDialog(this.model.email, (confirm) => {
      if (confirm) {
        this.model.customerConfirmEmail = true;
        this.changeEmail();
      }
    });
  }

  customerConfirmEmail() {
    this.isConfirmedEmail = true;
    this.accountService.customerConfirmEmail(this.model).subscribe(
      (signInSettings) => {
        this.model = signInSettings;
        this.signInSettings = this.model;
        this.confirmedEmailModelMessages = signInSettings.messages;
        this.loadingService.hide();
        setTimeout(() => {
          this.confirmedEmailModelMessages = [];
        }, 4 * 1000);
      },
      () => {
        this.loadingService.hide();
      }
    );
  }

  changePassword() {
    if (!this.changePasswordForm.valid || !this.model || !this.resetPasswordModel?.password) {
      return;
    }
    this.model.password = this.resetPasswordModel.password;
    this.isChangedEmail = false;
    this.resetPasswordModelMessages = [];
    this.confirmedEmailModelMessages = [];
    this.loadingService.show();
    this.accountService.changePassword(this.model).subscribe(
      (response) => {
        this.isChangedPassword = true;
        this.resetPasswordModel = new PasswordResetModel();
        this.changePasswordFormOptions.resetModel();
        this.resetPasswordModelMessages = response.messages;
        this.loadingService.hide();
      },
      () => {
        this.loadingService.hide();
      }
    );
  }

  private initChangeEmailForm() {
    this.resetEmailModel = new EmailResetModel();
    this.changeEmailForm = new UntypedFormGroup({});
    this.changeEmailFormFields = [
      {
        hooks: {
          onInit: () => {
            this.enableChangeEmailForm();
            this.changeDetectorRef.detectChanges();
          },
        },
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-8',
            type: 'kTextBox',
            key: 'email',
            templateOptions: {
              placeholder: 'Email Address*',
              ariaLabelBy: 'updateEmailAddress',
            },
            validators: {
              validation: [Validators.required, Validators.email],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-8',
            type: 'kTextBox',
            key: 'emailConfirmation',
            templateOptions: {
              placeholder: 'Confirm Email Address*',
            },
            validators: {
              validation: [Validators.required, Validators.email, AppValidators.emailMismatch(this.changeEmailForm, 'email')],
            },
          },
        ],
      },
    ];
  }

  formEnable() {
    return {
      onInit: () => {
        this.enableChangeEmailForm();
      },
    };
  }

  private initChangePasswordForm() {
    this.resetPasswordModel = new PasswordResetModel();
    this.changePasswordForm = new UntypedFormGroup({});
    this.changePasswordFormFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-8',
            type: 'kTextBox',
            key: 'password',
            templateOptions: {
              placeholder: 'New Password*',
              type: 'password',
              ariaLabelBy: 'updateYourPassword',
            },
            validators: {
              validation: [
                AppValidators.passwordMinLength,
                AppValidators.passwordStrength,
                AppValidators.passwordMismatchTrigger('passwordConfirmation'),
              ],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-md-8',
            type: 'kTextBox',
            key: 'passwordConfirmation',
            templateOptions: {
              placeholder: 'Confirm New Password*',
              type: 'password',
            },
            validators: {
              validation: [AppValidators.passwordMismatch('password')],
            },
          },
        ],
      },
    ];
  }
  
  private enableChangeEmailForm(): void {
    this.isWholesaler ? this.changeEmailForm.disable() : this.changeEmailForm.enable();
  }
}

<div class="my-account-hdr">Customer Information</div>
<div class="my-account-content bg-light mb-3 px-3 py-2">
  <div class="pt-2">
    <p>
      <strong>Name: </strong>{{account.mailingName ? account.mailingName : account.firstName}}
    </p>
    <p>
      <strong>Email: </strong> {{account.email}}
    </p>
    <p>
      <strong>Customer #: </strong>{{account.customerNumber}}
    </p>
  </div>
  <hr>
  <div *ngIf="account.showReward">
    <div>
      <p class="text-primary font-md">
        <strong>LE Dollars Available: </strong>
        {{account.availableLeRewardDollars | currency :'USD':'symbol':'1.2-2'}}
      </p>
      <div>
        <a [routerLink]="config.webRoutes.accountOrderHistory" target="_self" title="Order History"
           class="text-info"><strong>View LE Dollars Activity</strong></a>
      </div>
    </div>
    <hr>
    <div>
      <p *ngIf="account.customerTypeDescription"><strong>Account Type: </strong>
        {{account.customerTypeDescription}}</p>
      <p><strong>Free Shipping:</strong> No</p>
      <p><strong>LE Dollars Back: </strong>
        {{(account.rewardsMultiplier ? account.rewardsMultiplier : 0)| percent:'1.1-2' }}</p>
    </div>
  </div>
</div>
<div class="premier-cta" *ngIf="account.displayPremierShip">
  <div class="premier-cta-img">
    <img src="../../../../assets/images/premier-icon.svg" alt="" aria-hidden="true" />
  </div>
  <div class="premier-cta-content">
    <h2 class="roboto text-info"><strong>Premier</strong><br /><span class="zilla text-primary">Rewards</span></h2>
    <p>
      Get FREE unlimited shipping <br />& 4% BACK on all purchases
    </p>
    <a href="{{config.environment.contentSite}}/about/premier" target="_blank" class="btn btn-link">Learn
      More
      <i class="lef-chevron-circle-right text-orange ml-2"></i></a>
  </div>
</div>
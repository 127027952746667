<div class="lab-subjects">
  <div [class]="'alert alert-info'" role="alert">
    <div>
      <i [class]="'lef-information-solid'"></i>
      <span>We no longer send Lab Test Results via Mail. An Email will be required to receive lab results.</span>
    </div>
  </div>
  <lef-section-title [title]="'Lab Test Subjects'"></lef-section-title>
  <div [ngClass]="{'text-right':screenSize > screenSizes.SM, 'text-center': screenSize < screenSizes.MD}" class="mb-2">
    <button class="btn btn-link font-weight-bold" (click)="addLabSubject()">+ Add New Lab Subject</button>
  </div>
  <ng-container *ngIf="screenSize < screenSizes.MD">
    <kendo-listview *ngIf="labSubjectList.labSubjects.length > 0; else noResults" [data]="labSubjectList.labSubjects"
                    [containerClass]="'k-d-flex k-flex-col k-flex-nowrap'">
      <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
        <div class="bg-light pb-1 w-100">
          <div class="mx-3 pt-3" [ngClass]="{'border-top': !isFirst}">
            <div><span class="font-weight-bold">Full Name: </span>{{dataItem.name}}</div>
            <div><span class="font-weight-bold">Date of Birth: </span>{{dataItem.dateOfBirth | date: 'MM/dd/yyyy'}}
            </div>
            <div><span class="font-weight-bold">Gender: </span>{{getGenderText(dataItem.gender)}}</div>
            <div><span class="font-weight-bold">Address: </span>{{dataItem.address}} | {{dataItem.postalCode}}</div>
          </div>
          <div class="d-flex align-items-center p-2">
            <ng-container *ngIf="dataItem.isOnline">
              <button kendoButton class="text-info justify-content-end" look="clear"
                      [routerLink]="'/account/bloodlab/viewresults/' + dataItem.subjectNumber">View Results</button>
              <span class="mx-2">|</span>
            </ng-container>
            <button kendoButton look="clear" class="text-info" (click)="showSubjectDialog(dataItem)">Edit</button>
          </div>
        </div>
      </ng-template>
    </kendo-listview>
    <ng-template #noResults>
      <div class="bg-light p-3 w-100 text-center">
        No Lab Test Subjects were found for your account.
      </div>
    </ng-template>
  </ng-container>

  <kendo-grid *ngIf="screenSize > screenSizes.SM" [data]="labSubjectList.labSubjects" (edit)="editLabSubject($event)">
    <kendo-grid-column field="name" title="Full Name"></kendo-grid-column>
    <kendo-grid-column field="dateOfBirth" title="Date of Birth" [width]="125"></kendo-grid-column>
    <kendo-grid-column title="Gender" [width]="120">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{getGenderText(dataItem.gender)}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="Address">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{dataItem.address}} | {{dataItem.postalCode}}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="View Results" [width]="145" [headerClass]="'justify-content-center'"
                               [class]="'text-center'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button *ngIf="dataItem.isOnline && !dataItem.nonAccessible" kendoButton class="text-info font-weight-bold"
                look="clear" [routerLink]="'/account/bloodlab/viewresults/' + dataItem.subjectNumber">View
          Results</button>
        <button *ngIf="dataItem.isOnline && dataItem.nonAccessible" kendoButton class="text-info font-weight-bold"
                disabled="disabled" look="clear">View Results</button>
      </ng-template>
    </kendo-grid-command-column>
    <kendo-grid-command-column title="Actions" [width]="125" [headerClass]="'justify-content-end'"
                               [class]="'text-right'">
      <ng-template kendoGridCellTemplate>
        <button kendoGridEditCommand look="clear" class="text-info font-weight-bold p-0">Edit</button>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
<p class="font-md text-primary">CONFIRMATION OF COMPLIANCE WITH PROPOSITION 65</p>
<div id="MyAccountContainer" class="content">
  <div class="font-sm">
    <p>
      The implementing regulations for the State of California’s “Safe Drinking Water and Toxic Enforcement Act of
      1986”,
      also known as “Proposition 65”, were amended in 2018 to require new “clear and reasonable” warnings to
      California
      consumers
      prior to purchase if a product may cause exposure to any of the chemicals on the Proposition 65 List established
      by the State of California.
      The products declared in the “Life Extension Proposition 65 Product List” may result in exposure to Lead, which
      is
      known to the State of California to cause birth defects or other reproductive harm. Label and online warnings
      are
      required when selling these products to California consumers.
    </p>
    <p>
      <strong>Until Life Extension receives your Confirmation, we cannot ship products on our Proposition 65 Product
        List to you.</strong>
    </p>
    <ul>
      <li>
        <a href="assets/documents/Prop65/Prop65-Email-Letter.pdf" target="_blank"><i class="lef-file-pdf-o"></i>
          Download Prop 65 Notice (PDF)</a>
      </li>
      <li>
        <input id="ShowProp65Button" name="ShowProp65Button" type="button" (click)="showProp65Products()"
               value="View the Life Extension Proposition 65 Product List" class="btn btn-link paddinglt-0 font-sm" />

      </li>
    </ul>
    <p>
      <strong>Once we receive your Confirmation, we can ship you products on the List. The products will be labeled in
        compliance with the California regulations.</strong>
    </p>
    <p><strong>
        For more information about California Proposition 65, refer to
      </strong>
      <a href="{{config.environment.proposition65Url}}" target="_blank">{{config.environment.proposition65Url}}.</a>
    </p>
  </div>
  <hr />
  <div class="row" *ngIf="officer && showEdit==false">
    <div class="col-4">
      <strong> Organization: </strong>
      <span>{{officer.organizationName}}</span>
    </div>
    <div class="col-4">
      <strong>Contact Name:</strong>
      <span>{{officer.officerName}}</span>
    </div>
    <div class="col-4">
      <strong>Agreement Expiration Date:</strong>
      <span>{{officer.agreementExpirationDate}}</span>
    </div>
  </div>
</div>

<div *ngIf="!showEdit" class="mt-3 text-right">
  <button class="btn btn-action" (click)="edit(true)">Modify/Update</button>
</div>
<div *ngIf="showEdit" class="text-right">
  <button class="btn btn-primary" (click)="edit(false)">Cancel</button>
</div>
<div *ngIf="showEdit">
  <p><small class="req">*Indicates required fields.</small></p>
  <form class="k-form" [formGroup]="form" (ngSubmit)="saveOfficer()">
    <formly-form [model]="model" [fields]="formFields" [form]="form"></formly-form>
    <div class="messages">
      <div *ngFor="let message of model.messages">
        <p class="text-red">{{message.message}}</p>
      </div>
    </div>
    <div class="text-right">
      <button type="submit" class="btn btn btn-warning">Confirm</button>
    </div>
  </form>
</div>
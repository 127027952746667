import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@progress/kendo-angular-dialog';
import { IRegistration } from 'src/app/account/model';
import { AuthorizationService } from 'src/app/authorization';
import { ResponsiveService } from 'src/app/common/service';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { AppValidators, ValidationMessages } from 'src/app/shared/form/form-validations';
import { IActionToken } from 'src/app/shared/model/action-token';
import { IListItem } from 'src/app/shared/model/list-item';
import { ActionService } from 'src/app/shared/service/action.service';
import { CartCookieService } from 'src/app/shared/service/cart-cookie-service.service';
import { CartDialogService } from 'src/app/shared/service/cart-dialog.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { CaptureEmailModel } from 'src/app/tracking/model/capture-email.model';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { ILoginMessage, LoginMessageAction } from '../../model/login-message.model';
import { LoginModel } from '../../model/login-model.model';
import { AccountVerificationModel } from '../../model/verification-code.model';
import { LoginService } from '../../service/login.service';

@Component({
  selector: 'lef-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() isExternalLogin = false;
  @Input() showWelcomeMessage = false;
  @Input() returnUrl: string = '';
  @Input() recoveryId: string = '';
  @Input() isResetSubject: boolean = false;

  @Output() closeDialog: EventEmitter<void> = new EventEmitter();

  isCheckoutFlow = false;
  isResellerFlow: boolean = false;
  label = 'Email:';
  placeHolder = 'Email Address';
  welcomeMessage = 'Sign-in or Register for a Life Extension website account';
  forgotPasswordLabel = 'Forgot my password';
  loginModel = new LoginModel();
  countries: IListItem[] = [];
  showFormErrorMessage = false;
  errorMessage = '';
  showEmailForm = false;
  emailForm: UntypedFormGroup;
  showPasswordForm = false;
  passwordForm: UntypedFormGroup;
  showNewPasswordForm = false;
  newPasswordForm: UntypedFormGroup;
  showZipVerificationForm = false;
  zipVerificationForm: UntypedFormGroup;
  showCountryVerificationForm = false;
  countryVerificationForm: UntypedFormGroup;
  prompt: string;
  showLoginPassword: boolean = false;
  showRegistrationPassword: boolean = false;
  showConfirmPassword: boolean = false;
  submitEnabled: boolean = true;
  showLoginComponent: boolean = true;
  showForgotPasswordComponent: boolean = false;
  showResetPasswordComponent: boolean = false;
  showVerifyAccountCodeComponent: boolean = false;
  showRegistrationComponent: boolean = false;
  verificationModel: AccountVerificationModel;
  registrationModel: IRegistration
  isGuestCheckoutFlow: boolean = false;
  showContinueAsGuestButton = false;
  isMatchLogin: boolean = false;

  get showResetOption(): boolean {
    if (!this.loginModel) {
      return false;
    }

    const hasEmail = !!this.loginModel.email;
    const isFullLogin = this.loginModel.loginStatus === 'FullLogin';
    const isValidGuestCheckout =
      this.loginModel.isGuestCheckout && (!this.loginModel.isInvalidGuestEmail || this.loginModel.guestEmailConfirmed);

    return hasEmail && !isFullLogin && !isValidGuestCheckout;
  }

  get title(): string {
    return this.loginModel.isGuestCheckout ? 'Guest' : '';
  }

  get loginTitle(): string {
    if (this.showResetPasswordComponent) {
      return 'Password Assistance';
    }

    if (this.showVerifyAccountCodeComponent) {
      return 'Verify Account';
    }

    if (this.showForgotPasswordComponent) {
      return 'Forgot your password?';
    }

    if (this.showPasswordForm || this.showZipVerificationForm) {
      return 'Sign In';
    }

    if (this.showNewPasswordForm) {
      return 'Create an Account';
    }

    if (this.isGuestCheckoutFlow) {
      return 'Guest';
    }

    return '';
  }

  ValidationMessages = ValidationMessages;

  private loginActionToken: IActionToken;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly loginService: LoginService,
    private readonly dialogService: DialogService,
    private readonly cartDialogService: CartDialogService,
    private readonly trackingService: TrackingService,
    private readonly authorizationService: AuthorizationService,
    private readonly loadingService: LoadingService,
    private readonly responsiveService: ResponsiveService,
    private readonly commonService: CommonService,
    private readonly route: ActivatedRoute,
    private readonly actionService: ActionService,
    private readonly router: Router,
    private readonly cookieService: CartCookieService,
    private readonly config: CoreConfiguration
  ) { }

  toggleLoginPasswordVisibility() {
    this.showLoginPassword = !this.showLoginPassword;
  }

  toggleRegistrationPasswordVisibility() {
    this.showRegistrationPassword = !this.showRegistrationPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  ngOnInit() {

    this.loginService.isLoginComponentOpen = true;

    this.generateForms();

    if (this.returnUrl === '/checkout' || this.returnUrl === 'checkout') {
      this.isCheckoutFlow = true;
    }

    if (this.returnUrl === '/reseller') {
      this.isResellerFlow = true;
    }

    if (!this.recoveryId) {
      this.getCurrentUser();
      this.getLoginToken();
    }

    this.responsiveService.checkCurrentSize();
  }

  ngAfterViewInit() {
    if (this.recoveryId) {
      setTimeout(() => {
        this.showResetPasswordDialog(this.recoveryId);
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.loginService.isLoginComponentOpen = false;
  }

  submitEmailForm() {
    this.emailForm.controls.email.setValue(this.emailForm.value.email.trim());
    this.isResetSubject = false;

    if (!this.emailForm.valid) {
      this.showFormErrorMessage = true;
      if (this.emailForm.controls.email.errors?.email) {
        this.errorMessage = 'Invalid e-mail address.';
      }

      return;
    }

    this.loadingService.show();
    const model = { ...this.loginModel };
    model.email = this.emailForm.value.email;
    model.isCheckoutFlow = this.isCheckoutFlow;
    this.submitEnabled = false;

    this.loginService.validateEmail(model).subscribe(
      (result) => {
        this.processLoginResponse(result);
        this.submitEnabled = true;
        if (!this.isGuestCheckoutFlow || result?.isExistingCustomer) {
          this.loadingService.hide();
        }
      },
      (error) => {
        this.submitEnabled = true;
        this.loadingService.hide();
      });
  }

  submitZipForm() {
    if (!this.zipVerificationForm.valid) {
      return;
    }

    this.loginModel.billingZipCode = this.zipVerificationForm.value.zipCode;
    this.login();
  }

  submitCountryForm() {
    if (!this.countryVerificationForm.valid) {
      return;
    }

    this.loginModel.billingCountry = this.countryVerificationForm.value.country;
    this.login();
  }

  submitLoginForm() {
    if (!this.passwordForm.valid) {
      return;
    }

    this.loginModel.password = this.passwordForm.value.password;
    this.login();
  }

  submitNewPasswordForm() {
    if (!this.newPasswordForm.valid) {
      return;
    }
    this.loginService.isSignUp.next(true);

    this.loginModel.password = this.newPasswordForm.value.password;
    if (this.isCheckoutFlow) {
      this.sendCaptureEmail('Checkout');
      if (this.loginModel.isVerifiedCustomer) {
        this.login();
      } else {
        this.guestCheckOut();
      }
    } else {
      this.sendCaptureEmail('direct signup');
      this.startRegistrationProcess();
    }
  }

  resetForms() {
    this.processLoginResponse(new LoginModel());
    this.onLoginUpdate(this.loginModel);
  }

  setCurrentComponent(componentToShow: string): void {
    this.showForgotPasswordComponent = false;
    this.showLoginComponent = false;
    this.showResetPasswordComponent = false;
    this.showVerifyAccountCodeComponent = false;
    this.showRegistrationComponent = false;

    this[componentToShow] = true;
  }

  onCloseFlyout(): void {
    if (this.isExternalLogin) {
      this.sendLoginMessageToParentSite(false, 'Close', '');
      return;
    }

    this.router.navigateByUrl(this.config.webRoutes.root);
    this.closeDialog.emit();
  }

  onMarketingCodeRemoved(marketingCode: string): void {
    const dialogRef = this.dialogService.open({
      title: 'Invalid Discount Code',
      width: 700,
      maxHeight: '90%',
      maxWidth: '90%',
      content: `Sorry, this code isn't currently valid. We removed it from your cart.`,
    });

    const componentRef = dialogRef?.content?.instance;
    if (componentRef != null) {
      componentRef.closeDialog.subscribe(() => {
        dialogRef.close();
      });
    }
  }

  changeCredentials() {
    this.loginService.valid.next(false);
  }

  signUp(): void {
    this.isGuestCheckoutFlow = false;
    this.submitEmailForm();
  }

  guestCheckOut() {
    this.loadingService.show('Please Wait...');
    this.loginService.guestCheckOut(this.loginModel).subscribe({
      next: (result) => {
        if (this.loginModel?.isProspect === false && result?.isProspect === true) this.trackingService.generateLead.next();
        this.processLoginResponse(result);
        this.submitEnabled = true;
        this.isGuestCheckoutFlow = false;
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  showForgotPasswordDialog() {
    this.showLoginPassword = false;
    this.setCurrentComponent('showForgotPasswordComponent');
  }

  processAccountVerified(verificationModel: AccountVerificationModel): void {
    if (verificationModel.isValid) {
      this.loginModel.customerNumber = verificationModel.customerNumber;
      this.loginModel.isVerifiedCustomer = verificationModel.isValid;
      this.loginModel.allowContinueAsGuest = !verificationModel.isValid;
      this.loginModel.promptForAccountVerification = false;
      this.processLoginResponse(this.loginModel);
    }
  }

  private guestCheckOutConfirmEmail() {
    this.loginService.guestCheckOutConfirmEmail(this.loginModel).subscribe((result) => {
      this.processLoginResponse(result);
    });
  }

  private login() {
    this.errorMessage = '';

    this.loginModel.isCheckoutFlow = this.isCheckoutFlow;
    this.loadingService.show('Logging In...');
    this.submitEnabled = false;
    if (!this.loginActionToken.token || this.loginActionToken.expiration < Date.parse(new Date().toString())) {
      this.getLoginToken(true);
    } else {
      this.doLogin();
    }
  }

  private getLoginToken(doLogin: boolean = false) {
    this.actionService.GetActionToken('LoginUser').subscribe({
      next: (result) => {
        this.loginActionToken = result;
        if (doLogin) {
          this.doLogin();
        }
      },
      error: (error) => {
        this.loadingService.hide();
        this.submitEnabled = true;
      },
    });
  }

  private doLogin() {
    this.actionService.SetCurrentIntent(this.loginActionToken.token);
    this.loginService.login(this.loginModel).subscribe({
      next: (result) => {
        this.processLoginResponse(result);
        this.loadingService.hide();
        this.submitEnabled = true;
      },
      error: (error) => {
        if (error?.content?.errorMessage) {
          this.showFormErrorMessage = true;
          this.errorMessage = error.content.errorMessage;
        }
        this.loadingService.hide();
        this.submitEnabled = true;
      },
    });
  }

  private showEmailVerificationDialog() {
    this.cartDialogService.showEmailVerificationDialog(this.loginModel.email, (confirm) => {
      if (confirm) {
        this.loginModel.guestEmailConfirmed = true;
        this.guestCheckOutConfirmEmail();
      } else {
        this.resetForms();
      }
    });
  }

  private startRegistrationProcess() {
    this.registrationModel = {
      email: this.loginModel.email,
      customerNumber: this.loginModel.customerNumber,
      password: this.loginModel.password,
    };

    this.setCurrentComponent('showRegistrationComponent');
  }

  private showAccountVerificationDialog() {
    this.verificationModel = new AccountVerificationModel();
    this.verificationModel.email = this.loginModel.email;

    this.setCurrentComponent('showVerifyAccountCodeComponent');
  }

  private showResetPasswordDialog(recoveryId: string) {
    this.setCurrentComponent('showResetPasswordComponent');
  }

  private generateForms(): void {
    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, AppValidators.notNumber]],
    });

    this.passwordForm = this.formBuilder.group({
      password: ['', [Validators.required]],
    });

    this.newPasswordForm = this.formBuilder.group({
      password: [
        '',
        [
          Validators.required,
          AppValidators.passwordMinLength,
          AppValidators.passwordStrength,
          AppValidators.passwordMismatchTrigger('passwordConfirmation'),
        ],
      ],
      passwordConfirmation: ['', [Validators.required, AppValidators.passwordMismatch('password')]],
    });

    this.zipVerificationForm = this.formBuilder.group({
      zipCode: ['', [Validators.required]],
    });

    this.countryVerificationForm = this.formBuilder.group({
      country: ['', [Validators.required]],
    });
  }

  getCurrentUser(afterRegistration = false) {
    this.loginService.getCurrentUser(this.isCheckoutFlow, this.isResetSubject).subscribe((response) => {
      this.processLoginResponse(response, afterRegistration);
    });
  }

  private processLoginResponse(response: LoginModel, afterRegistration = false) {
    if (!response) {
      return;
    }

    this.setPrompt();

    if (this.loginModel.loginStatus !== response.loginStatus) {
      this.authorizationService.authorizationChange.next(null);
    }

    this.loginModel = response;
    this.showFormErrorMessage = false;
    this.errorMessage = '';

    // Process Error Messages
    if (response.errorMessage) {
      this.showFormErrorMessage = true;
      this.errorMessage = response.errorMessage;
    }

    // Send Object to Parent
    this.onLoginUpdate(this.loginModel);

    if (afterRegistration) {
      this.trackingService.customerRegistration.next(this.loginModel.customerNumber);
      this.trackingService.signUp.next(this.prompt);
    }

    // Ask for Email
    if (!this.loginModel || !this.loginModel.email || !this.loginModel.isValidEmail) {
      this.showLoginPassword = false;
      this.showRegistrationPassword = false;
      this.showConfirmPassword = false;
      this.toggleForm('Email');
      return;
    }

    // Ask for Zip Code
    if (this.loginModel.promptForZip) {
      this.toggleForm('Zip');
      return;
    }

    // Ask for Country
    if (this.loginModel.promptForCountry) {
      this.toggleForm('Country');
      return;
    }

    // Ask for Account Verification
    if (this.loginModel.promptForAccountVerification) {
      this.toggleForm('Email');
      this.showAccountVerificationDialog();
      return;
    }

    if (this.loginModel.marketingCodeRemoved) {
      this.onMarketingCodeRemoved('');
    }

    // User is Logged In
    if (this.loginModel.loginStatus === 'FullLogin') {
      if (!afterRegistration && this.loginModel?.success && this.loginModel?.isExistingCustomerWithWebAccount)
        this.trackingService.logIn.next(this.prompt);
      this.toggleForm('');
      return;
    }

    if (this.loginModel?.isExistingCustomer && !this.loginModel?.isExistingCustomerWithWebAccount) {
      this.isMatchLogin = true;
      this.isGuestCheckoutFlow = false;
    }

    // Guest Checkout Ready to Go
    if (
      this.isCheckoutFlow &&
      this.loginModel.isGuestCheckout &&
      (!this.loginModel.isInvalidGuestEmail || this.loginModel.guestEmailConfirmed) &&
      !this.isMatchLogin
    ) {
      this.toggleForm('');

      return;
    }

    // Ask for Password
    if (this.loginModel.isExistingCustomerWithWebAccount) {
      this.toggleForm('Password');
      return;
    }

    // Ask for Guest Email Verification
    if (this.loginModel.isInvalidGuestEmail) {
      this.showEmailVerificationDialog();
      return;
    }

    // Ask New Password and Register
    if (this.loginModel.isValidEmail) {
      if (this.isGuestCheckoutFlow) {
        this.guestCheckOut();
        return;
      }

      this.toggleForm('NewPassword');
      return;
    }
  }

  private toggleForm(form: '' | 'Email' | 'Password' | 'NewPassword' | 'Zip' | 'Country') {
    if (form === 'Email') {
      this.showEmailForm = true;
    } else {
      this.showEmailForm = false;
      this.emailForm.reset();
    }

    if (form === 'Password') {
      this.showPasswordForm = true;
    } else {
      this.showPasswordForm = false;
      this.passwordForm.reset();
    }

    if (form === 'NewPassword') {
      this.showNewPasswordForm = true;
    } else {
      this.showNewPasswordForm = false;
      this.newPasswordForm.reset();
    }

    if (form === 'Zip') {
      this.showZipVerificationForm = true;
    } else {
      this.showZipVerificationForm = false;
      this.zipVerificationForm.reset();
    }

    if (form === 'Country') {
      this.commonService.getCountryList().subscribe((countries: IListItem[]) => {
        this.countries = countries;
        this.showCountryVerificationForm = true;
      });
    } else {
      this.showCountryVerificationForm = false;
      this.countryVerificationForm.reset();
    }
  }

  private sendCaptureEmail(vehicle: string): void {
    let captureEmail = new CaptureEmailModel();

    captureEmail.vehicle = vehicle;

    captureEmail.status = 'New';
    if (this.loginModel.isExistingCustomer) {
      captureEmail.status = 'Customer';
    } else if (this.loginModel.isProspect) {
      captureEmail.status = 'Prospect';
    }

    this.trackingService.captureEmail.next(captureEmail);
  }

  private setPrompt(): void {
    this.prompt = 'direct';
    if (this.route.snapshot.queryParamMap.get('returnUrl')) this.prompt = 'required';
    if (this.isCheckoutFlow && this.prompt === 'direct') this.prompt = 'checkout';
  }

  private onLoginUpdate(model: LoginModel) {
    const isFullLogin = model.loginStatus === 'FullLogin';

    if (this.isCheckoutFlow) {
      const allowContinueAsGuest = model.allowContinueAsGuest;
      const isValidGuestCheckout = model.isGuestCheckout && (!model.isInvalidGuestEmail || model.guestEmailConfirmed);
      const isValid = isFullLogin || isValidGuestCheckout;

      this.showContinueAsGuestButton = !isValid && allowContinueAsGuest;
      this.loginService.loginUpdate.next(model);
      this.loginService.valid.next(isValid);
    }

    if ((isFullLogin || model.isGuestCheckout) && !this.isResetSubject) {
      this.closeDialog.emit();

      if (this.returnUrl && this.returnUrl.toLowerCase().indexOf('https') === 0) {
        if (this.isExternalLogin) {
          this.sendLoginMessageToParentSite(true, 'Redirect', this.returnUrl);
        } else {
          window.location.href = this.returnUrl;
        }
        return;
      }

      if (this.returnUrl) {
        if (this.isMatchLogin && !this.isCheckoutFlow && !this.isResellerFlow) {
          this.isMatchLogin = false;
          this.returnUrl += '/accountsettings';
        }

        if (this.isCheckoutFlow) {
          if (this.isExternalLogin) {
            this.sendLoginMessageToParentSite(true, 'Redirect', `${this.config.environment.cartSite}${this.config.webRoutes.checkout}`);
          } else {
            this.router.navigateByUrl(this.returnUrl, { state: { isValidCheckout: true } });
          }
          return;
        }

        if (this.isExternalLogin) {
          this.sendLoginMessageToParentSite(true, 'Redirect', `${this.config.environment.cartSite}${this.returnUrl}`);
        } else {
          this.router.navigateByUrl(this.returnUrl);
        }
        return;
      }

      if (!this.cookieService.getItemsInCartCount() &&
        !this.authorizationService.isAnonymousCustomer()) {
        if (this.authorizationService.isWholesaler()) {
          this.returnUrl = this.config.webRoutes.myLifeExtensionWholesale;
        } else {
          this.returnUrl = this.config.webRoutes.myLifeExtension;
        }

        if (this.isExternalLogin) {
          this.sendLoginMessageToParentSite(true, 'Redirect', this.returnUrl);
        } else {
          window.location.href = this.returnUrl;
        }
        return;
      }
      else {
        if (this.isExternalLogin) {
          this.sendLoginMessageToParentSite(true, 'Redirect', `${this.config.environment.cartSite}${this.config.webRoutes.root}`);
        } else {
          this.router.navigateByUrl(this.config.webRoutes.root);
        }
        return;
      }
    }
  }

  private sendLoginMessageToParentSite(success: boolean, action: LoginMessageAction, returnUrl: string) {
    var message: ILoginMessage = { success, action, returnUrl };
    window.parent.postMessage(JSON.stringify(message), '*');
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { IContactPreferences, IUnsubscribeSurvey } from '../model';
import { SmsContactPreferences } from '../model/sms-contact-preferences';

@Injectable({
  providedIn: 'root',
})
export class ContactPreferencesService extends BaseService<IContactPreferences> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.contactPreferences;
  }

  getContactPreferences(): Observable<IContactPreferences> {
    return this.getSingle(`${this.apiUrl}/GetContactPreferences`);
  }

  getPreferencesFromEmail(email: string): Observable<IContactPreferences> {
    return this.getSingle(`${this.apiUrl}/GetPreferencesFromEmail?email=${email}`);
  }

  getUnsubscribePreferences(): Observable<IContactPreferences> {
    return this.getSingle(`${this.apiUrl}/GetUnsubscribePreferences`);
  }

  createContactPreferences(contactPreferences: IContactPreferences): Observable<IContactPreferences> {
    return this.add(`${this.apiUrl}/CreateContactPreferences`, contactPreferences);
  }

  updateContactPreferences(contactPreferences: IContactPreferences): Observable<IContactPreferences> {
    return this.update(`${this.apiUrl}/UpdateContactPreferences`, contactPreferences);
  }

  updateProspectPreferences(contactPreferences: IContactPreferences): Observable<IContactPreferences> {
    return this.update(`${this.apiUrl}/UpdateProspectPreferences`, contactPreferences);
  }

  insertUnsubscribeSurvey(unsubscribeSurvey: IUnsubscribeSurvey): Observable<void> {
    return this.httpPost<void>(`${this.apiUrl}/InsertUnsubscribeSurvey`, unsubscribeSurvey);
  }

  updateSmsContactPreferences(smsContactPreferences: SmsContactPreferences): Observable<IContactPreferences> {    
    return this.update(`${this.apiUrl}/UpdateSmsContactPreferences`, smsContactPreferences);
  }
}

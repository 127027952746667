import { LoginStatus } from './login-status.type';

export interface ILogin {
  email?: string;
  lastName?: string;
  customerNumber?: number;
  customerType?: string;
  password?: string;

  billingZipCode?: string;
  billingCountry?: string;
  promptForCountry?: boolean;
  promptForZip?: boolean;
  promptForAccountVerification?: boolean;
  promptForEmailConfirmation?: boolean;

  loginStatus?: LoginStatus;
  isValidEmail?: boolean;
  isInvalidGuestEmail?: boolean;

  isExistingCustomer?: boolean;
  isExistingCustomerWithWebAccount?: boolean;
  isInternationalCustomer?: boolean;
  isVerifiedCustomer?: boolean;
  isGuestCheckout?: boolean;
  isCheckoutFlow?: boolean;

  guestEmailConfirmed?: boolean;
  neverBounceReturnCode?: number;
  recoveryId?: string;
  sessionId?: string;

  success?: boolean;
  errorMessage?: string;
  marketingCodeRemoved?: boolean;
  isProspect?: boolean;
}

export class LoginModel implements ILogin {
  email?: string;
  lastName?: string;
  customerNumber?: number;
  customerType?: string;
  password?: string;

  billingZipCode?: string;
  billingCountry?: string;
  promptForCountry?: boolean;
  promptForZip?: boolean;
  promptForAccountVerification?: boolean;
  promptForEmailConfirmation?: boolean;

  loginStatus?: LoginStatus;
  isValidEmail?: boolean;
  isInvalidGuestEmail?: boolean;

  isExistingCustomer?: boolean;
  isExistingCustomerWithWebAccount?: boolean;
  isInternationalCustomer?: boolean;
  isVerifiedCustomer?: boolean;
  isGuestCheckout?: boolean;
  isCheckoutFlow?: boolean;
  guestEmailConfirmed?: boolean;
  allowContinueAsGuest?: boolean;

  neverBounceReturnCode?: number;
  recoveryId?: string;
  sessionId?: string;

  success?: boolean;
  errorMessage?: string;
  marketingCodeRemoved?: boolean;
  isProspect?: boolean;

  constructor() {
    this.email = '';
    this.lastName = '';
    this.password = '';
    this.loginStatus = '';

    this.recoveryId = null;
    this.sessionId = null;

    this.success = false;
    this.errorMessage = '';

    this.billingZipCode = '';

    this.allowContinueAsGuest = true;
  }
}

import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { DynamicComponent, DynamicContentModel, INotification } from '../../model/dynamic-content.model';

@Component({
  selector: 'le-dynamic-content',
  templateUrl: './dynamic-content.component.html',
  styleUrls: ['./dynamic-content.component.scss'],
})
export class DynamicContentComponent implements OnChanges, OnDestroy {
  @Input() dynamicContent: DynamicContentModel;
  @Input() postponeInitialization = false;
  @Input() loadingMessage = 'Loading...';

  @Output()
  readonly notification: EventEmitter<INotification> = new EventEmitter<INotification>();
  @Output()
  readonly clearNotification: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  readonly changeEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  readonly initialized: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('componentViewContainer', { read: ViewContainerRef, static: true })
  componentViewContainerRef: ViewContainerRef;

  componentRef: ComponentRef<any>;
  componentName: string;
  get componentInitialized(): boolean {
    return this.isComponentInitialized;
  }

  get result(): any {
    if (this.componentRef && this.componentRef.instance && this.componentRef.instance instanceof DynamicComponent) {
      return (this.componentRef.instance as DynamicComponent).value;
    }
    return true;
  }

  private isComponentInitialized: boolean;

  constructor(private readonly componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnChanges(changes: SimpleChanges): void {
    const dynamicContentChange = (changes as Pick<SimpleChanges, 'dynamicContent'>).dynamicContent;

    if (dynamicContentChange) {
      this.onDynamicContentChange();
    }
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
      this.componentViewContainerRef.clear();
    }
  }

  /**
   * Call when postponeInitialization is set to handle lazy creation.
   */
  initializeComponent(): void {
    if (this.isComponentInitialized) {
      return;
    }

    this.postponeInitialization = false;
    this.onDynamicContentChange();
  }

  private onDynamicContentChange(): void {
    // If this will be a lazy initialization, we wait.
    if (this.postponeInitialization) {
      return;
    }

    // Remove existing reference for a clean rebuild.
    this.componentRef = null;
    this.componentName = this.dynamicContent.component.name;

    this.componentViewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dynamicContent.component);
    this.componentRef = this.componentViewContainerRef.createComponent(componentFactory);

    if (this.dynamicContent.inputs) {
      Object.keys(this.dynamicContent.inputs)
        .filter((input) => this.componentRef.instance.hasOwnProperty(input))
        .forEach((input) => (this.componentRef.instance[input] = this.dynamicContent.inputs[input]));
    }

    if (this.componentRef.instance instanceof DynamicComponent) {
      const dynamicComponent = this.componentRef.instance as DynamicComponent;

      dynamicComponent.notification.subscribe({
        next: (notification: INotification) => {
          this.notification.emit(notification);
        },
      });

      dynamicComponent.clearNotification.subscribe({
        next: () => {
          this.clearNotification.emit({});
        },
      });

      dynamicComponent.dataChanged.subscribe({
        next: (value: any) => {
          this.changeEvent.emit(value);
        },
      });

      this.dynamicContent.refreshEvent.subscribe({
        next: (value: any) => {
          dynamicComponent.refresh(value);
        },
      });
    }

    if (this.dynamicContent.outputs) {
      const outputs = this.dynamicContent.outputs;
      Object.keys(this.dynamicContent.outputs)
        .filter((output) => this.componentRef.instance[output])
        .forEach((p) =>
          this.componentRef.instance[p].subscribe({
            next: this.dynamicContent.outputs[p],
          })
        );
    }

    this.isComponentInitialized = true;
    this.initialized.emit(this.componentRef.instance);
  }
}
export class InvoicePreferences {
  customerNumber: number;
  isInvoiceNeeded?: boolean;
  isMail: boolean;
  isEmail: boolean;
  emailToAdd: string;
  emailList: Array<string> = [];
  defaultEmailAddress?: string;

  constructor() {
    this.customerNumber = 0;
    this.isInvoiceNeeded = false;
    this.isMail = false;
    this.isEmail = false;
    this.emailToAdd = '';
    this.emailList = [];
    this.defaultEmailAddress = '';  
  }
}
<kendo-popup *ngIf="flyoutExpanded" class="mainFlyout" [animate]="animate" [anchorAlign]="anchorAlign"
             [popupAlign]="popupAlign" (open)="onFlyoutOpen()">
    <div class="header" *ngIf="showHeader">
        <div class="container">
            <div class="row">
                <div class="col">
                    <h2 class="text-grey">{{title}}</h2>
                </div>
                <div class="col-auto" *ngIf="showHeaderButton && headerButtonClass">
                    <button kendoButton [class]="headerButtonClass" (click)="onHeaderButtonClick()">
                        <i [class]="headerButtonIconClass"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <le-dynamic-content [dynamicContent]="flyoutContent">
        </le-dynamic-content>

        <div class="p-3" *ngIf="!flyoutContent && flyoutHtmlContent" [innerHTML]="flyoutHtmlContent| trustHtml"></div>
    </div>
    <div *ngIf="showEditFooter" class="footer">
        <button kendoButton class="k-secondary" (click)="onCancelClick()" [disabled]="disabled">{{ cancelButtonText
            }}</button>
        <button kendoButton [primary]="true" (click)="onSubmitClick()" [disabled]="disabled">{{ submitButtonText
            }}</button>
    </div>

    <button *ngIf="!hideNotch" kendoButton class="k-button-info popup-btn" [icon]="'arrow-chevron-right'"
            (click)="onCancelClick()"></button>
</kendo-popup>

<div id="overlayFlyout" class="overlay-flyout" *ngIf="flyoutExpanded" (click)="onCancelClick()"></div>
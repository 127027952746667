<lef-section-title [title]="'Account Options'"></lef-section-title>
<lef-cart-messages *ngIf="account" [messages]="account.messages"></lef-cart-messages>

<div class="row mb-3" *ngIf="account && resellerApplicationStatus">
  <div class="col-12">
    <div class="my-account-hdr">
      Application Status: <strong>{{resellerApplicationStatus.status}}</strong>
    </div>
    <div class="bg-lightblue p-3">
      <div [innerHTML]="resellerApplicationStatus.message|trustHtml"></div>
      <div *ngIf="resellerApplicationAllowRestart" class="">
        <button class="btn btn-warning" type="button" (click)="restartResellerApplication()">Reapply</button>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="account">
  <div class="col-lg-4">
    <div>
      <div *ngIf="!account.isWholesaler && !account.isMember">
        <lef-my-account-standard [account]="account"></lef-my-account-standard>
      </div>
      <div *ngIf="account.isMember">
        <lef-my-account-premier [account]="account" (updateMessage)="updateMessage($event)"></lef-my-account-premier>
      </div>
      <div *ngIf="account.isWholesaler && !account.isMember">
        <div class="my-account-hdr">
          Customer Information
        </div>
        <div class="bg-light p-3">
          <p><strong>Customer #: </strong>{{account.customerNumber}}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-8">
    <div class="my-account-content bg-light mb-4">
      <div class="my-account-hdr">
        My Account Options
      </div>
      <div class="p-3">
        <div class="row">
          <div class="col-md-6 pr-md-5 account-options-wrap border-md-right">
            <ul *ngFor='let accountOption of accountOptionsLeft' class="account-options">
              <li class="account-opts-title" *ngIf="accountOption.display">
                <a [routerLink]="accountOption.url">
                  <i [class]="accountOption.icon"></i>{{accountOption.title}}
                </a>
              </li>
              <li *ngIf="accountOption.display">
                {{accountOption.description}}
              </li>
            </ul>
          </div>
          <div class="col-md-6 pl-md-5 account-options-wrap">
            <ul *ngFor='let accountOption of accountOptionsRight' class="account-options">
              <li class="account-opts-title" *ngIf="accountOption.display">
                <a [routerLink]="accountOption.url" [queryParams]="accountOption.queryParams">
                  <i [class]="accountOption.icon"></i>{{accountOption.title}}
                </a>
              </li>
              <li *ngIf="accountOption.display">
                {{accountOption.description}}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="account">
      <p class="px-2 px-lg-0">
        <strong>Do you need assistance?</strong><br />
        If you need help with your account, or would like to contact us in regards to a recently placed order, please
        <button class="btn btn-link" (click)="sendFeedBack()">contact our customer service team</button>
        or call toll-free at
        <a href="tel:{{config.environment.customerServicePhone}}" class="text-dark">
          <strong>
            {{config.environment.customerServicePhone}}.
          </strong>
        </a>
      </p>
    </div>
  </div>
</div>
<div class="fh-hide" *ngIf="account && account.isWholesaler">
  <hr class="mobile-hide" />
  <h2> Wholesale Policies and Notices</h2>
  <div class="row" id="Wholesaler">
    <div class="col-md-6">
      <ul class="mobile-my-account">
        <li class="mb-3">
          <a href="{{config.environment.contentSite}}/Vitamins-Supplements/LE-Wholesale-Advertising-Reprint-Policy.pdf"
             target="_blank" class="text-info">
            <i class="lef-file-pdf-o mr-1"></i>
            <strong>Advertising and Reprint Policies</strong>
          </a>
          <p>Review Life Extension's policies for advertising</p>
        </li>
        <li class="mb-3">
          <a href="{{config.environment.contentSite}}/Vitamins-Supplements/LEWholesaleReturnPolicy.pdf" target="_blank"
             class="text-info">
            <i class="lef-file-pdf-o mr-1"></i>
            <strong>Wholesale Return Policy</strong>
          </a>
          <p>Review Life Extension's Wholesale Return Policy</p>
        </li>
        <li>
          <a href="{{config.environment.contentSite}}/wholesale/wholesale-marketing-assets" class="text-info">
            <i class="k-icon k-font-icon k-i-lock mr-1"></i>
            <strong>Wholesale Marketing Assets</strong>
          </a>
          <p>Detailed instruction for accessing Life Extension's asset management platform</p>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul class="the-icons account-icons mobile-my-account">
        <li class="mb-3">
          <a href="{{config.environment.contentSite}}/Vitamins-Supplements/LE-Wholesale-MAP-Policy.pdf" target="_blank"
             class="text-info">
            <i class="lef-file-pdf-o mr-1"></i>
            <strong>MAP Policy</strong>
          </a>
          <p>Review Life Extension's MAP Policy</p>
        </li>
        <li class="mb-3">
          <a href="{{config.environment.contentSite}}/Legal/BrandedTerms.htm" class="text-info">
            <i class="k-icon k-font-icon k-i-link-vertical mr-1"></i>
            <strong>Branded Terms Policy</strong>
          </a>
          <p>Review the Life Extension internet advertising program’s branded terms</p>
        </li>
        <li *ngIf="showProp65Link()">
          <a routerLink="/account/officerinfo" class="text-info">
            <i class="k-icon k-font-icon k-i-link-vertical mr-1"></i>
            <strong>Confirmation of compliance with Proposition 65</strong>
          </a>
          <p>Review Confirmation of compliance with Proposition 65</p>
        </li>
      </ul>
    </div>
  </div>
</div>
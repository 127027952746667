import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAccountInformation } from '../../model/account-information.model';

@Component({
  selector: 'lef-customer-ncoa',
  templateUrl: './customer-ncoa.component.html',
  styleUrls: ['./customer-ncoa.component.scss'],
})
export class CustomerNCOAComponent implements OnInit {
  @Output()
  closeDialog: EventEmitter<boolean> = new EventEmitter();

  @Input()
  accountInformation: IAccountInformation;

  constructor() {}

  ngOnInit(): void {}

  confirm(confirm: boolean) {
    this.closeDialog.emit(confirm);
  }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AccountService } from 'src/app/account/service/account.service';
import { ScreenSizes } from 'src/app/common/service';
import { IListItem } from 'src/app/shared/model/list-item';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { InvoicePreferences } from '../../model/invoice-preferences.model';

@Component({
  selector: 'lef-invoice-preferences',
  templateUrl: './invoice-preferences.component.html',
  styleUrls: ['./invoice-preferences.component.scss']
})
export class InvoicePreferencesComponent implements OnInit {

  model: InvoicePreferences = new InvoicePreferences();
  form: UntypedFormGroup = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];
  screenSizes = ScreenSizes;

  invoicePreferenceList: IListItem[] = [
    { value: true, description: 'Invoice is ALWAYS sent regardless of balance of order' },
    { value: false, description: 'Invoice is NEVER sent to customer regardless of balance of order' },
    { value: null, description: 'Invoice SENT on orders with open balance only' }];

  get formInvalid(): boolean {
    if (
      (this.form.get('isInvoiceNeeded')?.value === null || this.form.get('isInvoiceNeeded')?.value === true) &&
      !this.form.get('isEmail')?.value &&
      !this.form.get('isMail')?.value
    ) {
      return true;
    }
    if (this.isMissingEmails) {
      return true;
    }
    return !this.form.valid;
  }

  get isMissingEmails(): boolean {
    const emailList: Array<string> = this.form.get('emailList')?.value;
    if (this.form.get('isEmail')?.value && (!emailList || emailList.length === 0)) {
      return true;
    }
    return false;
  }

  constructor(private readonly accountService: AccountService,
    private readonly loadingService: LoadingService) { }

  ngOnInit(): void {
    this.accountService.getInvoicePreference().subscribe({
      next: (invoicePreferences: InvoicePreferences) => {
        this.model = invoicePreferences;
        this.model.emailToAdd = '';
        this.initForm();
      },
    });
  }

  onDeleteEmailClick(email: string): void {
    const emailList: Array<string> = this.form.get('emailList').value ?? [];
    const index = emailList.findIndex((a) => a === email);
    if (index >= 0) {
      this.form.get('emailList').value.splice(index, 1);
      this.form.get('emailList').markAsDirty();
    }
  }

  onSave(): void {
    this.loadingService.show();
    this.model.emailList = this.form.get('emailList').value;
    this.accountService.updateInvoicePreferences(this.model).subscribe({
      next: (invoicePreferences: InvoicePreferences) => {
        this.model = invoicePreferences;
        this.model.emailToAdd = '';
        this.form.markAsPristine();
      },
      complete: () => {
        setTimeout(() => { this.loadingService.hide(); }, 1000);
      }
    });
  }

  onAddEmailClick(): void {
    const email = this.form.get('emailToAdd').value;
    const valid = this.form.get('emailToAdd').valid;
    if (!!email && valid) {
      this.form.get('emailList').value.push(email);
      this.form.get('emailToAdd').setValue(' ');
      this.form.get('emailToAdd').setValue('');
    }
  }

  private initForm() {
    this.form = new UntypedFormGroup({});

    this.formFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            key: 'isInvoiceNeeded',
            type: 'kDropdown',
            className: 'col-12',
            templateOptions: {
              label: 'Invoice Preferences',
              labelProp: 'description',
              valueProp: 'value',
              valuePrimitive: true,
              options: this.invoicePreferenceList,
            },
            hooks: {
              onInit: () => {
                this.form.get('isInvoiceNeeded').valueChanges.subscribe({
                  next: (value) => {
                    if (value === false) {
                      this.form.get('isMail').setValue(false);
                      this.form.get('isEmail').setValue(false);
                      this.form.get('emailList').setValue([]);
                    }
                  },
                });
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'p-0',
        fieldGroup: [
          {
            key: 'isMail',
            type: 'checkbox',
            templateOptions: {
              label: 'Mail',
              hideLabel: true
            },
            expressionProperties: {
              'templateOptions.required': (model) =>
                (model.isInvoiceNeeded === null || model.isInvoiceNeeded === true) && !model.isEmail && !model.isMail,
              'templateOptions.disabled': (model) => model.isInvoiceNeeded === false,
            }
          },
        ],
      },
      {
        fieldGroupClassName: 'p-0',
        fieldGroup: [
          {
            key: 'isEmail',
            type: 'checkbox',
            templateOptions: {
              label: 'Email',
              hideLabel: true
            },
            expressionProperties: {
              'templateOptions.required': (model) =>
                (model.isInvoiceNeeded === null || model.isInvoiceNeeded === true) && !model.isEmail && !model.isMail,
              'templateOptions.disabled': (model) => model.isInvoiceNeeded === false,
            },
            hooks: {
              onInit: () => {
                this.form.get('isEmail').valueChanges.subscribe({
                  next: (value) => {
                    if (value === false) {
                      this.form.get('emailToAdd').disable();
                      this.form.get('emailList').setValue([]);
                    } else {
                      this.form.get('emailToAdd').enable();
                      let emailList = this.form.get('emailList')?.value ?? [];
                      if ((!Array.isArray(emailList) || emailList.length === 0) && !!this.model.defaultEmailAddress) {
                        emailList = [this.model.defaultEmailAddress];
                        this.form.get('emailList').setValue(emailList);
                      }
                    }
                    this.form.updateValueAndValidity();
                  },
                });
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'd-flex justify-content-start pt-4',
        fieldGroup: [
          {
            key: 'emailToAdd',
            type: 'kTextBox',
            className: 'col-12 p-0',
            templateOptions: {
              placeholder: 'Insert email',
              maxLength: 50,
              type: 'email',
              keydown: (field: any, event: KeyboardEvent) => {
                if (event.key === 'Enter') {
                  this.onAddEmailClick();
                  event.preventDefault();
                }
              },
            },
            validators: {
              validation: ['email-address', Validators.email],
            },
          },
          {
            key: 'emailList'
          }
        ],
      }
    ];
  }
}
<lef-section-title [title]="'Account Settings'"></lef-section-title>
<div class="row">
  <div class="col-md-6">
    <div class="my-account-content">
      <div class="my-account-hdr d-flex justify-content-between">
        <div>
          Your Information
        </div>
        <div><small class="req">*Indicates required fields.</small></div>
      </div>
      <div class="bg-light p-3">
        <lef-cart-messages *ngIf="model" [messages]="model.messages" [messagesTypesToHide]="messagesTypesToHide">
        </lef-cart-messages>
        <form [formGroup]="form" (ngSubmit)="saveAccountInformation()">
          <formly-form [model]="model" [fields]="formFields" [form]="form">
          </formly-form>
          <div class="text-right">
            <button type="submit" class="btn btn btn-warning px-4" [disabled]="!form.valid || form.pristine"
                    *ngIf="!model.hasErrorInAddress && formFields.length > 0">Save</button>
            <button type="button" class="btn btn btn-warning px-4 mx-2" (click)="saveAccountInformation()"
                    *ngIf="model.hasErrorInAddress">Verify Again</button>
            <button type="button" class="btn btn btn-warning px-4 mx-2" (click)="saveAsEnter()"
                    *ngIf="model.hasErrorInAddress">Save as Entered</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="my-account-content">
      <div class="my-account-hdr">
        <div>
          Your Login Settings
        </div>
      </div>
    </div>
    <div class="bg-light p-3">
      <lef-sign-in-settings [isWholesaler]="model?.isWholesaler"></lef-sign-in-settings>
    </div>
  </div>
</div>
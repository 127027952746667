import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lef-unsubscribe-verify',
  templateUrl: './unsubscribe-verify.component.html',
  styleUrls: ['./unsubscribe-verify.component.scss'],
})
export class UnsubscribeVerifyComponent implements OnInit {
  @Input() isProspect: boolean;
  @Output() cancel: EventEmitter<void> = new EventEmitter();
  @Output() verify: EventEmitter<void> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onCancel() {
    this.cancel.emit();
  }

  onVerify() {
    this.verify.emit();
  }
}

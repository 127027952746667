<div>
  <div class="py-4">
    <lef-section-title [title]="titleText"></lef-section-title>
  </div>
  <button *ngIf="showReturnToOrders" class="btn btn-link text-primary font-weight-medium d-flex align-items-center mb-2"
          (click)="backToOrder()">
    <i class="lef-chevron-circle-left text-orange mr-1"></i>
    <span *ngIf="!source">Back to My Order</span>
    <span *ngIf="source">My Orders</span>
  </button>
  <button *ngIf="showPreviousStep" class="btn btn-link text-primary font-weight-medium d-flex align-items-center mb-2"
          (click)="setPreviousStep()">
    <i class="lef-chevron-circle-left text-orange mr-1"></i> {{previousStepText}}
  </button>
  <a *ngIf="showContinueShopping" class="btn btn-link text-primary font-weight-medium d-flex align-items-center mb-2"
     [attr.href]="shoppingUrl">
    <i class="lef-chevron-circle-left text-orange mr-1"></i> Continue Shopping
  </a>

  <div *ngIf="showReturnsCompleteMessage" class="bg-light p-4 d-flex justify-content-around">
    <div class="text-center">
      <p>All items for this order have been returned or the order has been cancelled.</p>
      <ul class="co-contact-info">
        <li>
          <a class="btn btn-link " [routerLink]="['/account/orderhistory/' + orderNumber]">
            <i class="lef-chevron-circle-left text-orange"></i> Back to Order Details
          </a>
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="showCustomerServiceMessage" class="bg-light p-4 d-flex justify-content-around">
    <div class="text-center">
      <p [innerHTML]="noReturnsMessage"></p>
      <lef-contact-us [align]="'center'"></lef-contact-us>
    </div>
  </div>

  <div *ngIf="returnOrder">
    <div class="row position-relative">
      <div class="col-lg-9 returns-info mb-3 mb-lg-0">
        <div *ngIf="orderInvalid" class="alert alert-danger" role="alert">
          <i class="lef-warning"></i>
          <span>Some items in your return need to be addressed before placing your return.</span>
        </div>
        <lef-cart-messages [messages]="returnOrder.messages"></lef-cart-messages>
        <div class="returns-header" [ngClass]="{'returns-header-blue': returnOrder.returnStep === returnSteps.Confirm}">
          <div *ngIf="returnOrder.returnStep === returnSteps.Complete" class="d-lg-flex justify-content-between"
               [ngClass]="{'returns-confirmation': showPayment}">
            <div>
              <p class="font-weight-bold font-md">Your return has been initiated successfully.</p>
              <p class="return-ty-header">
                Return #{{returnOrder.returnNumber}} | {{returnOrder.returnDate | date: 'MM/dd/yyyy'}}
              </p>
              <div>An email confirmation has been sent to <span
                      class="font-weight-bold">{{returnOrder.customerEmail}}</span></div>
            </div>
            <div class="text-right">
              <button class="btn btn-link">
                <div class="d-flex align-items-center" (click)="printConfirmation()">
                  Print Confirmation <i class="lef-print font-lg ml-2"></i>
                </div>
              </button>
              <br />
              <button *ngIf="returnOrder.trackingNumber" class="btn btn-action"
                      (click)="openShippingLabelDialog(dialogActions)">
                Print Shipping Label
              </button>
            </div>
          </div>
          <div *ngIf="showPayment" class="returns-selection-box "
               [ngClass]="{'returns-selection-box-blue': returnOrder.returnStep === returnSteps.Confirm}">
            <div #coverPayment>
              <div class="w-100 mb-1">
                <div *ngIf="showPaymentMessage" class="d-flex text-red">
                  <i class="lef-warning font-1x mr-2 mt-1"></i>
                  <div>A payment method is required to start this return.</div>
                </div>
                <lef-payment-method-selector [initialPayment]="returnOrder.paymentOptionSelected" [isReturn]="true"
                                             [hidePaymentMethodActions]="true"
                                             [preventChange]="returnOrder.returnStep > returnSteps.Confirm"
                                             [paymentMethodLabel]="'Payment Method for Shipping or Non Returns: '"
                                             [labelClass]="'text-primary font-weight-bold mr-1'"
                                             (paymentOptionSelected)="onPaymentOptionSelected($event)">
                </lef-payment-method-selector>
                <div class="d-flex">
                  <i class="lef-warning text-blue font-md mr-2 mt-1"></i>
                  <div>A payment method is required in case returned items are not received to our warehouse within 30
                    days. This method may also cover Replacement Shipping costs.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="returnOrder.returnStep === returnSteps.Complete && returnOrder.shouldSendBackItems">
          <div class="bg-primary text-white px-3 py-2 w-100">
            <span class="font-weight-bold">Item(s) Expected at Warehouse</span>
          </div>
          <div class="px-3 py-2 alert-warning text-body" role="alert">
            <div class="d-md-flex">
              <div class="border-md-right border-dark mb-md-3 pr-3 mr-3">
                <span class="text-primary font-weight-bold">Status:</span> {{returnOrder.status}}
              </div>
              <div>
                <span class="text-primary font-weight-bold">Expected Return Date: </span>
                {{returnOrder.expectedAtWarehouseDate | date: 'MM/dd/yyyy' }}
              </div>
            </div>
            <div *ngIf="!returnOrder.isRmaOnly" class="mt-2">
              We must receive your item(s) by this date to avoid incurring a charge.
              <i class="lef-information-solid text-info clickable" (click)="openDeadlineInfoDialog(deadlineInfo)"></i>
            </div>
            <div *ngIf="returnOrder.isRmaOnly" class="mt-2">
              Your refund is being processed and will be issued within 1-3 business days of receiving the returned items
              to our warehouse.
            </div>
          </div>
          <div class="px-3 py-2 bg-light">
            <table>
              <thead>
                <tr class="text-primary">
                  <th class="pr-4 border-right border-separator">Item#</th>
                  <th class="w-100 px-4 border-right border-separator">Name</th>
                  <th class="text-right pl-4">Quantity</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let returnItem of confirmedItems">
                  <td class="pr-4 border-right border-separator">{{returnItem.itemNumber}}</td>
                  <td class="w-100 px-4 border-right border-separator">{{returnItem.itemName}},
                    {{returnItem.nameSuffix}}</td>
                  <td class="text-right pl-4">{{returnItem.quantityToSendBack}}</td>
                </tr>
              </tbody>
            </table>
            <div class="spacer-horizontal"></div>
            <div class="d-md-flex justify-content-between">
              <div>
                <div class="font-weight-bold text-primary">Instructions</div>
                <ol>
                  <li>
                    <ng-container *ngIf="!returnOrder.trackingNumber">
                      An email will be sent with a link to your shipping label. Print your shipping label
                    </ng-container>
                    <ng-container *ngIf="returnOrder.trackingNumber">
                      Print your <button class="btn btn-link" (click)="openShippingLabelDialog(dialogActions)">
                        <div class="d-flex align-items-center">
                          shipping label <i class="lef-print font-lg ml-2"></i>
                        </div>
                      </button>
                    </ng-container>
                    and
                    <button class="btn btn-link">
                      <div class="d-flex align-items-center" (click)="printConfirmation()">
                        this page <i class="lef-print font-lg ml-2"></i>
                      </div>
                    </button>.
                  </li>
                  <li>Pack the items listed above, including this page print out.</li>
                  <li>Affix the shipping label to your package.</li>
                  <li>Take package to your local drop-off location, or arrange to have it picked up.</li>
                </ol>
              </div>
              <div class="text-center">
                <img src="https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl={{returnOrder.returnNumber}}" />
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="returnOrder.returnStep >= returnSteps.Confirm && replacementItems.length > 0">
          <div class="d-flex justify-content-between bg-primary text-white px-3 py-2 w-100">
            <span class="font-weight-medium">Replace with the Exact Same Item(s)</span>
            <span>{{returnOrder.replacementItemCount}} Item(s)</span>
          </div>
          <div class="returns-selection-box"
               [ngClass]="{'returns-selection-box-grey': returnOrder.returnStep === returnSteps.Confirm,'returns-selection-box-blue': returnOrder.returnStep > returnSteps.Confirm}">
            <div #shippingAddress>
              <div>
                <div *ngIf="showShippingMessage" class="d-flex text-red">
                  <i class="lef-warning font-md mr-2 mt-1"></i>
                  <div>Replacements are only available within the continental US.</div>
                </div>
                <span class="returns-selection-box-title">
                  Shipping Address:
                </span>
                <ng-container
                              *ngIf="returnOrder.shippingSummary && returnOrder.shippingSummary.selectedAddress as selectedAddress">
                  {{selectedAddress.address}} {{selectedAddress.city}}, {{selectedAddress.state}}
                  {{selectedAddress.postalCode}}
                </ng-container>
              </div>
              <button *ngIf="returnOrder.returnStep === returnSteps.Confirm" class="btn btn-link"
                      (click)="openShippingAddressDialog()">
                Change
              </button>
            </div>
            <div>
              <div>
                <span class="returns-selection-box-title">
                  Replacement Shipping Method:
                </span>
                <ng-container
                              *ngIf="returnOrder.shippingSummary && returnOrder.shippingSummary.shippingMethod as shippingMethod">
                  {{shippingMethod.description}}
                  <span class="font-weight-bold">{{replacementRate}}</span>
                </ng-container>
              </div>
              <button *ngIf="returnOrder.returnStep === returnSteps.Confirm" class="btn btn-link"
                      (click)="openShippingMethodDialog()">
                Change
              </button>
            </div>
          </div>
          <lef-return-item *ngFor="let replacementItem of replacementItems" [returnItem]="replacementItem"
                           [returnReasons]="returnOrder.returnReasons" [returnStep]="returnOrder.returnStep"
                           [mustSendBackItems]="returnOrder.mustSendBackItems"
                           (whyUnavailable)="openUnavailableInfoDialog(unavailableInfo)">
          </lef-return-item>
        </div>
        <div *ngIf="returnItems.length > 0">
          <div class="d-flex justify-content-between bg-primary text-white px-3 py-2 w-100">
            <span *ngIf="returnOrder.returnStep < returnSteps.Confirm" class="font-weight-medium">Choose Which Items to
              Return From Order #{{returnOrder.orderNumber}}</span>
            <span *ngIf="returnOrder.returnStep >= returnSteps.Confirm" class="font-weight-medium">Refund</span>
            <span *ngIf="returnOrder.returnStep === returnSteps.SelectItems" class="d-none d-md-block">*Indicates
              required fields.</span>
            <span *ngIf="returnOrder.returnStep >= returnSteps.Confirm">{{returnOrder.refundItemCount}} Item(s)</span>
          </div>
          <lef-return-item *ngFor="let returnItem of returnItems" [returnItem]="returnItem"
                           [returnReasons]="returnOrder.returnReasons" [returnStep]="returnOrder.returnStep"
                           [mustSendBackItems]="returnOrder.mustSendBackItems"
                           (whyUnavailable)="openUnavailableInfoDialog(unavailableInfo)">
          </lef-return-item>
        </div>
      </div>
      <div class="col-lg-3">
        <lef-scroll-container [scrollOffset]="120" [isResponsive]="true" [minScreenSize]="screenSizes.LG">
          <lef-return-summary [returnsSelected]="returnsSelected" [returnOrder]="returnOrder"
                              (nextStep)="setNextStep()"></lef-return-summary>
        </lef-scroll-container>
      </div>
    </div>
  </div>
</div>

<ng-template #dialogActions>
  <button kendoButton class="k-info font-lg" (click)="printLabel()">
    Reprint Label <i class="lef-print ml-2"></i>
  </button>
</ng-template>

<ng-template #unavailableInfo>
  <div class="p-3">
    <p>Certain products are ineligible for return, or the deadline for returns may have passed.</p>
    <p>For further information, please contact Customer Service at:<br /><b class="text-primary">1-800-678-8989</b></p>
    <p>
      If this request is regarding a <b>laboratory test</b>, please reach out to our Wellness Specialists at:<br />
      <b class="text-primary">1-800-226-2370</b>
    </p>
  </div>
</ng-template>

<ng-template #deadlineInfo>
  <div class="p-3">
    Because we've already processed your refund/exchange, you will be charged if we do not receive your item(s) by the
    expected return date.
  </div>
</ng-template>
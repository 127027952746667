import { AuthorizationRoutes, AuthorizationRoutesModel } from './authorization-routes.model';

// Required Values
export interface AuthorizationConfigValues {
  serverUrl: string;
}

// Optional/Override Values Set Through Default
export interface AuthorizationConfigOptions {
  authorizationCookie?: string;
  authorizationPath?: string;
  loginAction?: string;
  logoutAction?: string;
  cookieCustomerNumber?: string;
  cookieCustomerType?: string;
  cookieNameToken?: string;
  cookieLoginStatus?: string;
  cookieWholesalerType?: string;
  routes?: AuthorizationRoutes;
}

export interface AuthorizationConfig extends AuthorizationConfigValues, AuthorizationConfigOptions { }

export const AuthorizationConfigDefault: AuthorizationConfig = {
  authorizationCookie: 'AC',
  authorizationPath: 'api/authorization',
  loginAction: 'login',
  logoutAction: 'logout',
  cookieCustomerNumber: 'AC_CNBR',
  cookieCustomerType: 'AC_MT',
  cookieNameToken: 'AC_MN',
  cookieLoginStatus: 'AC_LS',
  cookieWholesalerType: 'AC_WT',
  serverUrl: null,
  routes: new AuthorizationRoutesModel('cart', 'unauthorized'),
};

export class AuthorizationRootConfigModel {
  public apiServer: string;
  public optionalConfig?: AuthorizationConfigOptions;
}

export class AuthorizationConfigModel {
  private configuration: AuthorizationConfig;

  constructor(server: string, authConfigOptions?: AuthorizationConfigOptions) {
    authConfigOptions = authConfigOptions || {};
    // Only take values when set.
    Object.keys(authConfigOptions).forEach((k: string) => {
      if (authConfigOptions[k]) {
        AuthorizationConfigDefault[k] = authConfigOptions[k];
      }
    });
    this.configuration = AuthorizationConfigDefault;
    this.configuration.serverUrl = server;
  }

  public getConfig(): AuthorizationConfig {
    return this.configuration;
  }
}

<div class="flex-form-row prop65-grid">
  <kendo-grid [kendoGridBinding]="itemProp65List" [sortable]="true" [groupable]="false" [reorderable]="true"
              [resizable]="true" [columnMenu]="{ filter: true }" [pageable]="true" [pageSize]="20" [autoSize]="false">
    <kendo-grid-column field="itemsString" title="Item(s)" [width]="125" [autoSize]="false">
    </kendo-grid-column>
    <kendo-grid-column field="description" title="Description" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column field="message" title="Warning" [width]="125">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div [innerHTML]="dataItem.message"></div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
import { Component, OnInit } from '@angular/core';
import { IContactPreferences } from 'src/app/preferences/model';
import { ContactPreferencesService } from 'src/app/preferences/service/contact-preferences.service';
import { LoadingService } from 'src/app/shared/service/loading.service';

@Component({
  selector: 'lef-contact-preferences',
  templateUrl: './contact-preferences.component.html',
  styleUrls: ['./contact-preferences.component.scss'],
})
export class ContactPreferencesComponent implements OnInit {
  contactPreferences: IContactPreferences;

  constructor(private readonly contactPreferencesService: ContactPreferencesService, private readonly loadingService: LoadingService) {}

  ngOnInit() {
    this.loadContactPreferences();
  }

  private loadContactPreferences() {
    this.loadingService.show('Loading...');
    this.contactPreferencesService.getContactPreferences().subscribe({
      next: (contactPreferences: IContactPreferences) => {
        this.contactPreferences = contactPreferences;
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }
}

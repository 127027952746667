<div #returnItemDiv class="return-item-wrapper">
  <div *ngIf="returnStep === returnSteps.SelectItems" class="return-item-checkbox-wrapper">
    <input *ngIf="returnItem.isReturnAllowed" [attr.aria-labelledby]="returnItem.id + '-' + returnItem.shipToNumber"
           type="checkbox" class="return-item-checkbox" [(ngModel)]="returnItem.isSelected"
           [disabled]="!canReturnOnline" />
  </div>

  <div class="return-item-content-wrapper">
    <div class="return-item-content">
      <div class="row co-item-wrap">
        <div class="col-lg">
          <div class="d-flex">
            <div class="return-item-image-wrapper">
              <a [attr.href]="itemUrl">
                <img alt="Product Image" tabindex="-1" aria-hidden="true"
                     [ngClass]="{'return-item-image': screenSize >screenSizes.MD, 'return-item-image-sm': screenSize < screenSizes.LG }"
                     [defaultImage]="defaultImage" [errorImage]="defaultImage" [lazyLoad]="itemImageUrl" />
              </a>
            </div>
            <div>
              <p class="return-item-title" [id]="returnItem.id + '-' + returnItem.shipToNumber"><a
                   [attr.href]="itemUrl">{{returnItem.itemName}}</a></p>
              <p class="return-item-number">
                <span *ngIf="returnItem.nameSuffix">{{returnItem.nameSuffix}} | </span>
                <span>#{{returnItem.itemNumber}}</span>
              </p>
              <ng-container *ngIf="returnItem.isReturnAllowed">
                <p *ngIf="returnStep === returnSteps.SelectItems">Quantity: <b>{{returnItem.quantityOrdered}}</b></p>
                <p *ngIf="returnStep > returnSteps.SelectItems">Quantity to Return:
                  <strong>{{returnItem.quantityToReturn}}</strong>
                </p>
                <p>Return Available Until: {{returnItem.returnExpiration | date}}</p>
              </ng-container>
              <ng-container *ngIf="!returnItem.isReturnAllowed">
                <p>Return Not Available <button class="btn btn-link" (click)="whyUnavailable.next()">Why?</button>
                </p>
              </ng-container>
            </div>
          </div>
          <div *ngIf="!canReturnOnline" class="alert alert-warning" role="alert">
            <i class="lef-warning text-red"></i>
            <span>
              Return unavailable online. Call Customer Service: <b>1-800-678-8989</b>
            </span>
          </div>
        </div>
        <div *ngIf="(returnItem.isSelected || !canReturnOnline)" [ngClass]="selectionClass">
          <div [ngSwitch]="returnStep">
            <ng-container *ngSwitchCase="returnSteps.SelectItems">
              <form class="k-form">
                <formly-form [model]="returnItem" [fields]="selectItemFormFields" [form]="selectItemForm">
                </formly-form>
              </form>
            </ng-container>
            <form class="k-form" *ngSwitchCase="returnSteps.SetOptions" [formGroup]="returnOptionsForm">
              <div class="selection-body">
                <div *ngIf="offerExchange" class="selection-record">
                  <input kendoRadioButton [attr.id]="'replaceItem' + returnItem.itemNumber"
                         class="k-radio form-check-input k-radio" type="radio" [value]="returnOptions.ReplaceItem"
                         formControlName="returnOption" name="returnOption" />
                  <label [attr.for]="'replaceItem' + returnItem.itemNumber" class="form-check-label clickable">
                    Replace with the exact same items(s)
                  </label>
                </div>
                <div class="selection-record">
                  <input kendoRadioButton [attr.id]="'refundPayment' + returnItem.itemNumber"
                         class="k-radio form-check-input k-radio" type="radio" [value]="returnOptions.RefundPayment"
                         formControlName="returnOption" name="returnOption" />
                  <label [attr.for]="'refundPayment' + returnItem.itemNumber" class="form-check-label clickable">
                    Refund item(s)
                  </label>
                </div>
              </div>
            </form>
            <ng-container *ngSwitchDefault>
              <div *ngIf="showReturnConfirmation" class="w-100 bg-light-blue px-3 pt-3 pb-1 mt-2">
                <formly-form [model]="returnItem" [fields]="confirmFormFields" [form]="confirmForm"> </formly-form>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
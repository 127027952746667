import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core/lib/models';
import { forkJoin } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { FormlyHelper } from 'src/app/shared/form/formly-helper';
import { IListItem } from 'src/app/shared/model/list-item';
import { CommonService } from 'src/app/shared/service/common.service';
import { AccountService } from '../../service/account.service';
import { IOfficer, OfficerModel } from './../../model/officer.model';
import { AccountDialogService } from './../../service/account-dialog.service';

@Component({
  selector: 'lef-officer-info',
  templateUrl: './officer-info.component.html',
  styleUrls: ['./officer-info.component.scss'],
})
export class OfficerInfoComponent implements OnInit {
  officer: IOfficer;
  model: IOfficer = new OfficerModel();
  countries: IListItem[] = [];
  states: IListItem[] = [];
  prop65SaleOptions: IListItem[] = [];
  showEdit = false;
  form: UntypedFormGroup = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];
  showProp65List = false;

  constructor(
    private readonly accountService: AccountService,
    private readonly commonService: CommonService,
    public readonly config: CoreConfiguration,
    private readonly dialogService: AccountDialogService
  ) {}
  ngOnInit(): void {
    this.init();
  }
  init() {
    if (!this.model) {
      this.model = new OfficerModel();
    }
    this.getOfficer();
  }

  private getOfficer() {
    forkJoin([
      this.commonService.getCountryList(),
      this.commonService.getStateList(''),
      this.commonService.getProp65SaleOptionsList(),
      this.accountService.getOfficer(),
    ])
      .pipe(
        map(([countries, states, prop65SaleOptions, officerModel]) => {
          return { countries, states, prop65SaleOptions, officerModel };
        })
      )
      .subscribe((data) => {
        this.countries = data.countries;
        this.states = data.states;
        this.prop65SaleOptions = data.prop65SaleOptions;
        this.officer = data.officerModel;
        Object.assign(this.model, this.officer);
        this.initForm();
      });
  }

  showProp65Products() {
    this.dialogService.openProp65ProductsDialog();
  }

  private onCountryChange(country) {
    if (this.form.get('officerState')) {
      this.form.get('officerState').setValue('');
    } else {
      this.model.officerState = null;
    }

    if (country === '' || country === 'CA') {
      this.commonService.getStateList(country).subscribe((states) => {
        this.states = states;
        FormlyHelper.getField('officerState', this.formFields).templateOptions.options = this.states;
      });
    }
  }

  onSameAsBillingChange(sameAsBilling) {
    if (sameAsBilling) {
      this.form.get('officerCountry').setValue(this.model.organizationCountry);
      if (this.model.organizationPhone !== '') {
        this.form.get('officerPhone').setValue(this.model.organizationPhone);
      }
      setTimeout(() => {
        this.form.get('officerState').setValue(this.model.organizationState);
        this.form.get('officerAddressLn1').setValue(this.model.organizationAddress);
        this.form.get('officerAddressLn2').setValue(this.model.organizationAddress2);
        this.form.get('officerCity').setValue(this.model.organizationCity);
        this.form.get('officerZipCode').setValue(this.model.organizationZip);
        this.initForm();
      }, 500);
    } else {
      this.form.get('officerCountry').setValue(this.officer.officerCountry);
      if (this.officer.officerPhone !== '') {
        this.form.get('officerPhone').setValue(this.officer.officerPhone);
      }
      setTimeout(() => {
        this.form.get('officerState').setValue(this.officer.officerState);
        this.form.get('officerAddressLn1').setValue(this.officer.officerAddressLn1);
        this.form.get('officerAddressLn2').setValue(this.officer.officerAddressLn2);
        this.form.get('officerCity').setValue(this.officer.officerCity);
        this.form.get('officerZipCode').setValue(this.officer.officerZipCode);
        this.initForm();
      }, 500);
    }
  }

  edit(edit: boolean) {
    this.showEdit = edit;
    if (!edit) {
      Object.assign(this.model, this.officer);
      this.initForm();
    }
  }

  saveOfficer() {
    if (!this.form.valid || !this.model) {
      return;
    }

    this.accountService.saveOfficer(this.model).subscribe((response) => {
      this.officer = response;
      Object.assign(this.model, this.officer);
      this.showEdit = false;
    });
  }

  private initForm() {
    this.form = new UntypedFormGroup({});
    this.formFields = [
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-4',
            type: 'kTextBox',
            key: 'officerFirstName',
            templateOptions: {
              placeholder: 'Contact First Name*',
              autocomplete: 'given-name'
            },
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-4',
            type: 'kTextBox',
            key: 'officerLastName',
            templateOptions: {
              placeholder: 'Contact Last Name*',
              autocomplete: 'family-name'
            },
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-4',
            type: 'kTextBox',
            key: 'officerEmail',
            templateOptions: {
              placeholder: 'Contact Email*',
            },
            validators: {
              validation: [Validators.required, Validators.email],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'checkbox',
            key: 'sameAsBilling',
            templateOptions: {
              label: 'Use Billing Address on file',
            },
            hooks: {
              onInit: (field) => {
                return field.formControl.valueChanges
                  .pipe(debounceTime(400))
                  .subscribe((sameAsBilling) => this.onSameAsBillingChange(sameAsBilling));
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-4',
            type: 'kTextBox',
            key: 'officerAddressLn1',
            templateOptions: {
              placeholder: 'Street Address 1*',
              autocomplete: 'address-line1'
            },
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-4',
            type: 'kTextBox',
            key: 'officerAddressLn2',
            templateOptions: {
              placeholder: 'Street Address 2',
              autocomplete: 'address-line2'
            },
          },
          {
            className: 'col-4',
            type: 'kTextBox',
            key: 'officerCity',
            templateOptions: {
              placeholder: 'City*',
              autocomplete: 'city'
            },
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-3',
            type: 'kDropdown',
            key: 'officerCountry',
            templateOptions: {
              placeholder: 'Country',
              options: this.countries,
              valueProp: 'code',
              labelProp: 'description',
              autocomplete: 'country-name'
            },
            hooks: {
              onInit: (field) => {
                return field.formControl.valueChanges
                  .pipe(debounceTime(400))
                  .subscribe((officerCountry) => this.onCountryChange(officerCountry));
              },
            },
          },
          {
            className: 'col-3',
            type: 'kDropdown',
            key: 'officerState',
            templateOptions: {
              placeholder: 'State',
              options: this.states,
              valueProp: 'value',
              labelProp: 'description',
              autocomplete: 'state'
            },
            hideExpression: (model: OfficerModel) => model.officerCountry !== '' && model.officerCountry !== 'CA',
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-3',
            type: 'kTextBox',
            key: 'officerZipCode',
            templateOptions: {
              placeholder: 'Zip Code*',
              autocomplete: 'postal-code'
            },
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-3',
            type: 'kTextBox',
            key: 'officerPhone',
            templateOptions: {
              placeholder: 'Phone*',
              autocomplete: 'tel'
            },
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-4',
            type: 'kDropdown',
            key: 'prop65SaleOption',
            templateOptions: {
              options: this.prop65SaleOptions,
              valueProp: 'value',
              labelProp: 'description',
            },
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-4',
            template: `<strong>
            By providing your name and clicking Confirm, you are confirming receipt of Life Extension's Proposition 65 Agreement
            </strong>`,
          },
          {
            className: 'col-4',
            type: 'kTextBox',
            key: 'signature',
            templateOptions: {
              placeholder: 'Name*',
            },
            expressionProperties: {
              'templateOptions.disabled': '!model.canRenew',
            },
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
    ];
  }
}

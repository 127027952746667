import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TextBoxModule } from '@progress/kendo-angular-inputs';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import {
  OrderDetailsComponent,
  OrderHistoryMyOrdersComponent,
  OrderHistoryOrderDetailComponent,
  OrderItemComponent,
  OrderShipmentComponent,
  ReturnsAlertComponent
} from './component';
import { FindMyOrderComponent } from './component/find-my-order/find-my-order.component';

const components = [
  OrderHistoryMyOrdersComponent,
  OrderHistoryOrderDetailComponent,
  OrderShipmentComponent,
  OrderItemComponent,
  ReturnsAlertComponent,
  OrderDetailsComponent,
  FindMyOrderComponent,
];

const modules = [CommonModule, SharedModule, CommonsModule, CartCommonModule, TextBoxModule, RouterModule];

@NgModule({
    imports: [modules],
    declarations: [components]
})
export class OrderHistoryModule { }

export interface IWishList {
  wishListId: string;
  wishListName: string;
  disable: boolean;
  isDefaultWishList: boolean;
}

export class WishListModel implements IWishList {
  wishListId: string;
  wishListName: string;
  disable: boolean;
  isDefaultWishList: boolean;
  constructor() {
    this.disable = false;
    this.isDefaultWishList = false;
    this.wishListId = null;
    this.wishListName = '';
  }
}

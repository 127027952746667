import { Component, OnDestroy, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Subscription } from 'rxjs';
import { ResponsiveService, ScreenSizes } from 'src/app/common/service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ILabSubjectInfo } from '../../model/lab-subject-info';
import { ILabSubjectList } from '../../model/lab-subject-list';
import { LabTestService } from '../../service/lab-test.service';
import { BloodLabSubjectComponent } from '../blood-lab-subject/blood-lab-subject.component';

@Component({
  selector: 'lef-lab-subjects',
  templateUrl: './lab-subjects.component.html',
  styleUrls: ['./lab-subjects.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabSubjectsComponent implements OnInit, OnDestroy {
  private subjectDialogRef: DialogRef;
  private infoDialogRef: DialogRef;

  private responsiveSubscription: Subscription;

  screenSize: ScreenSizes;
  screenSizes = ScreenSizes;

  labSubjectList: ILabSubjectList = { customerNumber: 0, labSubjects: [] };

  constructor(
    private readonly labTestService: LabTestService,
    private readonly loadingService: LoadingService,
    private readonly dialogService: DialogService,
    private readonly responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this.loadSubjects();
    this.subscribeToResponsive();
  }

  ngOnDestroy(): void {
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
  }

  private loadSubjects() {
    this.loadingService.show('Loading...');
    this.labTestService.getLabSubjects().subscribe({
      next: (labSubjects: ILabSubjectList) => {
        this.labSubjectList = labSubjects;
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  getGenderText(gender: string): string {
    return gender.toUpperCase() === 'M' ? 'MALE' : gender.toUpperCase() === 'F' ? 'FEMALE' : 'N/A';
  }

  addLabSubject() {
    this.showSubjectDialog({ customerNumber: this.labSubjectList.customerNumber, subjectNumber: 0 });
  }

  editLabSubject({ sender, rowIndex, dataItem }) {
    this.showSubjectDialog(dataItem);
  }

  showSubjectDialog(dataItem) {
    this.subjectDialogRef = this.dialogService.open({
      title: dataItem.subjectNumber !== 0 ? 'Edit Lab Test Subject' : 'Add New Lab Test Subject',
      width: 800,
      maxHeight: '90%',
      maxWidth: '90%',
      content: BloodLabSubjectComponent,
    });

    const labSubjectComponent: BloodLabSubjectComponent = this.subjectDialogRef.content.instance;
    labSubjectComponent.customerNumber = dataItem.customerNumber;
    if (dataItem.subjectNumber !== 0) {
      labSubjectComponent.subjectNumber = dataItem.subjectNumber;
    }

    labSubjectComponent.subjectSaved.subscribe((labSubject: ILabSubjectInfo) => {
      const subject = this.labSubjectList.labSubjects.find((ls: ILabSubjectInfo) => ls.subjectNumber === labSubject.subjectNumber);

      if (subject) {
        Object.assign(subject, labSubject);
      } else {
        this.labSubjectList.labSubjects.splice(0, 0, labSubject);
      }

      this.subjectDialogRef.close();
    });

    labSubjectComponent.infoLabelClicked.subscribe((requisitionDetails: TemplateRef<any>) => {
      this.infoDialogRef = this.dialogService.open({
        title: 'Lab Test Requisition Options',
        width: 700,
        maxHeight: '90%',
        maxWidth: '90%',
        content: requisitionDetails,
      });

      this.infoDialogRef.result.subscribe((result) => {
        this.infoDialogRef = null;
      });
    });

    this.subjectDialogRef.result.subscribe((result) => {
      this.subjectDialogRef = null;
    });
  }

  private subscribeToResponsive() {
    this.responsiveSubscription = this.responsiveService.onResize$.subscribe((screenSize: ScreenSizes) => {
      this.screenSize = screenSize;
    });
    this.responsiveService.checkCurrentSize();
  }
}

<form class="k-form" [formGroup]="preferencesForm" (ngSubmit)="savePreferences()">
  <div class="row">
    <div class="col-md-8 col-lg-9">
      <lef-scroll-container [scrollOffset]="55" [isResponsive]="true" [maxScreenSize]="screenSizes.MD">
        <div class="d-md-none float-cart-action">
          <button kendoButton type="submit" class="k-action w-100 mb-2 font-weight-bold"
                  [disabled]="!preferencesForm.dirty">
            Confirm Changes
          </button>
        </div>
        <div noScrollBottom class="bg-light p-4 preferences">
          <div class="row" *ngIf="isWholeSale">
            <div class="col-md-5">
              <p class="cp-title-lg" id="channelPref">
                Wholesale Preferences
              </p>
              <p class="cp-title-md" id="leNewsTitle">
                Promotional Email
              </p>
              <input kendoTextBox lefSetFocus type="text" class="form-control login-email"
                     [attr.placeholder]="'Promotional Email'" formControlName="marketingEmail"
                     aria-labelledby="marketingEmail" />
              <div *ngIf="preferencesForm.controls['marketingEmail'].invalid">
                <p class="text-red mt-1">Invalid e-mail address</p>
              </div>
              <div *ngIf="contactPreferences.marketingEmailInvalid">
                <p class="text-red mt-1">{{contactPreferences.marketingEmailValidationMessage}}</p>
              </div>
            </div>
            <div class="preferences-section-bottom"></div>
          </div>
          <div class="row">
            <div class="col-md-5 selection-border">
              <p class="cp-title-lg" id="channelPref">
                Channel Preferences
              </p>
              <p id="receiveCommunication">
                I'd like to receive communication via the following
                channels:
              </p>
              <div class="preferences-qna">
                <div class="cp-title-md">
                  YES / NO
                </div>
                <div class="preference">
                  <div class="pref-child" role="radiogroup" aria-labelledby="channelPref receiveCommunication email">
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" tabindex="0" aria-label="yes"
                             name="email" [attr.aria-checked]="preferencesForm.get('email').value" type="radio"
                             [value]="true" formControlName="email" />
                    </div>
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" aria-label="no" name="email"
                             [attr.aria-checked]="!preferencesForm.get('email').value" type="radio" [value]="false"
                             formControlName="email" />
                    </div>
                  </div>
                  <label class="preference-question form-check-label" id="email">
                    Email <span class="font-sm">(select NO to unsubscribe)</span>
                  </label>
                </div>
                <div class="preference">
                  <div class="pref-child" role="radiogroup" aria-labelledby="mail">
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" tabindex="0" aria-label="yes" name="mail"
                             [attr.aria-checked]="preferencesForm.get('mail').value" type="radio" [value]="true"
                             formControlName="mail" />
                    </div>
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" aria-label="no" name="mail"
                             [attr.aria-checked]="!preferencesForm.get('mail').value" type="radio" [value]="false"
                             formControlName="mail" />
                    </div>
                  </div>
                  <label class="preference-question form-check-label" id="mail">
                    Mail
                  </label>
                </div>
                <!-- To Implement When Available
                <div *ngIf="!isWholeSale" class="preference">
                  <div class="preference-answer">
                    <input kendoRadioButton class="k-radio form-check-input" type="radio" [value]="true"
                           formControlName="sms" />
                  </div>
                  <div class="preference-answer">
                    <input kendoRadioButton class="k-radio form-check-input" type="radio" [value]="false"
                           formControlName="sms" />
                  </div>
                  <label class="preference-question form-check-label text-grey">
                    SMS / Text Message <i class="lef-information-solid text-info clickable"
                       (click)="showInfoDialog('sms')"></i>
                  </label>
                </div>
                  <div *ngIf="contactPreferences.sms && contactPreferences.smsPhoneNumber" class="sms-number">
                  <b>SMS / Text Message #:</b> {{contactPreferences.smsPhoneNumber}}
                </div>
                -->
              </div>
            </div>
            <div class="content-separator d-md-none"></div>
            <div class="col-md-7">
              <p class="cp-title-lg" id="magTitle">
                <i>Life Extension</i> Magazine<sup>&reg;</sup>
              </p>
              <div class="row">
                <div class="col-sm-4 col-md-12 col-lg-4">
                  <img class="mag-image" alt="Life Extension Magazine" [defaultImage]="defaultImage"
                       [errorImage]="defaultImage" [lazyLoad]="magazineImage" />
                </div>
                <div class="col-sm-8 col-md-12 col-lg-8">
                  <p id="magSubTitle">
                    Packed with the latest global medical findings and research results, Life Extension Magazine is
                    the
                    world's premier publication on staying healthy and living longer.
                  </p>
                  <div class="preferences-qna">
                    <div class="cp-title-md">
                      YES / NO
                    </div>
                    <div class="preference">
                      <div class="pref-child" role="radiogroup" aria-labelledby="magTitle magSubTitle">
                        <div class="preference-answer">
                          <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="yes"
                                 name="emailMagazine" [attr.aria-checked]="preferencesForm.get('emailMagazine').value"
                                 [value]="true" formControlName="emailMagazine" />
                        </div>
                        <div class="preference-answer">
                          <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="no"
                                 name="emailMagazine" [attr.aria-checked]="!preferencesForm.get('emailMagazine').value"
                                 [value]="false" formControlName="emailMagazine" />
                        </div>
                      </div>
                      <label class="preference-question form-check-label" id="receiveByEmail">
                        Receive by Email
                      </label>
                    </div>
                    <div class="preference">
                      <div class="pref-child" role="radiogroup" aria-labelledby="receiveByMail">
                        <div class="preference-answer">
                          <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="yes"
                                 name="mailMagazine" [attr.aria-checked]="preferencesForm.get('mailMagazine').value"
                                 [value]="true" formControlName="mailMagazine" />
                        </div>
                        <div class="preference-answer">
                          <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="no"
                                 name="mailMagazine" [attr.aria-checked]="!preferencesForm.get('mailMagazine').value"
                                 [value]="false" formControlName="mailMagazine" />
                        </div>
                      </div>
                      <label class="preference-question form-check-label">
                        Receive by Mail
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="preferences-section-bottom"></div>
          </div>
          <div class="row">
            <p class="col cp-title-lg">
              Content Preferences
            </p>
          </div>
          <div class="row">
            <div class="col-md-5 selection-border">
              <p class="cp-title-md" id="promoTitle">
                Promotions and Special Offers
              </p>
              <p id="promoSub">
                {{promotionText}}
              </p>
              <div class="preferences-qna">
                <div class="cp-title-md">
                  YES / NO
                </div>
                <div class="preference">
                  <div class="pref-child" role="radiogroup" aria-labelledby="promoTitle promoSub promoAll">
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="yes"
                             name="allPromotions" [attr.aria-checked]="preferencesForm.get('allPromotions').value"
                             [value]="true" formControlName="allPromotions" />
                    </div>
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="no"
                             name="allPromotions" [attr.aria-checked]="!preferencesForm.get('allPromotions').value"
                             [value]="false" formControlName="allPromotions" />
                    </div>
                  </div>
                  <label class="preference-question form-check-label" id="promoAll">
                    All
                  </label>
                </div>
                <div *ngIf="!isWholeSale" class="preference">
                  <div class="pref-child" role="radiogroup" aria-labelledby="onlyBest">
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="Yes"
                             name="otbPromotions" [attr.aria-checked]="preferencesForm.get('otbPromotions').value"
                             [value]="true" [value]="true" formControlName="otbPromotions" />
                    </div>
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="No"
                             name="otbPromotions" [attr.aria-checked]="!preferencesForm.get('otbPromotions').value"
                             [value]="true" [value]="false" formControlName="otbPromotions" />
                    </div>
                  </div>
                  <label id="onlyBest" class="preference-question form-check-label"
                         [ngClass]="{'text-grey': contactPreferences.allPromotions}">
                    Only the Best <i tabindex="0" class="lef-information-solid text-info clickable"
                       (click)="showInfoDialog('otb')" (keyup.enter)="showInfoDialog('otb')"></i>
                  </label>
                </div>
              </div>
            </div>
            <div class="content-separator d-md-none"></div>
            <div class="col-md-7">
              <p class="cp-title-md" id="leNewsTitle">
                {{informationalTitle}}
              </p>
              <p id="leNewsSub">
                {{ informationalText }}
              </p>
              <div class="preferences-qna">
                <div class="cp-title-md">
                  YES / NO
                </div>
                <div class="preference">
                  <div class="pref-child" role="radiogroup" aria-labelledby="leNewsTitle leNewsSub receiveNot">
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="yes"
                             name="informational" [attr.aria-checked]="preferencesForm.get('informational').value"
                             [value]="true" formControlName="informational" />
                    </div>
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="no"
                             name="informational" [attr.aria-checked]="!preferencesForm.get('informational').value"
                             [value]="false" formControlName="informational" />
                    </div>
                  </div>
                  <label class="preference-question form-check-label" id="receiveNot">
                    Receive Notifications
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="!isWholeSale" class="content-separator"></div>
          </div>
          <div *ngIf="!isWholeSale" class="row">
            <div class="col-md-5 selection-border">
              <p class="cp-title-md" id="leAnnTitle">
                LE Announcements & Updates
              </p>
              <p id="leAnnSub">
                Get important news about Life Extension research, events, and services-even new discoveries!
              </p>
              <div class="preferences-qna">
                <div class="cp-title-md">
                  YES / NO
                </div>
                <div class="preference">
                  <div class="pref-child" role="radiogroup" aria-labelledby="leAnnTitle leAnnSub receiveNotAnnoun">
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="yes"
                             name="announcements" [attr.aria-checked]="preferencesForm.get('announcements').value"
                             [value]="true" formControlName="announcements" />
                    </div>
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="no"
                             name="announcements" [attr.aria-checked]="!preferencesForm.get('announcements').value"
                             [value]="false" formControlName="announcements" />
                    </div>
                  </div>
                  <label class="preference-question form-check-label" id="receiveNotAnnoun">
                    Receive Notifications
                  </label>
                </div>
              </div>
            </div>
            <div class="content-separator d-md-none"></div>
            <div class="col-md-7">
              <p class="cp-title-md" id="leRewardsTitle">
                Rewards and Referral Updates
              </p>
              <p id="leRewardsSub">
                Sure, being healthy is its own reward...but you deserve extra perks for being a loyal Life Extension
                customer!
              </p>
              <div class="preferences-qna">
                <div class="cp-title-md">
                  YES / NO
                </div>
                <div class="preference">
                  <div class="pref-child" role="radiogroup" aria-labelledby="leAnnTitle leAnnSub receiveNotRewards">
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="yes"
                             name="rewardsAndReferral"
                             [attr.aria-checked]="preferencesForm.get('rewardsAndReferral').value" [value]="true"
                             formControlName="rewardsAndReferral" />
                    </div>
                    <div class="preference-answer">
                      <input kendoRadioButton class="k-radio form-check-input" type="radio" aria-label="no"
                             name="rewardsAndReferral"
                             [attr.aria-checked]="!preferencesForm.get('rewardsAndReferral').value" [value]="false"
                             formControlName="rewardsAndReferral" />
                    </div>
                  </div>
                  <label class="preference-question form-check-label" id="receiveNotRewards">
                    Receive Notifications
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </lef-scroll-container>
    </div>
    <div class="col-md-4 col-lg-3">
      <lef-scroll-container [scrollOffset]="120" [isResponsive]="true" [minScreenSize]="screenSizes.MD">
        <div class="bg-light p-4">
          <button kendoButton type="submit" class="k-action w-100 mb-2 font-weight-bold"
                  [disabled]="!preferencesForm.dirty || preferencesForm.invalid">
            Confirm Changes
          </button>

          <lef-contact-us [align]="'right'"></lef-contact-us>
        </div>
      </lef-scroll-container>
    </div>
  </div>
</form>

<ng-template #info>
  <div class="py-3">
    <span [innerHtml]="content"></span>
  </div>
</ng-template>
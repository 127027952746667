import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ICartMessage } from 'src/app/cart-common/model/cart-message';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { OrderDetail } from '../../model/order-detail.model';
import { OrderHistory } from '../../model/order-history.model';
import { OrderItemHistory } from '../../model/order-item-history';
import { OrderItem } from '../../model/order-item.model';
import { OrderHistoryService } from '../../service/order-history.service';

export enum SearchType {
  Orders,
  Order,
  Items,
}

@Component({
  selector: 'lef-order-history-my-orders',
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  templateUrl: './order-history-my-orders.component.html',
  styleUrls: ['./order-history-my-orders.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderHistoryMyOrdersComponent implements OnInit {
  notResultsFound: boolean;
  messages: ICartMessage[] = [];
  orders: OrderDetail[] = [];
  items: OrderItem[] = [];
  orderSearchPeriod: Array<{ value: string; text: string }> = [];
  daysRange = 'NinetyDays';
  page = 1;
  limit: number;
  hasMore: boolean;
  currentCriteria: string;
  lastCriteria: string;
  searchType: SearchType = SearchType.Orders;
  ordersSearchRegexp = new RegExp(/^\s*$/);
  orderSearchRegexp = new RegExp(/^\d{7,}$/);

  constructor(
    protected readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly orderHistoryService: OrderHistoryService,
    private readonly loadingService: LoadingService,
    private readonly config: CoreConfiguration,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.orderSearchPeriod = this.orderHistoryService.orderSearchPeriod;
    this.limit = this.config.environment.orderHistory.limitItemsPerRequest;
    this.daysRange = this.route.snapshot.queryParamMap.get('daysRange');
    this.currentCriteria = this.route.snapshot.queryParamMap.get('criteria');
    this.executeSearch(true);
  }

  search() {
    this.router.navigate(['account/orderhistory'], {
      queryParams: { criteria: this.currentCriteria, daysRange: this.daysRange },
    });
    this.page = 1;
    this.executeSearch(true);
  }

  executeSearch(reset = false) {
    this.currentCriteria = this.currentCriteria ? this.currentCriteria.trim() : '';
    if (!reset && this.lastCriteria === this.currentCriteria) {
      return;
    }
    this.daysRange = this.daysRange ? this.daysRange : this.orderSearchPeriod[1].value;
    this.page = this.page ? this.page : 1;
    if (this.ordersSearchRegexp.test(this.currentCriteria)) {
      this.searchType = SearchType.Orders;
      this.loadHistory();
    } else if (this.orderSearchRegexp.test(this.currentCriteria)) {
      this.searchType = SearchType.Order;
      this.loadHistory();
    } else {
      this.searchType = SearchType.Items;
      this.loadItems();
    }
  }

  loadMoreData() {
    if (this.hasMore) {
      this.page += 1;
      if (this.searchType === SearchType.Items) {
        this.loadItems();
      } else {
        this.loadHistory();
      }
    }
  }

  loadHistory() {
    this.loadingService.show('Loading Order History...');
    this.lastCriteria = this.currentCriteria;
    this.items = [];
    let history: Observable<OrderHistory>;
    if (this.searchType === SearchType.Orders) {
      history = this.orderHistoryService.getHistory(this.daysRange, this.page, this.limit);
    } else {
      history = this.orderHistoryService.getOrder(parseInt(this.currentCriteria, 10), this.daysRange);
    }
    history.subscribe(
      (result: OrderHistory) => {
        this.messages = result.messages;
        this.notResultsFound = this.page === 1 && result.orders.length === 0;
        if (this.orders.length === 0 || this.page === 1 || this.searchType !== SearchType.Orders) {
          this.orders = result.orders;
        } else {
          result.orders.forEach((o) => {
            this.orders.push(o);
          });
        }
        this.hasMore = result.hasMore;
        this.loadingService.hide();
      },
      (error: any) => {
        this.messages = [];
        this.orders = [];
        this.loadingService.hide();
        this.page = this.page > 1 ? this.page - 1 : 1;
      }
    );
  }

  loadItems() {
    this.loadingService.show('Loading Order History...');
    this.lastCriteria = this.currentCriteria;
    this.orders = [];
    this.orderHistoryService.getItems(this.currentCriteria, this.daysRange, this.page, this.limit).subscribe(
      (result: OrderItemHistory) => {
        this.notResultsFound = this.page === 1 && result.items.length === 0;
        this.messages = result.messages;
        if (this.items.length === 0 || this.page === 1) {
          this.items = result.items;
        } else {
          result.items.forEach((i) => {
            this.items.push(i);
          });
        }
        this.hasMore = result.hasMore;
        this.loadingService.hide();
      },
      (error: any) => {
        this.messages = [];
        this.items = [];
        this.loadingService.hide();
        this.page = this.page > 1 ? this.page - 1 : 1;
      }
    );
  }

  public onChange(value: any): void {
    this.currentCriteria = value;
  }

  public onEnterPress(event): void {
    this.search();
  }

  openOrderDetails(orderNumber: string) {
    this.router.navigateByUrl(`/account/orderhistory/${orderNumber}`, {
      state: { criteria: this.lastCriteria, daysRange: this.daysRange },
    });
  }

  returnItems(orderNumber: string) {
    this.router.navigateByUrl(`/returns/${orderNumber}`, {
      state: { criteria: this.lastCriteria, daysRange: this.daysRange, source: 'history' },
    });
  }
}

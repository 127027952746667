<div class="row">
  <ng-container *ngIf="hasPreferences">
    <div *ngIf="contactPreferences.blockEmail" class="col-md-6 mb-3 mb-md-0">
      <div class="bg-light p-3 h-100">
        <p class="text-primary font-weight-bold font-lg">You have been unsubscribed.</p>
        <p>You will no longer receive emails from Life Extension.</p>
        <p>Unsubscribed by accident? <button class="btn btn-link font-weight-bold"
                  (click)="reSubscribeProspect()">Subscribe Again</button>
        </p>
      </div>
    </div>
    <div *ngIf="!contactPreferences.blockEmail" class="col-md-6">
      <p class="text-primary font-weight-bold font-lg">Welcome back!</p>
      <p>You have opted back in to receive emails from Life Extension.</p>
    </div>
    <div class="col-md-6">
      <lef-unsubscribe-survey [contactPreferences]="contactPreferences">
      </lef-unsubscribe-survey>
    </div>
  </ng-container>
  <div *ngIf="!hasPreferences" class="w-100 p-3 bg-light text-center">
    Unable to Find Preference for Provided Contact Information.
  </div>
</div>

export interface IItemWishList {
  wishListId: string;
  wishListName: string;
  disable: boolean;
  isDefaultWishList: boolean;
}

export interface IFavoriteItem {
  itemWishList: IItemWishList[];
  userId: string;
  wishListId: string;
  lineId: string;
  quantity: number;
  isSelected: boolean;
  description: string;
  yourPrice: number;
  itemNumber: string;
  basePrice: number;
  percentageSavings: number;
  isQuantityChangable: boolean;
  itemUrl: string;
  isItemAlreadyInCart: boolean;
  showMoveOption: boolean;
  isVipEligible: boolean;
  isInVip: boolean;
}

export interface Message {
  message: string;
  showIcon: boolean;
  type: number;
  source: string;
  tag: string;
}

export interface IFavorite {
  isWholesaler: boolean;
  wishListFilter: string;
  favoriteItems: IFavoriteItem[];
  messages: Message[];
  success: boolean;
}

export class FavoriteModel implements IFavorite {
  isWholesaler: boolean;
  wishListFilter: string;
  favoriteItems: IFavoriteItem[];
  messages: Message[];
  success: boolean;
  constructor() {
    this.favoriteItems = [];
    this.messages = [];
  }
}

export interface AuthorizationRoutes {
  cart: string;
  unauthorized: string;
}

export class AuthorizationRoutesModel implements AuthorizationRoutes {
  public cart: string;
  public unauthorized: string;

  constructor(cartRoute: string, unauthorizedRoute: string) {
    this.cart = cartRoute;
    this.unauthorized = unauthorizedRoute;
  }
}
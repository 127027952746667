import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { BaseService } from 'src/app/shared/service/base-service';
import { PaymentSettings } from '../model';

@Injectable({
  providedIn: 'root',
})
export class PaymentManagementService extends BaseService<PaymentSettings> {
  protected apiUrl: string;

  constructor(protected override readonly httpClient: HttpClient, private readonly config: CoreConfiguration) {
    super(httpClient);
    this.apiUrl = config.environment.api + config.apiRoutes.paymentManagement;
  }

  get(): Observable<PaymentSettings> {
    return this.getSingle(`${this.apiUrl}/get`);
  }

  updateLEDollarSetting(useLERewardsOnVIP: boolean) {
    return this.post(`${this.apiUrl}/updateLEDollarSetting`, { useLERewardsOnVIP });
  }

  setDefault(defaultPaymentOptionId: string) {
    return this.post(`${this.apiUrl}/setDefault`, { defaultPaymentOptionId });
  }

  deletePaymentMethod(defaultPaymentOptionId: string) {
    return this.post(`${this.apiUrl}/delete`, { defaultPaymentOptionId });
  }

  updatePaymentMethod(paymentOptionId: string, expiration: string) {
    return this.post(`${this.apiUrl}/update`, { paymentOptionId, expiration });
  }
}

import { environment as defaultEnvironment } from './environment.default';

export const environment = {
  ...defaultEnvironment,
  production: true,
  cartSite: 'https://cart-web-qa.lifeextension.com',
  api: 'https://cart-api-qa.lifeextension.com/',
  logging: 'https://cart-api-qa.lifeextension.com/api/logging',
  useApiLogging: true,
  enablePinterestTracking: true,
  enableGoogleTagTracking: true,
  enableCriteoTracking: true,
  enableSelligentTracking: false,
  enableFacebookTracking: true,
  enableGrinTracking: true,
  enablePremierSubscription: true,

  lifeExtensionTracking: {
    enabled: true,
    registerPageVisitEndPoint: '/cart/registercartpagevisit?customernumber=_customernumber_&page=_page_',
    registerPurchaseGoalEndPoint:
      '/cart/registercartpurchasegoal?customernumber=_customernumber_&ordernumber=_ordernumber_&newcustomer=_newcustomer_',
    registerCustomerRegistrationGoalEndPoint: '/cart/registercustomerregistrationgoal?customernumber=_customernumber_',
  },
  commissionJunction: {
    enableCJTracking: true,
    cookieName: 'cje',
    cookieID: 'cje',
    affiliateImageUrl: `https://www.emjcd.com/tags/c?CID=1501702&_items_&OID=_order_&TYPE=413968&CURRENCY=USD&CJEVENT=_idValue_&LOYALTY_STATUS=_LOYALTY_STATUS_&CUST_COUNTRY=_CUST_COUNTRY_&LOCATION=_LOCATION_&CUST_STATUS=_CUST_STATUS_`,
  },
  powerReviews: {
    enabled: true,
    includedOn: ['/checkout/success'],
    merchantId: '217083',
    checkoutPageId: '616642',
    orderTrackerEnabled: true,
  },
  connexityConversionTracking: {
    enabled: true,
    cookieName: 'CX',
    memberId: '314194',
  },
  abtastyTracking: {
    enabled: true,
  },
  nortonShoppingGuaranteeTracking: {
    enabled: true,
    includedOn: ['/', '/checkout'],
  },
  yahooGeminiConversionTracking: {
    enabled: true,
  },
  bingConversionTracking: {
    enabled: true,
  },
  captchaSiteKey: '6LfzBMkZAAAAAA_oxObeIiS-xmUCcbahH-0Gmu_X',
  reselleracknowledgementEnabled: true
};

<lef-section-title [title]="'Shop From My Wish Lists'"></lef-section-title>
<lef-cart-messages #messages *ngIf="favorite" [messages]="favorite.messages"></lef-cart-messages>
<div class="row wishlist-top mb-md-3">
  <div class="col-md-8 wishlist-top-ctrls">
    <div class="mb-3 mb-lg-0">
      <label class="mr-2 font-weight-medium" id="currentList">Current List:</label>
      <!-- <kendo-dropdownlist [data]="wishList" [textField]="'wishListName'" [valueField]="'wishListId'"
                          [valuePrimitive]="false" (selectionChange)="filterWishList($event)"
                          [(ngModel)]="selectedItem">
      </kendo-dropdownlist> -->
      <select [(ngModel)]="selectedItem" (ngModelChange)="filterWishList($event)" for="currentList"
              aria-label="Please select wish list" aria-labelledby="currentList">
        <ng-container *ngFor="let opt of wishList">
          <option [ngValue]="opt">{{ opt.wishListName }}</option>
        </ng-container>
      </select>
    </div>
    <div class="wishlist-top-acts">
      <div class="brd-rt" [hidden]="isDefaultWishList()">
        <button type="button" class="btn btn-link font-weight-bold" aria-haspopup="dialog"
                (click)="deleteThisWishList()">Delete
          This List</button>
      </div>
      <div class="brd-rt" [hidden]="isDefaultWishList()">
        <button type="button" class="btn btn-link font-weight-bold" aria-haspopup="dialog"
                (click)="renameThisWishList()">Rename
          This List</button>
      </div>
      <div class="brd-rt brd-rt-last"><button type="button" class="btn btn-link font-weight-bold" aria-haspopup="dialog"
                (click)="createWishList()">Create
          New
          List</button></div>
      <div class="d-md-none">
        <button type="button" class="btn btn-link" (click)="addAllItemsToCart()" [disabled]="!enableAddAllToCart()">+
          Add All Items To Cart </button>
      </div>
    </div>
  </div>
  <div class="col-md-4 text-md-right d-none d-md-block">
    <button type="button" aria-haspopup="dialog" class="btn btn-link" (click)="addAllItemsToCart()"
            [disabled]="!enableAddAllToCart()">+ Add
      All Items To Cart </button>
  </div>
</div>

<ng-container *ngIf="responsiveService.currentSize < screenSizes.MD">
  <kendo-listview [data]="favorite.favoriteItems" [containerClass]="'k-d-flex k-flex-col k-flex-nowrap'"
                  class="wishlist-mobile">
    <ng-template kendoListViewHeaderTemplate>
      <div class="wishlist-mob-hdr">
        <div>
          <span>Description</span>
        </div>
        <div>
          <span>Total</span>
        </div>
      </div>
    </ng-template>
    <ng-template kendoListViewItemTemplate let-dataItem="dataItem" let-isFirst="isFirst">
      <div class="wishlist-mob-content">
        <div class="wl-content-tp">
          <div>
            <p class="text-primary font-weight-bold"><a class="text-primary"
                 [attr.href]="itemUrlTemplate.replace('{itemNumber}',dataItem.itemNumber)">{{dataItem.description}}</a>
            </p>
            <p>{{dataItem.itemNumber}}</p>
          </div>
          <div>
            <lef-cart-item-price [extendedPrice]="dataItem.yourPrice" [totalBaseStandardPrice]="dataItem.basePrice"
                                 [percentSavings]="dataItem.percentageSavings" [isSuperSale]="dataItem.isSuperSale">
            </lef-cart-item-price>
          </div>
        </div>
        <div class="wl-mob-ctrls w-100">
          <div class="mb-3">
            <button type="button" class="btn btn-link mb-3" *ngIf="!favorite.isWholesaler && dataItem.isVipEligible"
                    (click)="addToSubscription(dataItem)" [disabled]="dataItem.isInVip || dataItem.isItemAlreadyInCart">
              Add to AutoShip &amp; Save
            </button>
            <ng-container *ngIf="dataItem.itemWishList.length > 0">
              <!-- <kendo-dropdownlist [data]="dataItem.itemWishList" [textField]="'wishListName'"
                                  [valueField]="'wishListId'" [valuePrimitive]="false" [itemDisabled]="wishListDisable"
                                  [value]="defaultItem" (valueChange)="moveItemToWishList($event, dataItem)">
              </kendo-dropdownlist> -->
              <select [disabled]="wishListDisable(dataItem)"
                      (change)="moveItemToWishList($event.target.value, dataItem)">
                <option [value]="null">Change List</option>
                <ng-container *ngFor="let opt of dataItem.itemWishList">
                  <option [value]="opt.wishListId">{{ opt.wishListName }}</option>
                </ng-container>
              </select>
            </ng-container>
          </div>
          <div class="text-right">
            <button type="button" class="btn btn-link text-danger mb-3" (click)="removeItemFavorite(dataItem)">
              <i class="lef-remove mr-1"></i> Remove
            </button>
            <button type="button" class="btn btn btn-warning" [disabled]="dataItem.isItemAlreadyInCart"
                    *ngIf="!dataItem.isItemAlreadyInCart" (click)="addItemToCart(dataItem)">
              Add To Cart
            </button>
            <p class="m-0 text-grey font-weight-medium" *ngIf="dataItem.isItemAlreadyInCart">
              Already in Cart
            </p>
          </div>
        </div>
      </div>
    </ng-template>
  </kendo-listview>
</ng-container>

<div *ngIf="responsiveService.currentSize > screenSizes.SM" class="wishlist-table">
  <kendo-grid [kendoGridBinding]="this.favorite.favoriteItems" [sortable]="{allowUnsort: false, mode: 'single'}"
              [groupable]="false" [reorderable]="true" [pageable]="false" *ngIf="favorite" [pageSize]="pageSize"
              (scrollBottom)="loadMore()" [ngClass]="{'fixed-table-height' : favorite?.favoriteItems?.length > 5}">
    <kendo-grid-column field=" description" title="Description">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <p class="text-primary font-weight-bold font-md">
          <a class="text-info" target="_blank"
             [attr.href]="itemUrlTemplate.replace('{itemNumber}',dataItem.itemNumber)">{{dataItem.description}}</a>
        </p>
        <div class="wishlist-drop">
          <div class="mr-3">
            <button type="button" class="btn btn-link" aria-haspopup="dialog"
                    [ngStyle]="{'visibility': favorite.isWholesaler || !dataItem.isVipEligible ? 'hidden': 'visible'}"
                    (click)="addToSubscription(dataItem)" [disabled]="dataItem.isInVip || dataItem.isItemAlreadyInCart">
              Add to AutoShip & Save
            </button>
          </div>
          <ng-container *ngIf="dataItem.itemWishList.length > 0">
            <!-- <kendo-dropdownlist [data]="dataItem.itemWishList" [textField]="'wishListName'" [valueField]="'wishListId'"
                                [valuePrimitive]="false" [itemDisabled]="wishListDisable" [value]="defaultItem"
                                (valueChange)="moveItemToWishList($event, dataItem)">
            </kendo-dropdownlist> -->
            <select [disabled]="wishListDisable(dataItem)" (change)="moveItemToWishList($event.target.value, dataItem)"
                    aria-label="Move this item to another wish list">
              <option [value]="null">Change List</option>
              <ng-container *ngFor="let opt of dataItem.itemWishList">
                <option [value]="opt.wishListId">{{ opt.wishListName }}</option>
              </ng-container>
            </select>
          </ng-container>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="itemNumber" title="Item #" [width]="130">
    </kendo-grid-column>
    <kendo-grid-column field="yourPrice" title="Price" headerClass="text-right justify-content-end" [sortable]="false"
                       [width]="130">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <lef-cart-item-price [extendedPrice]="dataItem.yourPrice" [totalBaseStandardPrice]="dataItem.basePrice"
                             [percentSavings]="dataItem.percentageSavings">
        </lef-cart-item-price>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column title="Action" headerClass="text-center justify-content-center" [width]="260"
                               [columnMenu]="false">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div class="text-center">
          <button type="button" class="btn btn-link text-danger mr-3" (click)="removeItemFavorite(dataItem)">
            <i class="lef-remove mr-1"></i> Remove
          </button>
          <button type="button" class="btn btn btn-warning" aria-haspopup="dialog" *ngIf="!dataItem.isItemAlreadyInCart"
                  (click)="addItemToCart(dataItem)">
            Add To Cart
          </button>
          <p class="m-0 text-grey font-weight-medium" *ngIf="dataItem.isItemAlreadyInCart">
            Already in Cart
          </p>
        </div>
      </ng-template>
    </kendo-grid-command-column>
  </kendo-grid>
</div>
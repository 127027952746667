<div>
  <div class="p-3 bg-primary text-white d-flex justify-content-between">
    <div class="font-weight-bold">Submit your email address below to proceed</div>
    <div>*Indicates required field.</div>
  </div>
  <form class="k-form bg-light p-4" [formGroup]="checkEmailForm" (ngSubmit)="checkEmail()">
    <lef-cart-messages *ngIf="checkEmailFailed" [messages]="noEmail"></lef-cart-messages>
    <div class="d-md-flex">
      <div class="inline-form-field mr-3">
        <formly-form [form]="checkEmailForm" [fields]="checkEmailFormFields" [model]="checkEmailModel">
        </formly-form>
      </div>
      <button type="submit" class="btn btn-warning inline-form-btn" [disabled]="!checkEmailForm.valid">
        Submit
      </button>
    </div>
    <div *ngIf="checkEmailFailed" class="text-red">Please enter a different email address.</div>
  </form>
</div>

import { Component, EventEmitter, Output, Type } from '@angular/core';

export interface INotification {
    message: string;
    style?: 'none' | 'success' | 'warning' | 'error' | 'info';
    icon: boolean;
}

export interface IDynamicContentModel {
    component: Type<any>;
    inputs: { [k: string]: any };
    outputs: { [k: string]: (value: any) => void | {} };
}

@Component({
    selector: 'le-dynamic-component',
    template: ``,
})
export abstract class DynamicComponent {
    @Output()
    readonly notification = new EventEmitter<INotification>();
    @Output()
    readonly clearNotification = new EventEmitter<any>();
    @Output()
    readonly dataChanged = new EventEmitter<any>();

    value: any;

    notifyError(message: string): void {
        this.notification.emit({ message, style: 'error', icon: true });
    }

    notifyMessage(message: string, style?: 'none' | 'success' | 'warning' | 'error' | 'info', icon = true): void {
        this.notification.emit({ message, style, icon });
    }

    clearMessages(): void {
        this.clearNotification.emit({});
    }

    abstract refresh(data: any): void;
}

export class DynamicContentModel implements IDynamicContentModel {
    component: Type<any>;
    inputs: { [k: string]: any };
    outputs: { [k: string]: (value: any) => void | {} };

    refreshEvent = new EventEmitter<any>();

    refresh(data: any = null): void {
        this.refreshEvent.emit(data);
    }
}
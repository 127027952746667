import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lef-wish-list-item-delete-confirmation',
  templateUrl: './wish-list-item-delete-confirmation.component.html',
  styleUrls: ['./wish-list-item-delete-confirmation.component.scss'],
})
export class WishListItemDeleteConfirmationComponent implements OnInit {
  @Output()
  closeDialog: EventEmitter<boolean> = new EventEmitter();
  wishListId: string;

  constructor() {}

  ngOnInit(): void {}

  confirm(confirm: boolean) {
    this.closeDialog.emit(confirm);
  }
}

import { ICartMessage } from 'src/app/cart-common/model';

export interface ISignInSettings {
  confirmEmailMessage: string;
  invalidEmail: boolean;
  customerConfirmEmail: boolean;
  originalEmail: string;
  doubleOptinEmailErrorMsg?: any;
  isSuccess: boolean;
  customerNumber: number;
  isCustomer: boolean;
  changeSecretQuestion: boolean;
  email: string;
  password: string;
  secretQuestion: string;
  secretAnswer: string;
  displayConfirmEmailButton: boolean;
  customSecretQuestion: string;
  messages: ICartMessage[];
}

export class SignInSettingsModel implements ISignInSettings {
  confirmEmailMessage: string;
  invalidEmail: boolean;
  customerConfirmEmail: boolean;
  originalEmail: string;
  doubleOptinEmailErrorMsg?: any;
  isSuccess: boolean;
  customerNumber: number;
  isCustomer: boolean;
  changeSecretQuestion: boolean;
  email: string;
  password: string;
  secretQuestion: string;
  secretAnswer: string;
  displayConfirmEmailButton: boolean;
  customSecretQuestion: string;
  messages: ICartMessage[];

  constructor() {}
}

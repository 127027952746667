import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { ResellerApplicationService } from 'src/app/reseller/service/reseller-application.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IAccountOptions } from '../../model/account-options.model';
import { ResellerApplicationStatus } from '../../model/reseller-application-status.model';
import { AccountService } from '../../service/account.service';
import { ICartMessage } from './../../../cart-common/model/cart-message';
import { IAccount } from './../../model/account.model';

@Component({
  selector: 'lef-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MyAccountComponent implements OnInit {
  account: IAccount;
  accountOptionsLeft: IAccountOptions[] = [];
  accountOptionsRight: IAccountOptions[] = [];
  resellerApplicationStatus: ResellerApplicationStatus;
  resellerApplicationAllowRestart: boolean;
  resellerApplicationId: string;
  protected webUrl: string;
  constructor(
    private readonly accountService: AccountService,
    public readonly config: CoreConfiguration,
    private readonly loadingService: LoadingService,
    private readonly router: Router,
    private readonly resellerApplicationService: ResellerApplicationService
  ) { }

  ngOnInit(): void {
    this.getCustomer();
  }

  private setAccountOptions() {
    this.accountOptionsLeft = [
      {
        idAccountOptions: 0,
        title: 'Contact Preferences',
        icon: 'lef-envelope',
        description: 'Update your contact preferences and newsletter subscriptions',
        url: this.config.webRoutes.accountContactPreferences,
        display: true,
      },
      {
        idAccountOptions: 1,
        title: 'Profile Settings',
        icon: 'k-icon k-font-icon k-i-gear',
        description: 'Update your name and default billing address, email or change your password',
        url: this.config.webRoutes.accountProfileSettings,
        display: true,
      },
      {
        idAccountOptions: 2,
        title: 'Shipping Address',
        icon: 'lef-home1',
        description: 'Add, delete, or modify your addresses',
        url: this.config.webRoutes.accountShippingAddress,
        display: true,
      },
      {
        idAccountOptions: 3,
        title: 'Payment Management',
        icon: 'lef-money',
        description: 'Speed up your checkout process by setting your payment method and shipping address for future orders',
        url: this.config.webRoutes.accountPaymentManagement,
        display: true,
      },
    ];
    this.accountOptionsRight = [
      {
        idAccountOptions: 4,
        title: 'Order History & Reorder',
        icon: 'k-icon k-font-icon k-i-list-unordered',
        description: 'View and track open and completed orders',
        url: this.config.webRoutes.accountOrderHistory,
        display: true,
      },
      {
        idAccountOptions: 5,
        title: 'My Wish Lists',
        icon: 'lef-price-tag',
        description: 'Manage and shop your favorite items',
        url: this.config.webRoutes.accountMyWishLists,
        display: true,
      },
      {
        idAccountOptions: 6,
        title: 'Lab Testing',
        icon: 'lef-lab',
        description: 'Add or modify your lab test subjects',
        url: this.config.webRoutes.accountLabTesting,
        display: true,
      },
      {
        idAccountOptions: 7,
        title: 'AutoShip & Save',
        icon: 'lef-truck',
        description: 'View and manage your recurring orders',
        url: this.config.webRoutes.accountAutoShip,
        queryParams: { tab: 'items' },
        display: !this.account.isWholesaler,
      },
      {
        idAccountOptions: 8,
        title: 'Premier Subscription',
        icon: 'lef-subscription-icon',
        description: 'View and manage your account subscriptions',
        url: this.config.webRoutes.accountAutoShip,
        queryParams: { tab: 'sub' },
        display: this.account.isMember && this.config.environment.enablePremierSubscription,
      },
      {
        idAccountOptions: 9,
        title: 'Invoice Preferences',
        icon: 'lef-subscription-icon',
        description: 'View and manage your Invoice Preferences',
        url: this.config.webRoutes.accountInvoicePreferences,
        display: this.account.isWholesaler
      }
    ];

    this.accountOptionsLeft = this.accountOptionsLeft.filter((option) => option.display);
    this.accountOptionsRight = this.accountOptionsRight.filter((option) => option.display);
  }

  private setResellerApplicationStatus() {
    const status = this.account.resellerApplicationStatus;
    if (status !== null && status !== '') {
      this.resellerApplicationId = this.account.resellerApplicationId;
      if (status === 'Approved') {
        this.resellerApplicationStatus = {
          status: 'Approved',
          message: `<p>Great News! We’ve approved your application.  Welcome to the Life Extension Family!.</p>`,
        };
      } else if (status === 'Declined') {
        this.resellerApplicationStatus = {
          status: 'Declined',
          message:
            '<p>We are sorry to inform you that we were not able to approve your application. Please review the notice that was sent to you via email.</p>',
        };
        this.resellerApplicationAllowRestart = this.account.resellerApplicationAllowRestart;
      } else if (status === 'Awaiting') {
        this.resellerApplicationStatus = {
          status: 'Pending Applicant Update',
          message:
            '<p>Your application has been reviewed but additional information is needed from you.  Please refer to your <a href="/reseller">application to make necessary updates.</a></p>',
        };
      } else {
        this.resellerApplicationStatus = {
          status: 'Under Review',
          message:
            '<p>Stay tuned!  We are reviewing your application and will be in touch if we have questions.  Please allow 3-5 business days for application review process.</p>',
        };
      }
    } else {
      this.resellerApplicationStatus = undefined;
    }
  }

  restartResellerApplication() {
    this.loadingService.show();
    this.resellerApplicationService.moveApplicationToArchive(this.resellerApplicationId).subscribe(
      (application) => {
        this.loadingService.hide();
        this.router.navigateByUrl('/reseller');
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }

  private getCustomer() {
    this.loadingService.show('Loading Account Options...');
    this.accountService.getCustomer().subscribe((account: IAccount) => {
      if (account && account.customerNumber) {
        this.account = account;
        this.setResellerApplicationStatus();
        this.setAccountOptions();
      }
      this.loadingService.hide();
    });
  }

  public showProp65Link(): boolean {
    return this.account.isWholesaler && !this.account.prop65Exception;
  }

  public sendFeedBack() {
    const customerNumber = this.account && this.account.customerNumber !== 0 ? this.account.customerNumber.toString() : '0';
    const feedBackUrl = `${this.config.environment.feedbackUrl}?page=${this.config.webRoutes.accountHome}&ref=&AN8=${customerNumber}`;
    const windowSettings =
      'width=1010,height=630,toolbar=no,location=no,directories=no,status=yes,menubar=no,scrollbars=yes,resizable=yes,left=50,top=50';
    window.open(feedBackUrl, 'surveywindow', windowSettings);
  }

  public updateMessage(messages: ICartMessage[]) {
    this.account.messages = messages;
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { TrackingService } from 'src/app/tracking/service/tracking.service';
import { ApplicationSectionStep } from '../../model/application-section-step.enum';
import { IApplicationSection } from '../../model/application-section.model';

@Component({
  selector: 'lef-reseller-customer',
  templateUrl: './reseller-customer.component.html',
  styleUrls: ['./reseller-customer.component.scss'],
})
export class ResellerCustomerComponent implements OnInit, AfterViewInit, IApplicationSection {
  email = '';
  stepNumber = '1.';
  stepTitle = 'Register / Sign In';
  isStepValid = false;
  isLoading = true;

  @Input()
  disableChangeButton: boolean;

  @Output()
  stepValidationChange: EventEmitter<IApplicationSection> = new EventEmitter<IApplicationSection>();

  constructor(private readonly router: Router,
    public readonly config: CoreConfiguration,
    private readonly trackingService: TrackingService) { }

  isValid(): boolean {
    return this.isStepValid;
  }

  isDirty(): boolean {
    return false;
  }

  saveSection(): void { }

  getCurrentStep(): ApplicationSectionStep {
    return ApplicationSectionStep.Customer;
  }

  ngOnInit(): void {
    this.isStepValid = true;
    this.stepValidationChange.emit(this as IApplicationSection);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      let customerData = this.trackingService.getCurrentCustomerData();
      if (customerData?.email) {
        this.isLoading = false;
        this.email = customerData?.email;
      }
    }, 1500);
  }

  changeCredentials() {
    this.isStepValid = false;
    this.stepValidationChange.emit(this as IApplicationSection);
    this.router.navigateByUrl(`${this.config.webRoutes.root}?returnUrl=reseller&resetSubject=true`);
  }
}

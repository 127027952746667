import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { forkJoin } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { CartMessageType } from 'src/app/cart-common/model';
import { CommonDialogService } from 'src/app/common/service/common-dialog.service';
import { FormlyHelper } from 'src/app/shared/form/formly-helper';
import { IListItem } from 'src/app/shared/model/list-item';
import { CartDialogService } from 'src/app/shared/service/cart-dialog.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { AccountDialogService } from '../../service/account-dialog.service';
import { AccountService } from '../../service/account.service';
import { IAccountInformation } from './../../model/account-information.model';

@Component({
  selector: 'lef-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss'],
})
export class AccountSettingsComponent implements OnInit {
  model: IAccountInformation = {};
  countries: IListItem[] = [];
  states: IListItem[] = [];
  salutationList: IListItem[] = [];

  form: UntypedFormGroup = new UntypedFormGroup({});
  formFields: FormlyFieldConfig[] = [];

  messagesTypesToHide: CartMessageType[] = ['Success'];

  constructor(
    private readonly commonService: CommonService,
    private readonly accountService: AccountService,
    private readonly cartDialogService: CartDialogService,
    private readonly accountDialogService: AccountDialogService,
    private readonly loadingService: LoadingService,
    private readonly commonDialogService: CommonDialogService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getAccountSettings();
  }

  saveAsEnter() {
    this.model.addressVerified = true;
    this.model.addressValidation.addressSelected = '';
    this.saveAccountInformation();
  }

  saveAccountInformation() {
    if (!this.form.valid || !this.model) {
      return;
    }

    this.loadingService.show();
    this.model.messages = [];

    this.accountService.saveAccountInformation(this.model).subscribe(
      (response) => {
        this.model = response;
        this.loadingService.hide();

        if (this.model.isServiceByDistributor) {
          this.showDistributorDialog(this.model);
          return;
        }
        if (this.model.showAddressValidation) {
          this.showAddressValidationDialog();
          return;
        }
        if (this.model.hasErrorInAddress) {
          // Show Error Message
          return;
        }

        this.initForm();
      },
      () => {
        this.loadingService.hide();
      }
    );
  }

  private getAccountSettings() {
    this.loadingService.show();
    forkJoin([
      this.commonService.getSalutationList(),
      this.commonService.getCountryList(),
      this.commonService.getStateList(''),
      this.accountService.getAccountInformation(),
    ])
      .pipe(
        map(([salutationList, countries, states, accountInformationModel]) => {
          return { salutationList, countries, states, accountInformationModel };
        })
      )
      .subscribe(
        (data) => {
          this.salutationList = data.salutationList;
          this.countries = data.countries;
          this.states = data.states;
          this.model = data.accountInformationModel;

          if (this.model.country === 'CA') {
            this.commonService.getStateList(this.model.country).subscribe(
              (states) => {
                this.states = states;
                this.init();
              },
              () => {
                this.loadingService.hide();
              }
            );
          } else {
            this.init();
          }
        },
        () => {
          this.loadingService.hide();
        }
      );
  }

  private init() {
    this.initForm();

    if (this.model.showCustomerNcoaNotification || this.model.showCustomerNcoaVerification) {
      this.showCustomerNcoaVerificationDialog();
    }
    this.loadingService.hide();
  }

  private showCustomerNcoaVerificationDialog() {
    this.accountDialogService.showCustomerNcoaVerificationDialog(this.model, (confirm: any) => {
      if (confirm) {
        if (this.model.customerNCOA.addressSelected === this.model.customerNCOA.currentAddress) {
          this.updateNcoaRecord();
        } else {
          if (this.model.customerNCOA.addressSelected === 'new') {
            this.model.addressLn1 = this.model.customerNCOA.newAddressLn1;
            this.model.addressLn2 = this.model.customerNCOA.newAddressLn2;
            this.model.city = this.model.customerNCOA.newCity;
            this.model.state = this.model.customerNCOA.newState;
            this.model.postalCode = this.model.customerNCOA.newZip;
            this.model.country = this.model.customerNCOA.newCountry;
          } else if (this.model.customerNCOA.addressSelected === 'old') {
            this.model.addressLn1 = this.model.customerNCOA.oldAddressLn1;
            this.model.addressLn2 = this.model.customerNCOA.oldAddressLn2;
            this.model.city = this.model.customerNCOA.oldCity;
            this.model.state = this.model.customerNCOA.oldState;
            this.model.postalCode = this.model.customerNCOA.oldZip;
            this.model.country = this.model.customerNCOA.oldCountry;
          }
          this.model.ncoaAddressVerified = true;
          this.saveAccountInformation();
        }
      }
    });
  }

  private updateNcoaRecord() {
    this.loadingService.show();
    this.accountService.updateNcoaRecord(this.model).subscribe(
      (response) => {
        this.model = response;
        this.initForm();
        this.loadingService.hide();
      },
      () => {
        this.loadingService.hide();
      }
    );
  }

  private showDistributorDialog(shippingAddress: IAccountInformation) {
    this.commonDialogService.openDistributorCountryVerificationDialog({
      showDialog: shippingAddress.isServiceByDistributor,
      message: shippingAddress.distributorRegionMessage,
      distributor: shippingAddress.distributorName,
      distributorSiteUrl: shippingAddress.distributorSiteUrl,
      region: shippingAddress.distributorRegion,
    });
  }

  private showAddressValidationDialog() {
    this.cartDialogService.showAddressValidationDialog(this.model.addressValidation, (confirm) => {
      if (confirm) {
        this.model.addressVerified = true;
        this.saveAccountInformation();
      }
    });
  }

  private initForm() {
    this.setDefaultValues();

    this.form = new UntypedFormGroup({});
    this.formFields = [
      {
        hooks: {
          onInit: () => {
            this.model.allowEdit ? this.form.enable() : this.form.disable();
            this.changeDetectorRef.detectChanges();
          },
        },
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'kCheckbox',
            key: 'isCompany',
            templateOptions: {
              label: 'Company',
              hideLabel: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kDropdown',
            key: 'salutation',
            templateOptions: {
              placeholder: 'Salutation',
              options: this.salutationList,
              valueProp: 'value',
              labelProp: 'description',
              placeholderValue: '',
              autocomplete: 'honorific-prefix'
            },
            hideExpression: (model: IAccountInformation) => model.isCompany,
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'firstName',
            templateOptions: {
              placeholder: 'First Name*',
              autocomplete: 'given-name'
            },
            validators: {
              validation: [Validators.required],
            },
            hideExpression: (model: IAccountInformation) => model.isCompany,
          },
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'lastName',
            templateOptions: {
              placeholder: 'Last Name*',
              autocomplete: 'family-name'
            },
            validators: {
              validation: [Validators.required],
            },
            hideExpression: (model: IAccountInformation) => model.isCompany,
          },
          {
            className: 'col-12',
            type: 'kTextBox',
            key: 'mailingName',
            templateOptions: {
              placeholder: 'Company Name*',             
            },
            validators: {
              validation: [Validators.required],
            },
            hideExpression: (model: IAccountInformation) => !model.isCompany,
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'kCheckbox',
            key: 'isPoBox',
            templateOptions: {
              label: 'P.O. Box',
              hideLabel: true,
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'kTextBox',
            key: 'addressLn1',
            templateOptions: {
              placeholder: 'Street Address 1*',
              autocomplete: 'address-line1'
            },
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-12',
            type: 'kTextBox',
            key: 'addressLn2',
            templateOptions: {
              placeholder: 'Street Address 2',
              autocomplete: 'address-line2'
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'city',
            templateOptions: {
              placeholder: 'City*',
              autocomplete: 'city'
            },
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-6',
            type: 'kDropdown',
            key: 'country',
            templateOptions: {
              placeholder: 'Country',
              options: this.countries,
              valueProp: 'code',
              labelProp: 'description',
              autocomplete: 'country-name'
            },
            hooks: {
              onInit: (field) => {
                return field.formControl.valueChanges.pipe(debounceTime(400)).subscribe((country) => this.onCountryChange(country));
              },
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kDropdown',
            key: 'state',
            templateOptions: {
              placeholder: 'State',
              options: this.states,
              valueProp: 'value',
              labelProp: 'description',
              autocomplete: 'state'
            },
            hideExpression: (model: IAccountInformation) => model.country !== '' && model.country !== 'CA',
            validators: {
              validation: [Validators.required],
            },
          },
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'postalCode',
            templateOptions: {
              placeholder: 'Zip Code',
              required: true,
              autocomplete: 'postal-code'
            },
          },
        ],
      },
      {
        fieldGroupClassName: 'row',
        fieldGroup: [
          {
            className: 'col-6',
            type: 'kTextBox',
            key: 'phoneNumber',
            templateOptions: {
              placeholder: 'Phone*',
              autocomplete: 'tel'
            },
            validators: {
              validation: [Validators.required],
            },
          },
        ],
      },
    ];
  }

  private onCountryChange(country) {
    if (this.form.get('state')) {
      this.form.get('state').setValue('');
    } else {
      this.model.state = null;
    }

    if (country === '' || country === 'CA') {
      FormlyHelper.getField('postalCode', this.formFields).templateOptions.required = true;
      this.commonService.getStateList(country).subscribe((states) => {
        this.states = states;
        FormlyHelper.getField('state', this.formFields).templateOptions.options = this.states;
      });
    } else {
      FormlyHelper.getField('postalCode', this.formFields).templateOptions.required = false;
    }
  }

  private setDefaultValues() {
    if (this.model.state === '') {
      this.model.state = null;
    }

    if (this.model.salutation === '') {
      this.model.salutation = null;
    }

    if (this.model.phoneNumber === '') {
      this.model.phoneNumber = null;
    }
  }
}

export interface IOfficer {
  officerFirstName: string;
  officerLastName: string;
  officerEmail: string;
  officerPhone: string;
  sameAsBilling: boolean;
  officerAddressLn1: string;
  officerAddressLn2: string;
  officerCity: string;
  officerState: string;
  officerZipCode: string;
  officerCountry: string;
  prop65SaleOption: string;
  createdBy: string;
  agreementExpirationDate: string;
  signature: string;
  success: boolean;
  canRenew: boolean;
  officerName: string;
  organizationPhone: string;
  organizationName: string;
  organizationAddress: string;
  organizationAddress2: string;
  organizationCity: string;
  organizationState: string;
  organizationZip: string;
  organizationCountry: string;
  messages: any[];
}

export class OfficerModel implements IOfficer {
  officerFirstName: string;
  officerLastName: string;
  officerEmail: string;
  officerPhone: string;
  sameAsBilling: boolean;
  officerAddressLn1: string;
  officerAddressLn2: string;
  officerCity: string;
  officerState: string;
  officerZipCode: string;
  officerCountry: string;
  prop65SaleOption: string;
  createdBy: string;
  agreementExpirationDate: string;
  signature: string;
  success: boolean;
  canRenew: boolean;
  officerName: string;
  organizationPhone: string;
  organizationName: string;
  organizationAddress: string;
  organizationAddress2: string;
  organizationCity: string;
  organizationState: string;
  organizationZip: string;
  organizationCountry: string;
  messages: any[];

  constructor() {
    // this.email = '';
    // this.aboutUs = null;
    // this.state = null;
    // this.salutation = null;
    // this.country = '';
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Align } from '@progress/kendo-angular-popup';
import { DynamicContentModel } from 'src/app/dynamic-content/model/dynamic-content.model';

@Component({
  selector: 'lef-main-flyout',
  templateUrl: './main-flyout.component.html',
  styleUrl: './main-flyout.component.scss'
})
export class MainFlyoutComponent implements OnInit {

  @Input() flyoutExpanded = false;
  @Input() disabled = false;
  @Input() title = '';
  @Input() flyoutContent: DynamicContentModel = null;
  @Input() flyoutHtmlContent: string = null;
  @Input() showEditFooter = true;
  @Input() showHeader = true;
  @Input() showHeaderButton = true;
  @Input() headerButtonClass = '';
  @Input() headerButtonIconClass = '';
  @Input() cancelButtonText = 'Cancel';
  @Input() submitButtonText = 'Submit';
  @Input() hideNotch = false;

  @Output() flyoutOpen = new EventEmitter<any>();
  @Output() submitClick = new EventEmitter<any>();
  @Output() cancelClick = new EventEmitter<any>();
  @Output() headerButtonClick = new EventEmitter<any>();

  anchorAlign: Align = { horizontal: 'right', vertical: 'top' };
  popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  get animate(): any {
    return {
      type: 'slide',
      direction: 'left',
      duration: 200,
    };
  }

  constructor() { }

  ngOnInit(): void { }

  onFlyoutOpen(): void {
    this.flyoutOpen.emit();
  }

  onSubmitClick(): void {
    this.submitClick.emit();
  }

  onCancelClick(): void {
    this.cancelClick.emit();
  }

  onHeaderButtonClick(): void {
    this.headerButtonClick.emit();
  }
}

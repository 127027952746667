import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DynamicContentComponent } from './component/dynamic-content/dynamic-content.component';



@NgModule({
  declarations: [DynamicContentComponent],
  imports: [
    CommonModule
  ],
  exports: [
    DynamicContentComponent
  ]
})
export class DynamicContentModule { }

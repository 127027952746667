<!-- tracking -->
<lef-tracking></lef-tracking>
<!-- captcha placeholder -->
<lef-captcha-placeholder></lef-captcha-placeholder>
<!-- header -->
<header *ngIf="!isRenderingNonHeadersOrFooterPage" class="header-sticky">
  <lef-header [mainNavBar]="mainNavBar" [mainMenu]="mainMenu" [mainMenuWS]="mainMenuWS"></lef-header>
  <lef-rendering-content></lef-rendering-content>
</header>

<main role="main" class="main-print" id="content">
  <div
       [ngClass]="{'main-flow': showFullHeader, 'main-checkout-flow': !showFullHeader, 'container main-container py-2' :!isRenderingNonHeadersOrFooterPage}">
    <img class="logo-print d-none d-print-block mb-2"
         src="https://qa3.lifeextension.com/-/media/project/lifeextension/images/logo/le-logo.png"
         alt="Life Extension: The Science of a Healthier Life – Homepage">
    <div>
      <lef-global-cart-messages class="d-print-none"></lef-global-cart-messages>
    </div>

    <lef-main-flyout *ngIf="flyout" [flyoutExpanded]="flyout.flyoutExpanded" [disabled]="flyout.disabled"
                     [title]="flyout.title" [flyoutContent]="flyout.flyoutContent"
                     [flyoutHtmlContent]="flyout.flyoutHtmlContent" [showEditFooter]="flyout.showEditFooter"
                     [showHeader]="flyout.showHeader" [cancelButtonText]="flyout.cancelButtonText"
                     [submitButtonText]="flyout.submitButtonText" [showHeaderButton]="flyout.showHeaderButton"
                     [headerButtonClass]="flyout.headerButtonClass"
                     [headerButtonIconClass]="flyout.headerButtonIconClass" [hideNotch]="flyout.hideNotch"
                     (flyoutOpen)="onFlyoutOpen()" (submitClick)="onFlyoutSubmitClick()" (cancelClick)="onCancelClick()"
                     (headerButtonClick)="onHeaderButtonClick()">

    </lef-main-flyout>

    <router-outlet></router-outlet>
  </div>

</main>

<lef-genesys-chat *ngIf="!isRenderingNonHeadersOrFooterPage"></lef-genesys-chat>

<!-- Footer -->
<footer id="footer" *ngIf="!isRenderingNonHeadersOrFooterPage" class="cart-print-ftr">
  <lef-rendering-content [htmlContent]="footerContent" (contentInitialized)="initializeLazy()"></lef-rendering-content>
  <lef-application-version-info></lef-application-version-info>
</footer>
<div class="bg-overlay"></div>

<!-- Cart Dialogs Container -->
<div kendoDialogContainer></div>
<div kendoWindowContainer></div>
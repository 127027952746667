import { Component, OnInit } from '@angular/core';
import { ICartMessage } from 'src/app/cart-common/model/cart-message';
import { PaymentCreditCardComponent } from 'src/app/payment/component/payment-credit-card/payment-credit-card.component';
import { PaymentNewMethodComponent } from 'src/app/payment/component/payment-new-method/payment-new-method.component';
import { PaymentGateway } from 'src/app/payment/model/payment-gateway';
import { PaymentOption } from 'src/app/payment/model/payment-option';
import { PaymentOptionCollection } from 'src/app/payment/model/payment-option-collection';
import { PayPalCheckoutService } from 'src/app/payment/service/pay-pal-checkout-service.service';
import { DialogAlertMessageComponent } from 'src/app/shared/component/dialog-alert-message/dialog-alert-message.component';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { PaymentSettings } from '../../model/payment-settings.model';
import { PaymentManagementService } from '../../service/payment-management.service';

@Component({
  selector: 'lef-payment-management',
  templateUrl: './payment-management.component.html',
  styleUrls: ['./payment-management.component.scss'],
})
export class PaymentManagementComponent implements OnInit {
  useLERewardsOnVIP = false;
  paymentOptions: PaymentOption[];
  gateway: PaymentGateway = new PaymentGateway();
  messages: ICartMessage[] = [];
  constructor(
    private readonly dialogService: DialogService,
    private readonly paymentManagementService: PaymentManagementService,
    private readonly loadingService: LoadingService,
    private readonly payPalCheckOutService: PayPalCheckoutService
  ) {}

  ngOnInit(): void {
    this.loadPaymentSettings();
  }

  loadPaymentSettings(message?: ICartMessage) {
    this.loadingService.show();
    this.paymentManagementService.get().subscribe(
      (settings: PaymentSettings) => {
        this.refreshPaymentSettings(settings);
        if (settings.messages.length === 0 && message) {
          this.messages = [message];
        }
        this.loadingService.hide();
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }
  refreshPaymentSettings(settings: PaymentSettings) {
    this.useLERewardsOnVIP = settings.useLERewardsOnVIP;
    this.paymentOptions = settings.paymentOptions;
    this.gateway = settings.gateway;
    this.messages = settings.messages;
    this.payPalCheckOutService.refreshPayPalInstanceForCapture(this.gateway);
  }

  setDefault(paymentOptionId: string) {
    this.loadingService.show();
    this.paymentManagementService.setDefault(paymentOptionId).subscribe(
      (settings: PaymentSettings) => {
        this.refreshPaymentSettings(settings);
        this.loadingService.hide();
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }

  onUseLERewardsOnVIP(useLERewardsOnVIP: boolean) {
    this.loadingService.show();
    this.paymentManagementService.updateLEDollarSetting(useLERewardsOnVIP).subscribe(
      (settings: PaymentSettings) => {
        this.refreshPaymentSettings(settings);
        this.loadingService.hide();
        if (this.useLERewardsOnVIP) {
          const message = `Your LE Dollars will be automatically applied to AutoShip purchases.`;
          this.showAlertMessage(`Success! Changes Saved.`, message);
        }
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }

  deletePaymentOption(paymentOption: PaymentOption) {
    this.loadingService.show();
    this.paymentManagementService.deletePaymentMethod(paymentOption.id).subscribe(
      (settings: PaymentSettings) => {
        this.refreshPaymentSettings(settings);
        this.loadingService.hide();
      },
      (error) => {
        this.loadingService.hide();
      }
    );
  }
  showAutoShipPaymentAlert() {
    const message = `The payment method you're trying to delete is associated with one or more of your AutoShip items.
    Please modify your <a class='btn btn-link' href='/account/autoship'> AutoShip item(s)</a>
    associated with the payment method before deleting.`;
    this.showAlertMessage(`We're Sorry!`, message);
  }

  showAlertMessage(alertTitle: string, htmlMessage: string) {
    const paymentDialogRef = this.dialogService.open({
      title: alertTitle,
      width: 720,
      maxHeight: '90%',
      maxWidth: '90%',
      content: DialogAlertMessageComponent,
    });
    const alert: DialogAlertMessageComponent = paymentDialogRef.content.instance;
    alert.htmlMessage = htmlMessage;
  }

  confirmDeletePaymentOption(paymentOption: PaymentOption) {
    if (paymentOption.usedInAutoShip) {
      this.showAutoShipPaymentAlert();
      return;
    }
    const paymentDialogRef = this.dialogService.open({
      title: 'Delete Payment Method',
      width: 720,
      maxHeight: '90%',
      maxWidth: '90%',
      content: DialogAlertMessageComponent,
    });
    const alert: DialogAlertMessageComponent = paymentDialogRef.content.instance;
    alert.showButtons = true;
    alert.htmlMessage = `Please confirm if you'd like to delete this payment method.`;
    alert.cancelPressed.subscribe(() => {
      paymentDialogRef.close();
    });
    alert.confirmPressed.subscribe(() => {
      paymentDialogRef.close();
      this.deletePaymentOption(paymentOption);
    });
  }

  addNewPaymentMethod(): void {
    const paymentDialogRef = this.dialogService.open({
      title: 'Add New Payment Method',
      width: 720,
      maxHeight: '90%',
      maxWidth: '90%',
      content: PaymentNewMethodComponent,
    });

    const newPaymentMethodComponent = paymentDialogRef.content.instance;
    newPaymentMethodComponent.gateway = this.gateway;
    newPaymentMethodComponent.instantSave = true;

    newPaymentMethodComponent.paymentOptionsChanged.subscribe((paymentCollection: PaymentOptionCollection) => {
      paymentDialogRef.close();
      this.loadPaymentSettings({ message: `New payment method added!`, showIcon: true, type: 'Success' });
    });
  }

  editPaymentOption(payment: PaymentOption) {
    const paymentDialogRef = this.dialogService.open({
      title: 'Edit Card',
      width: 720,
      maxHeight: '90%',
      maxWidth: '90%',
      content: PaymentCreditCardComponent,
    });

    const editDialog: PaymentCreditCardComponent = paymentDialogRef.content.instance;
    editDialog.gateway = this.gateway;
    editDialog.isNetworkRequestInProgress = false;
    editDialog.notEmbeddedComponent = true;
    editDialog.instantSave = true;
    editDialog.paymentOptionId = payment.id;

    editDialog.paymentOptionsChanged.subscribe((settings: PaymentSettings) => {
      paymentDialogRef.close();
      this.loadPaymentSettings({ message: `Credit Card Updated!`, showIcon: true, type: 'Success' });
    });

    editDialog.executingNetworkRequest.subscribe((executingNetworkRequest) => {
      if (executingNetworkRequest) {
        this.loadingService.show();
      } else {
        this.loadingService.hide();
      }
    });
  }
}

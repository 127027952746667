<lef-section-title [title]="'Billing Preferences'"></lef-section-title>

<form class="k-form" (ngSubmit)="onSave()">
    <div class="row">
        <div class="col-md-8 col-lg-9">
            <lef-scroll-container [scrollOffset]="55" [isResponsive]="true" [maxScreenSize]="screenSizes.MD">
                <div class="row d-flex justify-content-center">
                    <div class="col-12">
                        <div class="my-account-content bg-light mb-4">
                            <div class="p-3">
                                <div class="row">
                                    <div class="col-12 d-flex flex-row">
                                        <div class="col-11 col-md-8 account-options-wrap">
                                            <formly-form class="col-8" [model]="model" [fields]="formFields"
                                                         [form]="form"></formly-form>
                                        </div>
                                        <div
                                             class="col-1 col-md-4 pb-5 px-0 d-flex justify-content-start align-items-end">
                                            <button type="button" class="btn btn-link font-weight-bold"
                                                    (click)="onAddEmailClick()">Add</button>
                                        </div>
                                    </div>

                                    <div *ngFor="let email of form.get('emailList')?.value"
                                         class="col-12 d-flex justify-content-start">
                                        <div class="d-flex align-items-center col-11 col-md-8">{{email}}</div>
                                        <button type="button" class="btn btn-link font-weight-bold"
                                                (click)="onDeleteEmailClick(email)">Delete</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </lef-scroll-container>
        </div>
        <div class="col-md-4 col-lg-3">
            <lef-scroll-container [scrollOffset]="120" [isResponsive]="true" [minScreenSize]="screenSizes.MD">
                <div class="bg-light p-4">
                    <button kendoButton type="submit" class="k-action w-100 mb-2 font-weight-bold"
                            [disabled]="!form.dirty || formInvalid">
                        Confirm Changes
                    </button>
                </div>
            </lef-scroll-container>
        </div>
    </div>
</form>
<div class="payment-management">
  <lef-section-title [title]="'Payment Management'"></lef-section-title>
  <div class="row pm-wrapper">
    <div class="col-lg-9">
      <lef-cart-messages [messages]="messages"></lef-cart-messages>
      <div *ngIf="!paymentOptions" class="alert alert-warning" role="alert"> No payment method found!</div>
      <div *ngIf="gateway.usingSecondaryGateway" class="alert alert-warning" role="alert">
        The system is now using an alternative payment gateway, during this time the payment methods cannot be
        updated.
      </div>
      <ng-container *ngIf="paymentOptions">
        <div>
          <div class="pm-hdr d-none d-md-block">
            <ul>
              <li>Payment Method</li>
              <li>Expiration</li>
              <li>Details</li>
              <li>Actions</li>
            </ul>
          </div>
          <div class="pm-content bg-light p-3 mb-3 mb-md-0">
            <div class="pm-mobile-action text-center d-md-none">
              <button class="btn btn-link" type="button" (click)="addNewPaymentMethod()">+ Add New Payment
                Method</button>
            </div>
            <ul *ngFor="let payment of paymentOptions">
              <li>
                <p class="d-md-none">Payment Method: </p>
                <img src="assets/images/payment-method-{{payment.image}}.png" class="co-payment-img mr-2"
                     [alt]="payment.image" />
                <span [innerHTML]="payment.shortDescription|trustHtml"></span>
                <span *ngIf="payment?.isDefault && (payment.type === 'ApplePay' || payment.type === 'GooglePay')"
                      class="invalid-feedback" role="alert">
                  {{ payment.type }} will be the default flow at checkout if the device supports it.
                </span>
              </li>
              <li><strong class="d-md-none">Expiration: </strong>{{payment.expiration}}</li>
              <li class="pm-details">
                <strong class="d-md-none">Details: </strong>
                <span *ngIf="payment.isDefault">Default</span>
                <span *ngIf="payment.usedInAutoShip">AutoShip</span>
              </li>
              <li class="pm-actions">
                <div *ngIf="!gateway.usingSecondaryGateway && payment.paymentMethod != 'PayPal' && payment.paymentMethod != 'ApplePay' && payment.paymentMethod != 'GooglePay'"
                     class="action payment-edit">
                  <button class="btn btn-link font-weight-bold" type="button"
                          (click)="editPaymentOption(payment)">Edit</button>
                </div>
                <div *ngIf="!gateway.usingSecondaryGateway" class="action action-delete">
                  <button class="btn btn-link font-weight-bold" type="button"
                          (click)="confirmDeletePaymentOption(payment)">{{payment.paymentMethod == 'PayPal' ||
                    payment.paymentMethod == 'ApplePay' || payment.paymentMethod == 'GooglePay'? 'Unlink':
                    'Delete'}}</button>
                </div>
                <div class="action action-set-default">
                  <button *ngIf="!payment.isDefault" class="btn btn-link font-weight-bold" type="button"
                          (click)="setDefault(payment.id)">Make
                    Default</button>
                  <button *ngIf="payment.isDefault" class="btn btn-link" disabled type="button">Make
                    Default</button>
                </div>
              </li>
            </ul>
            <div class="text-center text-md-right">
              <button *ngIf="!gateway.usingSecondaryGateway" class="btn btn-link" type="button"
                      (click)="addNewPaymentMethod()">+ Add New Payment
                Method</button>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-lg-3">
      <div class="pm-hdr">
        LE Dollars
      </div>
      <div class="bg-light p-3">
        <div class="pm-le-apply" style="cursor: pointer;">
          <input type="checkbox" id="useLERewardsOnVIP" [ngModel]="useLERewardsOnVIP"
                 (ngModelChange)="onUseLERewardsOnVIP($event)" />
          <label for="useLERewardsOnVIP">Automatically apply my LE Dollars to AutoShip purchases.</label>
        </div>
      </div>
    </div>
  </div>
</div>
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAutoShipItem } from 'src/app/auto-ship/model/auto-ship-item';
import { IPaymentOption } from 'src/app/payment/model';
import { IAccount } from '../../model';

@Component({
  selector: 'lef-renew-premier',
  templateUrl: './renew-premier.component.html',
  styleUrls: ['./renew-premier.component.scss'],
})
export class RenewPremierComponent implements OnInit {
  @Input() account: IAccount;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter();
  paymentOption: IPaymentOption;

  item: IAutoShipItem;

  constructor() {}

  ngOnInit(): void {}

  onPaymentSelected(paymentOption: IPaymentOption) {
    this.paymentOption = paymentOption;
    const confirm = this.paymentOption ? true : false;
    this.closeDialog.emit(confirm);
  }
}

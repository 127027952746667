import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { SharedModule } from '../shared/shared.module';
import {
  CustomerPreferencesComponent,
  UnsubscribeEmailComponent,
  UnsubscribeProspectComponent,
  UnsubscribeSurveyComponent,
  UnsubscribeVerifyComponent,
} from './component';
import { InvoicePreferencesComponent } from './component/invoice-preferences/invoice-preferences.component';

const components = [
  CustomerPreferencesComponent,
  UnsubscribeProspectComponent,
  UnsubscribeSurveyComponent,
  UnsubscribeEmailComponent,
  UnsubscribeVerifyComponent,
  InvoicePreferencesComponent
];

const modules = [CommonModule, SharedModule, CommonsModule, CartCommonModule];

@NgModule({
  imports: [modules],
  exports: [SharedModule, components],
  declarations: [components],
})
export class PreferencesModule {}

<div *ngIf="model && model.customerNumber">
  <div>
    <lef-cart-messages *ngIf="model && isConfirmedEmail" [messages]="confirmedEmailModelMessages"
                       [messagesTypesToHide]="messagesTypesToHide"></lef-cart-messages>
    <p>
      Customer #: <strong>{{model.customerNumber}}</strong>
    </p>
    <p *ngIf="!model.displayConfirmEmailButton">
      Customer Email Address: <strong>{{model.originalEmail}}</strong>
    </p>
    <p *ngIf="model.displayConfirmEmailButton">
      My Current Email Address: <strong>{{model.originalEmail}} </strong> <button type="button" class="btn btn-link"
              (click)="customerConfirmEmail()">Send Email Verification Link</button>
    </p>
    <hr />
    <div>
      <div>
        <label id="updateEmailAddress">
          <strong>Update Email Address</strong>
        </label>
        <lef-cart-messages *ngIf="model && isChangedEmail" [messages]="resetEmailModelMessages"
                           [messagesTypesToHide]="messagesTypesToHide"></lef-cart-messages>
        <form [formGroup]="changeEmailForm" (ngSubmit)="changeEmail()">
          <formly-form [model]="resetEmailModel" [fields]="changeEmailFormFields" [form]="changeEmailForm"
                       [options]="changeEmailFormOptions">
          </formly-form>
          <div class="invalid-feedbackk" *ngIf="changeEmailForm.hasError('emailMismatch')" role="alert">
            <span>The email don't match</span>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn btn-warning px-4" [disabled]="!changeEmailForm.valid">Save</button>
          </div>
        </form>
      </div>
      <hr />
      <div>
        <label id="updateYourPassword"><strong>Update Your Password</strong></label>
        <lef-cart-messages *ngIf="model && isChangedPassword" [messages]="resetPasswordModelMessages"
                           [messagesTypesToHide]="messagesTypesToHide">
        </lef-cart-messages>
        <p>At least 6 characters, including letters and numbers</p>
        <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
          <formly-form [model]="resetPasswordModel" [fields]="changePasswordFormFields" [form]="changePasswordForm"
                       [options]="changePasswordFormOptions">
          </formly-form>
          <div class="invalid-feedbackk" *ngIf="changePasswordForm.hasError('passwordMismatch')" role="alert">
            <span>The passwords don't match2</span>
          </div>
          <div class="text-right">
            <button type="submit" class="btn btn-warning px-4"
                    [disabled]="!changePasswordForm.valid || changePasswordForm.pristine">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
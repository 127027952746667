import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReturnOrderComponent } from './component/return-order/return-order.component';
import { ShippingLabelComponent } from './component/shipping-label/shipping-label.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: ReturnOrderComponent },
  { path: 'prepaidshippinglabel', component: ShippingLabelComponent, data: { isPublic: true } },
  { path: ':orderNumber', component: ReturnOrderComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ReturnsRoutingModule {}

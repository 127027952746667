<div class="w-100 d-md-flex justify-content-md-between align-items-md-basline">
    <div class="co-cust-login">
        <div class="d-flex justify-content-between align-items-center d-md-block">
            <div class="d-flex align-items-center">
                <p class="co-step-wrap m-0">
                    <span class="wra-step-number">{{stepNumber}}</span><span class="wra-step-title">{{stepTitle}}</span>
                </p>
                <div *ngIf="isLoading" class="k-icon k-font-icon k-i-loading text-info font-2x"></div>
            </div>
            <div class="w-100 d-flex justify-content-start">
                <div class="mr-2">{{email}}</div>
                <button *ngIf="!disableChangeButton" class="btn btn-link m-0" (click)="changeCredentials()">
                    Change
                </button>
            </div>
        </div>
    </div>
</div>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ICartMessage } from 'src/app/cart-common/model';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { IContactPreferences } from '../../model';
import { ContactPreferencesService } from '../../service/contact-preferences.service';

@Component({
  selector: 'lef-unsubscribe-email',
  templateUrl: './unsubscribe-email.component.html',
  styleUrls: ['./unsubscribe-email.component.scss'],
})
export class UnsubscribeEmailComponent implements OnInit {
  @Output() emailChecked: EventEmitter<IContactPreferences> = new EventEmitter();

  checkEmailModel: { email: string };
  checkEmailForm: UntypedFormGroup;
  checkEmailFormFields: FormlyFieldConfig[];
  checkEmailFailed = false;

  noEmail: ICartMessage[] = [{ message: 'The email address you entered does not exist on file!', showIcon: true, type: 'Error' }];

  constructor(private readonly contactPreferencesService: ContactPreferencesService, private readonly loadingService: LoadingService) {}

  ngOnInit(): void {
    this.initCheckEmailForm();
  }

  private initCheckEmailForm(): void {
    this.checkEmailFailed = false;

    this.checkEmailModel = { email: '' };
    this.checkEmailForm = new UntypedFormGroup({});
    this.checkEmailFormFields = [
      {
        key: 'email',
        type: 'focusInput',
        templateOptions: {
          placeholder: 'Email Address*',
        },
        validators: {
          validation: [Validators.required, Validators.email],
        },
      },
    ];
  }

  checkEmail(): void {
    this.contactPreferencesService.getPreferencesFromEmail(this.checkEmailModel.email).subscribe((preferences: IContactPreferences) => {
      if (preferences.customerNumber) {
        this.emailChecked.emit(preferences);
        return;
      }

      this.checkEmailFailed = true;
    });
  }
}

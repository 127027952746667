import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResponsiveService, ScreenSizes } from 'src/app/common/service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { ILabResult } from '../../model/lab-result';
import { LabTestService } from '../../service/lab-test.service';

@Component({
  selector: 'lef-lab-test-results',
  templateUrl: './lab-test-results.component.html',
  styleUrls: ['./lab-test-results.component.scss'],
})
export class LabTestResultsComponent implements OnInit, OnDestroy {
  private subjectNumber: number;

  private responsiveSubscription: Subscription;

  screenSize: ScreenSizes;
  screenSizes = ScreenSizes;

  labResults: ILabResult[] = [];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly labTestService: LabTestService,
    private readonly loadingService: LoadingService,
    private readonly responsiveService: ResponsiveService
  ) {}

  ngOnInit(): void {
    this.loadResults();
    this.subscribeToResponsive();
  }

  ngOnDestroy(): void {
    if (this.responsiveSubscription) {
      this.responsiveSubscription.unsubscribe();
    }
  }

  private loadResults() {
    this.subjectNumber = this.route.snapshot.params.subjectNumber;
    this.loadingService.show('Loading Results...');
    this.labTestService.getLabTestResults(this.subjectNumber).subscribe({
      next: (labResults: ILabResult[]) => {
        this.labResults = labResults;
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  downloadResult(labResult: ILabResult) {
    this.loadingService.show(['Getting Your Results Ready.', 'Downloading Your Results.']);
    this.labTestService.getLabTestResult(labResult.labId, labResult.resultId).subscribe({
      next: (data) => {
        const newBlob = new Blob([data], { type: 'application/pdf' });

        // IE Support
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
          (window.navigator as any).msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        if (labResult.resultDate) {
          const resultDate = new Date(labResult.resultDate);
          link.download = `LabResults-${resultDate.getMonth() + 1}-${resultDate.getDate()}-${resultDate.getFullYear()}.pdf`;
        } else {
          link.download = `LabResults.pdf`;
        }
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      },
      error: (error) => {
        this.loadingService.hide();
      },
      complete: () => {
        this.loadingService.hide();
      },
    });
  }

  private subscribeToResponsive() {
    this.responsiveSubscription = this.responsiveService.onResize$.subscribe((screenSize: ScreenSizes) => {
      this.screenSize = screenSize;
    });
    this.responsiveService.checkCurrentSize();
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CartCommonModule } from '../cart-common/cart-common.module';
import { CommonsModule } from '../common/common.module';
import { CoveoModule } from '../coveo/coveo.module';
import { PaymentModule } from '../payment/payment.module';
import { SharedModule } from '../shared/shared.module';
import { ShippingModule } from '../shipping/shipping.module';
import {
  AutoShipAddItemComponent,
  AutoShipAddressSelectionComponent,
  AutoShipAddToCartComponent,
  AutoShipComponent,
  AutoShipDateSelectionComponent,
  AutoShipDeactivationComponent,
  AutoShipDeliveryComponent,
  AutoShipItemComponent,
  AutoShipItemRescheduleComponent,
  AutoShipPriceInfoComponent,
  AutoShipRibbonInfoComponent,
  AutoShipScheduleComponent,
  AutoShipScheduleDeliveryComponent,
  AutoShipShippingMethodSelectionComponent,
  AutoShipSubscriptionInfoComponent, AutoShipSubscriptionItemComponent
} from './component';

const modules = [CommonModule, SharedModule, ShippingModule, CommonsModule, CartCommonModule, PaymentModule, CoveoModule];

const components = [
  AutoShipComponent,
  AutoShipDeliveryComponent,
  AutoShipItemComponent,
  AutoShipSubscriptionItemComponent,
  AutoShipDateSelectionComponent,
  AutoShipAddressSelectionComponent,
  AutoShipShippingMethodSelectionComponent,
  AutoShipAddToCartComponent,
  AutoShipRibbonInfoComponent,
  AutoShipDeactivationComponent,
  AutoShipAddToCartComponent,
  AutoShipPriceInfoComponent,
  AutoShipItemRescheduleComponent,
  AutoShipScheduleComponent,
  AutoShipAddItemComponent,
  AutoShipScheduleDeliveryComponent,
  AutoShipSubscriptionInfoComponent,
];

@NgModule({
  imports: [modules],
  declarations: [components],
})
export class AutoShipModule {}
